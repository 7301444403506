import dayjs, { Dayjs } from 'dayjs'
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

import { EditableQuestionKeysStep3, MissionStatusType, MissionUpdateTypeAPI } from '../../../api/types'
import { DateTimePicker } from '../../../elements/DateTimePicker'
import { DecimalNumberInput } from '../../../elements/DecimalNumberInput'
import { TextInput } from '../../../elements/TextInput'
import { WizardContextType } from '../Wizard'

import styles from './Step3.module.scss'


export const Step3 = forwardRef(() => {
  const i18n = useTranslation()

  const { mission, setStep, stepRef } = useOutletContext<WizardContextType>()

  const [questions, setQuestions] = useState(mission.questions ? mission.questions.filter(q => q.toValidate) : [])
  const [validationEndDate, setValidationEndDate] = useState<Dayjs | null>(
    questions?.length ? dayjs(mission.validationEndDate) : null
  )


  useEffect(() => {
    setStep(3)
  }, [setStep])


  useImperativeHandle(stepRef, () => ({
    updateData(newStatus: MissionStatusType): MissionUpdateTypeAPI {
      return({mission: {
        status: newStatus,
        validationEndDate: validationEndDate?.isValid() ? validationEndDate.toISOString() : null,
        validationAttributes: questions?.map(question => {
          return {
            questionId: question.id || 0,
            validationText: question.validationText || '',
            validationValue: question.validationValue || 0,
            maxValidations: question.maxValidations || 0
          }
        })
      }})
    },

    getWarnings(): string[] {
      const newWarnings: string[] = []
      if (questions.length !== 0) {
        if (!validationEndDate?.isValid())
          newWarnings.push(i18n.t('MissionWizard:WarningValidationEndDateMissing'))
        if (validationEndDate?.isValid() && dayjs(mission.endDate).isAfter(validationEndDate))
          newWarnings.push(i18n.t('MissionWizard:WarningEndDateBeforeValidationEndDate'))
        questions?.forEach((question, index) => {
          if (!question.validationText)
            newWarnings.push(i18n.t('MissionWizard:WarningNoValidationText', {n: index+1}))
          if (!question.validationValue || question.validationValue <= 0)
            newWarnings.push(i18n.t('MissionWizard:WarningNoValidationValue', {n: index+1}))
          if (!question.maxValidations || question.maxValidations <= 0)
            newWarnings.push(i18n.t('MissionWizard:WarningNoMaxValidations', {n: index+1}))
        })
      }
      return newWarnings
    }
  }))


  const updateQuestion = useCallback((
    id: number,
    value: string | number,
    toUpdate: EditableQuestionKeysStep3
  ) => {
    const newQuestion = {
      ...questions[id],
      [toUpdate]: value
    }
    setQuestions(questions.slice(0, id).concat(newQuestion).concat(questions.slice(id+1)))
  }, [questions])


  return (
    <div className={styles.validations}>
      <h2>
        {i18n.t('MissionWizard:CreateValidations')}
      </h2>
      {questions.map((question, index) => (
        <div
          key={`validation_${index}`}
          className={styles.validationContainer}
        >
          <div className={styles.id}>
            <span className={styles.idText}>
              {`${question.order+1}`}
            </span>
          </div>
          <div className={styles.imgContainer}>
            <img
              src={question.image?.toString()}
              alt='questionImage'
              className={styles.img}
              draggable={false}
            />
          </div>
          <div className={styles.validation}>
            <div className={styles.questionInfo}>
              <div className={styles.questionInfoPartial}>
                <div className={styles.info}>
                  <h4>
                    {i18n.t('Mission:Question')}
                  </h4>
                  <h5>
                    {question.text}
                  </h5>
                </div>
              </div>
              <div className={styles.questionInfoPartial}>
                <div className={`${styles.info} ${styles.top}`}>
                  <h4>
                    {i18n.t('Mission:ShortName')}
                  </h4>
                  <h5 className={question.shortName ? undefined : styles.italic}>
                    {question.shortName ? question.shortName : i18n.t('MissionWizard:NoShortName')}
                  </h5>
                </div>
                <div className={`${styles.info} ${styles.top}`}>
                  <h4>
                    {i18n.t('Mission:QuestionType')}
                  </h4>
                  <h5>
                    {question.questionType}
                  </h5>
                </div>
                <div className={styles.info}>
                  <h4>
                    {i18n.t('Mission:ExpectedAnswer')}
                  </h4>
                  <h5 className={question.expectedAnswer ? undefined : styles.italic}>
                    {question.expectedAnswer ? question.expectedAnswer : i18n.t('MissionWizard:NoExpectedAnswer')}
                  </h5>
                </div>
              </div>
            </div>
            <div className={styles.inputs}>
              <TextInput
                title={i18n.t('MissionWizard:ValidationQuestion')}
                placeholder={i18n.t('MissionWizard:WriteHereValidationQuestion')}
                value={question.validationText || ''}
                onChange={v => updateQuestion(index, v, 'validationText')}
              />
              <div className={styles.input}>
                <DecimalNumberInput
                  title={i18n.t('Common:Value')}
                  value={question.validationValue || 0}
                  onChange={v => updateQuestion(index, v, 'validationValue')}
                  endSymbol='€'
                />
              </div>
              <div className={styles.input}>
                <DecimalNumberInput
                  title={i18n.t('Mission:MaxValidations')}
                  value={question.maxValidations || 0}
                  onChange={v => updateQuestion(index, v, 'maxValidations')}
                  decimalPlaces={0}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      {questions.length === 0 ? (
        <div className={styles.noValidations}>
          <h3>
            {i18n.t('MissionWizard:NoValidationsWarning')}
          </h3>
          <h4>
            {i18n.t('MissionWizard:NoValidationsText')}
          </h4>
        </div>
      ) : (
        <DateTimePicker
          label={i18n.t('Mission:ValidationEndDate')}
          value={validationEndDate}
          onChange={v => setValidationEndDate(v)}
          minDateTime={dayjs(mission.endDate)}
        />
      )}
    </div>
  )
})
