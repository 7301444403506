import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { URLSearchParamsInit, useSearchParams } from "react-router-dom";

import { ChartAxisType, GenericChartAction, RESET_ZOOM_ACTION } from "../components/Chart/ChartDefinition";
import { useLocationTransition } from "../hooks/useLocationTransition";
import { cleanupObject, clearObject, extractPartialObject, formatSearchParameters, formatTitle, formatVariable, unformatSearchParameters } from "../utils/formatter";
import { isEmptyOrWhiteSpace, objectsAreEqual } from "../utils/validator";

//#region Interfaces

interface Props {
  children?: ReactNode;
}

interface GenericFilterType {
  genericFilters: FilterStruct;
  genericZoomDateFilter: [unknown, unknown];
  inUseFilters: string[] | boolean;
  genericChartAction: GenericChartAction;
  updateGenericFilter: (name: string, value: unknown) => void;
  updateGenericZoom: (values: [unknown, unknown], type?: ChartAxisType) => void;
  updateMultipleGenericFilter: (partial: {[key: string]: unknown}) => void;
  cleanupGenericFilters: () => void;
  updateInUseFilters: (bindedFilters: string[] | boolean) => void;
  updateGenericChartAction: (config: GenericChartAction) => void;
}

//#endregion Interfaces

//#region Types

export type FilterStruct = { [key: string]: unknown };

//#endregion Types

//#region Constants

const LOCATION_PATHNAME = 'pathname',
      ZOOM_VARIATION = 'zoom',
      ZOOM_STANDARD_KEYS = ['min', 'max'].map((key: string) => `${ZOOM_VARIATION}${formatTitle(key)}`),
      ZOOM_DATE_KEYS = ZOOM_STANDARD_KEYS.map((key: string) => `${key}${'Date'}`),
      ZOOM_REGEX_FLAGS = 'gi';

export const ZOOM_KEYS = [...ZOOM_STANDARD_KEYS, ...ZOOM_DATE_KEYS];

//#endregion Constants

//#region Context

const GenericFilterContext = createContext<GenericFilterType>({} as GenericFilterType);

export const useGenericFilterContext = (includeZoom = true) => {
  const {genericFilters, ...others} = useContext(GenericFilterContext),
        moddedFilters = useMemo(() => {
          const baseKeys = Object.keys(genericFilters ?? {}).filter((key: string) => !ZOOM_KEYS.includes(key)),
                baseObject = baseKeys.reduce((acc: FilterStruct, key: string) => {
                  return {...acc, [key]: genericFilters[key]};
                }, {} as FilterStruct);

          return includeZoom
                  ? ZOOM_KEYS.reduce((acc: FilterStruct, key: string) => {
                      const value = genericFilters?.[key],
                            unZoomedKey = key.replace(ZOOM_VARIATION, ''),
                            replacementKey = new RegExp(unZoomedKey.substring(0, unZoomedKey.length - 1), ZOOM_REGEX_FLAGS),
                            matchKey = Object.keys(acc).find((key: string) => {
                              replacementKey.lastIndex = 0;
                              return replacementKey.test(key);
                            });

                      return !isEmptyOrWhiteSpace(value) && matchKey? {...acc, [matchKey]: value } : acc;
                    }, {...baseObject})
                  : baseObject;
        }, [genericFilters, includeZoom]);

  return  {genericFilters: moddedFilters, ...others};
};

//#endregion Context

export const GenericFilterProvider = ({children}: Props) => {
  const [inUseFilters, setInUseFilters] = useState([] as string[] | boolean),
        [searchParams, setSearchParams] = useSearchParams(),
        [chartAction, setChartAction] = useState({} as GenericChartAction),
        [filters, setFilters] = useState(unformatSearchParameters([...searchParams.entries()].reduce((acc: object, [key, value]: [string, unknown]) => {
          return {
            ...acc,
            [key]: !acc[key]
                      ? value
                      : Array.isArray(acc[key])
                        ? [...acc[key], value]
                        : [acc[key], value]
          } as object;
        }, {}))),
        zoomDateFilter = useMemo(() => ZOOM_DATE_KEYS.map((key: string) => filters[key]) as [unknown, unknown],[filters]);

  //#region Private Methods

  const setFiltersPlusSearch = (newFilters: object) => {
    const keysForSearch = Object.keys(newFilters).filter((key: string) => !ZOOM_KEYS.includes(key));
    setFilters(newFilters as FilterStruct);
    setSearchParams(formatSearchParameters(cleanupObject(extractPartialObject({...newFilters}, keysForSearch))) as URLSearchParamsInit);
  }

  const updateFilter = (name: string, value: unknown) => {
    const newFilters = {
      ...filters,
      [name]: value
    };
    
    // Prevent update on load of filters
    if(isEmptyOrWhiteSpace(value) && !Object.keys(filters).includes(name)) {
      delete newFilters[name];
    }

    if(!objectsAreEqual(newFilters, filters)) {
      setFiltersPlusSearch(newFilters);
    }
  }

  const updateMultipleFilter = (partial: {[key: string]: unknown}) => {
    const newFilters = {
      ...filters,
      ...partial
    };

    Object.entries(partial).forEach(([name, value]: [string, unknown]) => {
      // Prevent update on load of filters
      if(isEmptyOrWhiteSpace(value) && !Object.keys(filters).includes(name)) {
        delete newFilters[name];
      }
    });

    if(!objectsAreEqual(newFilters, filters)) {
      setFiltersPlusSearch(newFilters);
    }
  }

  const updateZoom = (values: [unknown, unknown], type: ChartAxisType = ChartAxisType.TIME) => {
    const keys = type === ChartAxisType.TIME ? ZOOM_DATE_KEYS : ZOOM_STANDARD_KEYS;
    updateMultipleFilter(values.reduce((acc: {[key: string]: unknown}, value: unknown, index: number) => {
      return {...acc, [keys[index]]: value};
    }, {}));
  }

  const cleanupFilters = () => {
    setChartAction(RESET_ZOOM_ACTION);
    setFiltersPlusSearch(clearObject({...filters}, true));
  }

  const cleanupZoom = () => {
    const keysToKeep = Object.keys(filters).filter((key: string) => !ZOOM_KEYS.includes(key));
    setFilters(extractPartialObject({...filters}, keysToKeep) as FilterStruct);
  }

  const updateInUseFilters = (bindedFilters: string[] | boolean) => {
    setInUseFilters((prevFilters: string[] | boolean) => {
      if(prevFilters === true || bindedFilters === true) {
        return true;
      }

      const formattedBindedFilters = (bindedFilters as string[]).map((filter: string) => {
        return formatVariable(filter.replace(/(min|max)/, ''));
      });

      return [...new Set([...prevFilters as string[], ...formattedBindedFilters])];
    });
  }

  //#endregion Private Methods

  //#region Effects

  useLocationTransition({
    prop: LOCATION_PATHNAME,
    onExit: () => {
      cleanupZoom();
      setInUseFilters([]);
      setChartAction({} as GenericChartAction);
    }
  })

  //#endregion Effects

  return (
    <GenericFilterContext.Provider value={{genericFilters: filters, genericZoomDateFilter: zoomDateFilter, inUseFilters, genericChartAction: chartAction, updateGenericFilter: updateFilter, updateGenericZoom: updateZoom, updateMultipleGenericFilter: updateMultipleFilter, cleanupGenericFilters: cleanupFilters, updateInUseFilters, updateGenericChartAction: setChartAction}}>
      {children}
    </GenericFilterContext.Provider>
  )
}
