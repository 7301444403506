import { SaveAlt } from '@mui/icons-material';
import { Box, Grid } from '@mui/material';
import { DataGrid, GridAutosizeOptions, GridColDef, GridPaginationModel, GridSortModel, GridValidRowModel, useGridApiRef } from '@mui/x-data-grid';
import { ptPT, frFR, esES, enUS } from '@mui/x-data-grid/locales';
import { useEffect, useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { exportDashboardTable, OrderByType, ServerResponseType } from '../../api';
import { TableTypeAPI } from '../../api/types';
import { useGenericFilterContext } from '../../contexts/GenericFilterContext';
import i18n from '../../localization';
import BaseTableModel from '../../models/base/BaseTableModel';
import { extractConditionalObject, extractPartialObject, formatFileName, ValueType } from '../../utils/formatter';
import { extractGridColDef, extractGridExportColumns } from '../../utils/table';
import { objectsAreEqual } from '../../utils/validator';
import { wrapContent, WrapperConfig } from '../../utils/wrapper';
import { ActionsMenu, ActionsMenuItem } from '../ActionsMenu/ActionsMenu';
import { ErrorAlert } from '../ErrorAlert/ErrorAlert';

import styles from './RemoteTable.module.scss';

//#region Enums

export enum GridColSortType {
  DESC = 'desc',
  ASC = 'asc'
}

export enum GridCellWidgetType {
  PROGRESS = 'progress'
}

enum TableMode {
  CLIENT = 'client',
  SERVER = 'server'
}

enum GridActionMenuType {
  EXPORT = 'export'
}

//#endregion Enums

//#region Types

export type GridCellFormat = {
  condition: (value: unknown) => boolean;
  color: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CustomGridColDef<R extends GridValidRowModel = any> = Partial<GridColDef<R>> & {
  cellConditionalFormat?: GridCellFormat | GridCellFormat[];
  formatType: ValueType;
  defaultSorted?: GridColSortType;
  widget?: GridCellWidgetType;
  headerResourceName?: string;
}

//#endregion Types

//#region Interfaces

export interface TableFilters {
  page: number
  items: number
  orderBy?: string
  orderDirection?: OrderByType
  table: string
}

export interface ExportTableFilters extends Omit<TableFilters, 'items' | 'page'> {
  columns: ExportTableFiltersColumn[];
}

export interface ExportTableFiltersColumn {
  raw: string
  format: string;
}

interface RemoteTableConfig<T> {
  model: T;
  maxRows?: number;
  title?: string;
  bindGenericFilters?: boolean | string[];
  fetchMethod?: (filters: TableFilters) => Promise<ServerResponseType<TableTypeAPI<T>>>;
  wrapper?: WrapperConfig;
}

//#endregion Interfaces

//#region Constants

const CSS_IMPORTANT = '!important',
      CSS_PIXELS = 'px',
      MAX_ROWS = 10,
      ROW_HEIGHT = 52,
      ACTIONS_MENU_CONTEXT = 'Table',
      EXPORT_REJECTED_FILTERS_FIELDS: (keyof TableFilters)[] = ['items', 'page'],
      AUTO_SIZE_CONFIG: GridAutosizeOptions = {
        includeHeaders: true,
        includeOutliers: true,
        expand: true
      },
      ACTIONS: ActionsMenuItem<GridActionMenuType>[] = [
        {
          id: GridActionMenuType.EXPORT,
          icon: SaveAlt
        }
      ];

//#endregion Constants


export const RemoteTable = <T extends BaseTableModel>(config: RemoteTableConfig<T>) => {
  const maxRows = useMemo(() => config.maxRows ?? MAX_ROWS, [config.maxRows]),
        rawColumns = useMemo(() => config.model.getColumnsConfig(), [config.model]),
        columnsConfig = useMemo(() => extractGridColDef(rawColumns), [rawColumns]),
        apiRef = useGridApiRef(),
        {genericFilters, updateInUseFilters} = useGenericFilterContext(),
        [error, setError] = useState(false),
        [data, setData] = useState(undefined as T[] | undefined),
        sortColumn = (columnsConfig as CustomGridColDef<T>[]).find(({defaultSorted}: CustomGridColDef<T>) => defaultSorted),
        [totalItems, setTotalItems] = useState(0),
        [pending, setPending] = useState(false),
        [filters, setFilters] = useState({
          items: maxRows,
          page: 0,
          orderBy: sortColumn?.field,
          orderDirection: sortColumn?.defaultSorted?.toUpperCase(),
          ...config.model.getCustomFilters?.(),
          ...extractConditionalObject(genericFilters, config.bindGenericFilters)
        } as TableFilters),
        sortModel = useMemo(() => filters.orderBy ? [{field: filters.orderBy, sort: filters.orderDirection?.toLowerCase()}] as GridSortModel : [], [filters]),
        dynamicCss = useMemo(() => {
          const height = `${ROW_HEIGHT * maxRows}${CSS_PIXELS}`;
          return {
            // GENERATE COLOR FROM cellConditionalFormat of columns
            ...Object.entries(rawColumns).reduce((tableClasses: object, [key, gridColDef]: [string, CustomGridColDef | undefined]) => {
              const {cellConditionalFormat} = gridColDef as CustomGridColDef;

              if(cellConditionalFormat) {
                // eslint-disable-next-line i18next/no-literal-string
                const cellClass = `.${key}-cell-condition`,
                      formatCondition = Array.isArray(cellConditionalFormat)
                                          ? cellConditionalFormat
                                          : [cellConditionalFormat];

                return {
                  ...tableClasses,
                  ...formatCondition.reduce((columnClasses: object, {color}: GridCellFormat, index: number) => {
                    const newClass = `${cellClass}-${index}`;
                    return {
                      ...columnClasses,
                      // eslint-disable-next-line i18next/no-literal-string
                      [`& .MuiDataGrid-cell${newClass}`]: { backgroundColor: `${color}a6` },
                      // eslint-disable-next-line i18next/no-literal-string
                      [`& .MuiDataGrid-row:has(${newClass})`]: { backgroundColor: `${color}4d` }
                    };
                  }, {})
                };
              }
              return tableClasses;
            }, {}),
            '& .MuiDataGrid-virtualScroller': { '--DataGrid-overlayHeight':  height },
            '& .MuiDataGrid-virtualScrollerContent': { height: `${height} ${CSS_IMPORTANT}` }
          };
        }, [maxRows, rawColumns]),
        language = useMemo(() => {
          switch(i18n.language) {
            case 'pt-PT':
              return ptPT;
            case 'es-ES':
              return esES;
            case 'fr-FR':
              return frFR;
            default:
              return enUS;
          }
        }, []);

  //#region Private Methods

  //#region Remote

  const fetchRemote = () => {
    setError(false);
    setPending(true);
    config.fetchMethod?.(filters)
      .then(({data, success}: ServerResponseType<TableTypeAPI<T>>) => {
        if(!success) {
          setError(true);
        }
        setData(data?.data);
        setTotalItems(data?.metadata.itemCount ?? 0);
      })
      .finally(() => {
        setPending(false);
      });
  }

  //#endregion Remote

  const getRowId = () => uuidv4();

  const onPaginationModelChange = ({page}: GridPaginationModel) => {
    setFilters({...filters, page: page});
  }

  const onSortModelChange = (model: GridSortModel) => {
    const {field, sort} = model?.[0] ?? {};
    setFilters({...filters, orderBy: field, orderDirection: sort?.toUpperCase() as OrderByType});
  }

  const exportGrid = () => {
    exportDashboardTable({
      ...extractPartialObject(filters, Object.keys(filters).filter((key: string) => !EXPORT_REJECTED_FILTERS_FIELDS.includes(key as keyof TableFilters))),
      columns: extractGridExportColumns(columnsConfig)
    } as ExportTableFilters, config.title ? formatFileName(config.title) : undefined);
  }

  const onAction = (action: GridActionMenuType) => {
    switch(action) {
      case GridActionMenuType.EXPORT:
        exportGrid();
        break;
    }
  }

  //#endregion Private Methods

  //#region Effects

  useEffect(fetchRemote, [filters, config])

  useEffect(() => {
    setTimeout(() => {
      apiRef.current?.autosizeColumns(AUTO_SIZE_CONFIG);
    }, 200);
  }, [data, apiRef])

  useEffect(() => {
    if(config.bindGenericFilters) {
      const newFilters = {
        ...filters,
        ...extractConditionalObject(genericFilters, config.bindGenericFilters)
      };

      if(!objectsAreEqual(filters, newFilters)) {
        setFilters(newFilters);
      }
    }
  }, [filters, genericFilters, config.bindGenericFilters])

  useEffect(() => {
    if(config.bindGenericFilters) {
      updateInUseFilters(config.bindGenericFilters);
    }
    // Disable eslint for updateInUseFilters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.bindGenericFilters]);

  //#endregion Effects

  //#region Partial Elements

  const noRowsOverlay = (
    <Box display={'flex'} width={'100%'} height={'100%'} justifyContent={'center'} alignItems={'center'}>
      <span>{i18n.t('Table:WithoutResults')}</span>
    </Box>
  )

  //#endregion Partial Elements

  return wrapContent(
    <Grid container spacing={1}>
      <Grid item xs={12} display={'flex'} alignItems={'center'}>
        <h3>{config.title}</h3>
        <ActionsMenu<GridActionMenuType>
          actions={ACTIONS}
          context={ACTIONS_MENU_CONTEXT}
          onClick={onAction}/>
      </Grid>
      <Grid item xs={12} position={'relative'}>
        <ErrorAlert show={error} onReload={fetchRemote}/>
        <DataGrid
          sx={dynamicCss}
          slots={{noRowsOverlay: () => noRowsOverlay}}
          className={styles.table}
          apiRef={apiRef}
          loading={pending}
          columns={columnsConfig}
          rows={data}
          getRowId={getRowId}
          disableColumnMenu
          disableColumnResize
          disableRowSelectionOnClick
          disableDensitySelector
          disableVirtualization
          autoHeight
          autosizeOnMount
          pageSizeOptions={[maxRows]}
          autosizeOptions={AUTO_SIZE_CONFIG}
          sortingMode={TableMode.SERVER}
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          rowCount={totalItems}
          paginationMode={TableMode.SERVER}
          paginationModel={{pageSize: maxRows, page: filters.page}}
          onPaginationModelChange={onPaginationModelChange}
          localeText={language?.components.MuiDataGrid.defaultProps.localeText}
          />
      </Grid>
    </Grid>
  , config.wrapper)
}
