import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswerOptionTypeAPI, QuestionTypeAPI } from '../../api/types'
import { formatTitle } from '../../utils/formatter'
import { isJSONString } from '../../utils/validator'
import { Chart } from '../Chart/Chart'
import { ChartRawData, ChartType, OrientType } from '../Chart/ChartDefinition'
import { ListCard } from '../ListCard'
import { QuestionAndValidations } from '../QuestionAndValidations'

import styles from './QuestionStatisticCard.module.scss'


type QuestionStatisticCardProps = {
  question: QuestionTypeAPI
  grey?: boolean
}


export const QuestionStatisticCard = (({
  question,
  grey
}: QuestionStatisticCardProps) => {
  const i18n = useTranslation(),
        [isOpen, setIsOpen] = useState(false),
        chartType = useMemo(() => {
          switch(question.questionType) {
            case 'numeric':
              return ChartType.VIOLIN;
            case 'multiple_choice':
              return ChartType.BAR;
            default:
              return ChartType.PIE;
          }
        }, [question.questionType]),
        dataSource = useMemo(() => {
          const answerOptions = question.answerOptions.reduce((acc: {[key: number]: string}, {id, text}: AnswerOptionTypeAPI) => {
                  if(id !== undefined) {
                    acc[id] = text;
                  }
                  return acc;
                }, {}),
                arrayLike = Object.entries(question.statistic ?? {})
                              .sort(([keyA]: [string, number], [keyB]: [string, number]) => keyB.length - keyA.length)
                              .map(([key, value]: [string, number]) => {
                                if(isJSONString(key)) {
                                  const ids: unknown = JSON.parse(key);

                                  if(Array.isArray(ids)) {
                                    return [ids.map((id: number) => answerOptions[id]).join(','), value] as [string, number];
                                  }
                                }
                                return [key, value] as [string, number];
                              });

          switch(chartType) {
            case ChartType.VIOLIN:
              return arrayLike.reduce((acc: ChartRawData[], [key, value]: [string, number]) => [...acc, ...new Array(value).fill({label: '', value: parseInt(key)})], []);
            default:
              return arrayLike.map(([key, value]: [string, number]) => ({label: formatTitle(key), value: value} as ChartRawData));
          }
        }, [question, chartType]);

  return (
    <ListCard grey={grey} handler={() => { setIsOpen(!isOpen) }}>
      <div className={styles.questionStatisticCard}>
        <h3>
          {`${question.order+1}) ${question.text}`}
        </h3>
        <div className={styles.expectedAnswer}>
          <h4>
            {`${i18n.t('Mission:ExpectedAnswer')}:`}
          </h4>
          <h5 className={question.expectedAnswer ? undefined : styles.italic}>
            {question.expectedAnswer ? question.expectedAnswer : i18n.t('MissionWizard:NoExpectedAnswer')}
          </h5>
        </div>
        {isOpen && (
          <QuestionAndValidations
            question={question}
            // README: In the future, when a question can have many validations, pass the array of validations to this prop
            validations={[{
              text: question.validationText || '',
              value: question.validationValue || 0,
              maxValidations: question.maxValidations || 0
            }]}
          />
        )}
        <div className={styles.answers}>
          <Chart dataLabel={question.text} orientation={OrientType.HORIZONTAL} type={chartType} data={dataSource}/>
        </div>
      </div>
    </ListCard>
  )
})
