import { ZoomIn } from "@mui/icons-material";
import { Box, FormControl } from "@mui/material";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useMemo, useState } from "react";

import { useGenericFilterContext } from "../../contexts/GenericFilterContext";
import i18n from "../../localization";
import { formatTitle } from "../../utils/formatter";
import { isNullOrUndefined } from "../../utils/validator";
import { DateTimePicker } from "../DateTimePicker";
import { DATE_FORMAT, DateTimePickerProps } from "../DateTimePicker/DateTimePicker";

import styles from './DateRangePicker.module.scss';

//#region Types

type DateOrUndefined = Date | Dayjs | undefined | null

type DateRangePickerProps = Omit<DateTimePickerProps, "value"|"label"|"onChange"> & {
  minValue?: DateOrUndefined;
  maxValue?: DateOrUndefined;
  minLabel?: string;
  maxLabel?: string;
  onChange?: (values: [DateOrUndefined, DateOrUndefined]) => void
}

//#endregion Types

//#region Constants

const MIN_MAX_ARRAY = ['min', 'max'];

//#endregion Constants

export const DateRangePicker = (config: DateRangePickerProps) => {
  const { genericFilters, genericZoomDateFilter } = useGenericFilterContext(),
        zoomActive = useMemo(() => !!config.filterField && genericZoomDateFilter.some((value: unknown) => !!value),[genericZoomDateFilter, config.filterField]),
        [minZoomValue, maxZoomValue] = useMemo(() => zoomActive ? genericZoomDateFilter.map((value: unknown) => dayjs(value as string, DATE_FORMAT)) : [undefined, undefined], [zoomActive, genericZoomDateFilter]),
        [minFilterName, maxFilterName] = useMemo(() => config.filterField ? MIN_MAX_ARRAY.map((value: string) => `${value}${formatTitle(config.filterField)}`) : [undefined, undefined], [config.filterField]),
        [values, setValues] = useState(() => {
          let values = [config.minValue, config.maxValue] as [DateOrUndefined, DateOrUndefined];

          if(config.filterField) {
            const minFilterValue = genericFilters[minFilterName as string] as string,
                  maxFilterValue = genericFilters[maxFilterName as string] as string;

            values = [
              values[0] ?? minFilterValue ? dayjs(minFilterValue, DATE_FORMAT) : undefined,
              values[1] ?? maxFilterValue ? dayjs(maxFilterValue, DATE_FORMAT) : undefined
            ];
          }

          return values;
        }),
        [minValue, maxValue] = useMemo(() => values.map((value: DateOrUndefined) => isNullOrUndefined(value) ? undefined : dayjs(value)), [values]),
        [changed, setChanged] = useState(false),
        commonProps = {
          type: config.type,
          timeSelection: config.timeSelection ?? false,
          size: config.size,
          clearable: config.clearable ?? true,
          disabled: config.disabled
        } as DateTimePickerProps;

  //#region Private Methods

  const onChange = (value: DateOrUndefined, index: number) => {
    setValues(([first, second]: [DateOrUndefined, DateOrUndefined]) => ([index === 0 ? value : first, index === 1 ? value : second]));
    setChanged(true);
  }

  //#endregion Private Methods

  //#region Effects

  useEffect(() => {
    setChanged(false);
  }, [config]);

  useEffect(() => {
    if(changed) {
      config.onChange?.(values);
    }
  }, [values, changed, config]);

  //#endregion Effects

  return (
    <Box width={'100%'} position='relative'>
      {
        zoomActive
          ? <Box className={styles.group}>
              <span className={styles.label}>
                <ZoomIn/>
                <span>{i18n.t('Common:SelectionByChartZoom')}</span>
              </span>
            </Box>
          : <></>
      }
      <FormControl size={config.size}>
        <Box display={zoomActive ? 'none' : 'flex'} alignItems='center' gap={2}>
          <DateTimePicker
            {...commonProps}
            filterField={minFilterName}
            value={minValue}
            label={config.minLabel ?? i18n.t('Common:StartDate')}
            onChange={(value: DateOrUndefined) => onChange(value, 0)}
            maxDateTime={maxValue}/>
          <DateTimePicker
            {...commonProps}
            filterField={maxFilterName}
            value={maxValue}
            label={config.maxLabel ?? i18n.t('Common:EndDate')}
            onChange={(value: DateOrUndefined) => onChange(value, 1)}
            minDateTime={minValue}/>
        </Box>
        {
          zoomActive
            ? <Box display='flex' alignItems='center' gap={2}>
                <DateTimePicker
                  {...commonProps}
                  disabled
                  value={minZoomValue}
                  label={config.minLabel ?? i18n.t('Common:StartDate')}/>
                <DateTimePicker
                  {...commonProps}
                  disabled
                  value={maxZoomValue}
                  label={config.maxLabel ?? i18n.t('Common:EndDate')}/>
              </Box>
            : <></>
        }
        
      </FormControl>
    </Box>
  )
}
