//#region Constants

const MIME_TYPE_REGEX = /([a-zA-Z]+|\/)+(?=;base64)/;

//#endregion Constants

export const base64ToBlob = (base64: string): Blob => {
  MIME_TYPE_REGEX.lastIndex = 0;

  const [base64Definition, base64Content]: string[] = base64.split(','),
        byteContent: string = atob(base64Content),
        mimeType: string | undefined = MIME_TYPE_REGEX.exec(base64Definition)?.[0],
        buffer: ArrayBuffer = new ArrayBuffer(byteContent.length),
        bufferView: Uint8Array = new Uint8Array(buffer);

  for(let index = 0; index < byteContent.length; ++index) {
    bufferView[index] = byteContent.charCodeAt(index);
  }

  return new Blob([buffer], { type: mimeType});
};
