import React, { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AnswersStatusType, getAnswers, GetAnswersParamsType } from '../../api'
import { QuestionTypeAPI, StoreAnswersHistoryTypeAPI } from '../../api/types'
import { AnswersConsts, AnswersStatusesType } from '../../Constants'
import { Dropdown } from '../../elements/Dropdown'
import { TextInput } from '../../elements/TextInput'
import usePagyRequest from '../../utils/usePagyRequest'
import useSearchParams from '../../utils/useSearchParams'
import { Loading } from '../shared/Loading'
import { StoreAnswersHistoryCard } from '../StoreAnswersHistoryCard'

import styles from './MissionAnswers.module.scss'


const { ANSWERS_STATUSES } = AnswersConsts

type MissionAnswersProps = {
  missionId: number
  questions: QuestionTypeAPI[]
}


export const MissionAnswers = ({
  missionId,
  questions
}: MissionAnswersProps) => {
  const i18n = useTranslation()
  const { getSearchParams, searchParams, updateSearchParams, setSearchParams } = useSearchParams()

  const [storesAnswersHistory, setStoresAnswersHistory] = useState<StoreAnswersHistoryTypeAPI[]>([])
  const [storeFilterText, setStoreFilterText] = useState(searchParams.get('store') || '')

  const answersParams = useMemo(() => {
    // eslint-disable-next-line i18next/no-literal-string
    const status = searchParams.get('status') as AnswersStatusType | null || 'all'
    const store = searchParams.get('store')
    return { status, store }
  }, [searchParams])


  // Deals with fetching the answers and paginating them
  const { loading, pagyIndicator } = usePagyRequest<GetAnswersParamsType, StoreAnswersHistoryTypeAPI>({
    requestFunction: getAnswers,
    requestParams: { missionId, ...answersParams, count: 10 },
    setData: setStoresAnswersHistory,
    errorText: i18n.t('Answers:ErrorFetchingAnswers'),
    preRequest: () => {
      if (!Object.keys(ANSWERS_STATUSES).includes(answersParams.status)) {
        updateSearchParams({ status: 'all' })
        return true
      }
      return false
    }
  })


  // Handle the store filter debounce
  const handleDebounceStoreFilter = useCallback((v: string) => {
    if (v === '') {
      const newSearchParams = getSearchParams()
      newSearchParams.delete('store')
      newSearchParams.set('page', '1')
      setSearchParams(newSearchParams)
    } else {
      updateSearchParams({ store: v, page: '1' })
    }
  }, [updateSearchParams, setSearchParams, getSearchParams])


  return (
    <div>
      <div className={styles.filters}>
        <TextInput
          value={storeFilterText}
          placeholder={i18n.t('MissionWizard:WriteHereStoreName')}
          disabled={loading}
          onChange={setStoreFilterText}
          debounceHandler={handleDebounceStoreFilter}
          pill
          search
          customWidth='25rem'
        />
        <Dropdown<AnswersStatusesType>
          options={Object.values(ANSWERS_STATUSES)}
          selected={ANSWERS_STATUSES[searchParams.get('status') as keyof typeof ANSWERS_STATUSES] || ANSWERS_STATUSES.all}
          getOptionLabel={opt => opt.label}
          getOptionId={opt => opt.id}
          onChange={v => updateSearchParams({ status: v.id, page: '1' })}
          pill
          customWidth='15rem'
        />
      </div>
      {loading ? (
        <Loading />
      ) : (
        storesAnswersHistory.length === 0 ? (
          <div className={styles.noAnswers}>
            <h3>
              {i18n.t('Answers:NoAnswers')}
            </h3>
          </div>
        ) : (
          <>
            <div className={styles.missionsList}>
              {storesAnswersHistory.map(storeAnswersHistory => (
                <StoreAnswersHistoryCard
                  key={`answerCard_${storeAnswersHistory[0].storeName}_mission_${missionId}`}
                  storeAnswersHistory={storeAnswersHistory}
                  questions={questions}
                />
              ))}
            </div>
            {pagyIndicator}
          </>
        )
      )}
    </div>
  )
}
