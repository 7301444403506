import { BarChart, RestartAlt, SaveAlt, ScatterPlot, Timeline, ZoomIn } from "@mui/icons-material";
import { Chip, Tooltip } from "@mui/material";
import { ECharts } from "echarts";
import { useEffect, useState, Ref, forwardRef, useImperativeHandle } from "react";

import { useGenericFilterContext } from "../../contexts/GenericFilterContext";
import i18n from "../../localization";
import { addDateTime, DateUnitType } from "../../utils/dates";
import { formatString, formatValueByType, ValueType } from "../../utils/formatter";
import { timeIsTruncated } from "../../utils/validator";
import { ActionsMenu, ActionsMenuItem } from "../ActionsMenu/ActionsMenu";

import { ChartAction, ChartAxisType, ChartToolboxActionType, ChartToolboxTagValue, ChartType, DefaultChartActionType } from "./ChartDefinition";


//#region Interfaces

interface ChartToolboxConfig {
  chartInstance?: ECharts;
  chartType: ChartType;
  values: ChartToolboxTagValue;
  axisType?: ChartAxisType;
  xAxisValueType?: ValueType;
  onAction: (action: ChartToolboxActionType, toggle?: boolean) => void;
}

interface ChartActionConfig extends Omit<ActionsMenuItem<ChartToolboxActionType>, 'hiddenCondition'> {
  hiddenCondition?: (chartType: ChartType) => boolean;
}

export interface ChartToolboxRef {
  forwardActionTrigger: (action: ChartAction, original?: boolean) => void;
}

//#endregion Interfaces

//#region Types

type InternalZoomValue = string | number | undefined;

//#endregion Types

//#region Constants

const ALLOWED_SHIFTS = [ChartType.BAR, ChartType.LINE, ChartType.SCATTER],
  ALLOWED_ZOOMS = [ChartType.BAR, ChartType.LINE, ChartType.SCATTER],
  ACTIONS_MENU_CONTEXT = 'Chart',
  INIT_START_ZOOM = 0,
  INIT_END_ZOOM = 100,
  ACTIONS: ChartActionConfig[] = [
    {
      id: ChartToolboxActionType.EXPORT,
      icon: SaveAlt
    },
    {
      id: ChartToolboxActionType.SCATTER_SHIFT,
      icon: ScatterPlot,
      hiddenCondition: (chartType: ChartType) => !ALLOWED_SHIFTS.includes(chartType) || chartType === ChartType.SCATTER
    },
    {
      id: ChartToolboxActionType.LINE_SHIFT,
      icon: Timeline,
      hiddenCondition: (chartType: ChartType) => !ALLOWED_SHIFTS.includes(chartType) || chartType === ChartType.LINE
    },
    {
      id: ChartToolboxActionType.BAR_SHIFT,
      icon: BarChart,
      hiddenCondition: (chartType: ChartType) => !ALLOWED_SHIFTS.includes(chartType) || chartType === ChartType.BAR
    },
    {
      id: ChartToolboxActionType.ZOOM,
      icon: ZoomIn,
      hiddenCondition: (chartType: ChartType) => !ALLOWED_ZOOMS.includes(chartType),
      toggle: true
    },
    {
      id: ChartToolboxActionType.RESET_ZOOM,
      icon: RestartAlt,
      hiddenCondition: (chartType: ChartType) => !ALLOWED_ZOOMS.includes(chartType),
      disabled: true
    }
  ];

//#endregion Constants


export const ChartToolbox = forwardRef((config: ChartToolboxConfig, ref: Ref<ChartToolboxRef>) => {
  const {updateGenericZoom} = useGenericFilterContext(),
        [actions, setActions] = useState([] as ChartActionConfig[]),
        [zoomValues, setZoomValues] = useState([undefined, undefined] as InternalZoomValue[]);

  //#region Private Methods

  const onDataZoom = ({start, startValue, end, endValue}: Partial<ChartAction>, original?: boolean) => {
    const zoomReseted = start === INIT_START_ZOOM && end === INIT_END_ZOOM,
          localActions = [...actions],
          action = localActions.find(({id}: ChartActionConfig) => id === ChartToolboxActionType.RESET_ZOOM);

    if(zoomReseted) {
      const values = [undefined, undefined]
      setZoomValues(values);

      if(original) {
        updateGenericZoom(values as [unknown, unknown], config.axisType);
      }
    } else {
      const values = [startValue ?? start, endValue ?? end].map((value: number | undefined, index: number) => {
        switch(config.axisType) {
          case ChartAxisType.TIME:
            const date = new Date(value ?? 0),
                  sum = !timeIsTruncated(date) && index === 0 ? 1 : 0;
            return formatValueByType(addDateTime(date, sum, DateUnitType.DAY), config.xAxisValueType ?? ValueType.DATE);
          default:
            return value;
        }
      });

      if(original) {
        updateGenericZoom(values as [unknown, unknown], config.axisType);
      }

      setZoomValues(values);
    }

    if(action) {
      action.disabled = zoomReseted;
      setActions(localActions);
    }
  } 

  //#endregion Private Methods

  //#region Effects

  useEffect(() => {
    const actions = ACTIONS.map((action: ChartActionConfig) => ({...action, hiddenCondition: () => action.hiddenCondition?.(config.chartType)} as ChartActionConfig));
    switch(typeof config.values) {
      case "boolean":
        setActions([]);
        break;
      case "object":
      case "string":
        setActions(actions.filter(({id}: ChartActionConfig) => (config.values as ChartToolboxActionType[]).includes(id) || config.values === 'All'));
        break;
      default:
        setActions(actions);
        break;
    }
  }, [config.values, config.chartType]);

  //#endregion Effects

  //#region Public Methods

  useImperativeHandle(ref, () => ({
    forwardActionTrigger: ({type, ...action}: ChartAction, original?: boolean)  => {
      switch(type) {
        case DefaultChartActionType.DATA_ZOOM:
          onDataZoom(action, original);
          break;
      }
    }
  }));

  //#endregion Public Methods

  return (
    <ActionsMenu<ChartToolboxActionType>
      absolute
      actions={actions as ActionsMenuItem<ChartToolboxActionType>[]}
      onClick={config.onAction}
      context={ACTIONS_MENU_CONTEXT}>
      {
        zoomValues.some((value: InternalZoomValue) => value)
          ? <Tooltip title={formatString(i18n.t('Chart:AppliedZoomIn'), zoomValues.join(' - '))} placement='top' arrow>
              <Chip icon={<ZoomIn/>} color='primary' label={i18n.t('Chart:Zoom')} size='small' />
            </Tooltip>
          : <></>
      }
    </ActionsMenu>
  );
});
