import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { StoreTypeAPI } from '../../api/types'
import { Map } from '../Map'

import styles from './StoresSummary.module.scss'


type StoresSummaryProps = {
  stores: StoreTypeAPI[]
  missionFinished: boolean
}

export const StoresSummary = ({
  stores,
  missionFinished
}: StoresSummaryProps) => {
  const i18n = useTranslation()


  /* eslint-disable-next-line i18next/no-literal-string */
  const storesNotDone = useMemo(() => stores.filter(store => ['invisible', 'preview', 'available', 'undone'].includes(store.status)), [stores])
  const storesToValidate = useMemo(() => stores.filter(store => store.status === 'analysis'), [stores])
  const storesCompleted = useMemo(() => stores.filter(store => store.status === 'completed'), [stores])


  return (
    <div className={styles.storesSummary}>
      <div className={styles.mapContainer}>
        <Map
          markers={stores.map(s => ({
            position: [s.location.latitude, s.location.longitude],
            popUp: s.name,
            status: s.status
          })) || []}
        />
      </div>
      <div className={styles.storesColumns}>
        <div className={`${styles.storesColumn} ${missionFinished && styles.cantDo}`}>
          <div className={styles.storesHeader}>
            <h4>
              {missionFinished ? i18n.t('Mission:StoresUndone') : i18n.t('Mission:StoresToBeDone')}
            </h4>
            <h4 className={styles.pill}>
              {`${storesNotDone.length}`}
            </h4>
          </div>
          <div className={styles.listContainer}>
            <div className={styles.storesList}>
              {storesNotDone.map((store, i) => (
                <h5 key={`storeListed_${store.id}`}>
                  {`${i+1}) ${store.name}${i === storesNotDone.length-1 ? '.' : ';'}`}
                </h5>
              ))}
            </div>
          </div>
        </div>
        <div className={`${styles.storesColumn} ${styles.toValidate}`}>
          <div className={styles.storesHeader}>
            <h4>
              {i18n.t('Mission:StoresToValidate')}
            </h4>
            <h4 className={styles.pill}>
              {`${storesToValidate.length}`}
            </h4>
          </div>
          <div className={styles.listContainer}>
            <div className={styles.storesList}>
              {storesToValidate.map((store, i) => (
                <h5 key={`storeListed_${store.id}`}>
                  {`${i+1}) ${store.name}${i === storesToValidate.length-1 ? '.' : ';'}`}
                </h5>
              ))}
            </div>
          </div>
        </div>
        <div className={`${styles.storesColumn} ${styles.completed}`}>
          <div className={styles.storesHeader}>
            <h4>
              {i18n.t('Mission:StoresCompleted')}
            </h4>
            <h4 className={styles.pill}>
              {`${storesCompleted.length}`}
            </h4>
          </div>
          <div className={styles.listContainer}>
            <div className={styles.storesList}>
              {storesCompleted.map((store, i) => (
                <h5 key={`storeListed_${store.id}`}>
                  {`${i+1}) ${store.name}${i === storesCompleted.length-1 ? '.' : ';'}`}
                </h5>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
