import React, {
  useState,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useEffect
} from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

import {
  EditableQuestionKeysStep1,
  MissionStatusType,
  MissionUpdateTypeAPI,
  QuestionTypeAPI,
  QuestionUpdateTypeAPI
} from '../../../api/types'
import ArrowTrade from '../../../assets/icons/arrowTrade.svg'
import { NewQuestion } from '../../../components/NewQuestion'
import { WizardConsts } from '../../../Constants'
import { AbsoluteButton } from '../../../elements/AbsoluteButton'
import { Button } from '../../../elements/Button'
import { Checkbox } from '../../../elements/Checkbox'
import { DecimalNumberInput } from '../../../elements/DecimalNumberInput'
import { TextInput } from '../../../elements/TextInput'
import { WizardContextType } from '../Wizard'

import styles from './Step1.module.scss'


const { DEFAULT_NEW_QUESTION, MAX_CHARS_MISSION_DESCRIPTION, QUESTION_TYPES } = WizardConsts

export type UpdateQuestionParamsType = {
  id: number,
  value: string | boolean | File | null,
  toUpdate: Exclude<EditableQuestionKeysStep1, 'questionType'>
} | {
  id: number,
  value: keyof typeof QUESTION_TYPES,
  toUpdate: 'questionType'
}


export const Step1 = forwardRef(() => {
  const i18n = useTranslation()

  const { mission, setStep, stepRef } = useOutletContext<WizardContextType>()

  const [title, setTitle] = useState(mission.title || '')
  const [description, setDescription] = useState(mission.description || '')
  const [value, setValue] = useState(mission.value || 0)
  const [isCrowdsource, setIsCrowdsource] = useState(mission.isCrowdsource || false)
  const [walletEnabled, setWalletEnabled] = useState(mission.walletEnabled || false)
  const [questions, setQuestions] = useState<QuestionTypeAPI[]>(
    mission.questions || [DEFAULT_NEW_QUESTION() as QuestionTypeAPI]
  )


  useEffect(() => {
    setStep(1)
  }, [setStep])


  useImperativeHandle(stepRef, () => ({
    updateData(newStatus: MissionStatusType): MissionUpdateTypeAPI {
      // Create questionsAttributes to send to API
      // Change answerOptions to answerOptionsAttributes to send to API
      let questionsAttributes = questions.map(({
        answerOptions: answerOptionsAttributes,
        ...rest
      }) => ({ ...rest, answerOptionsAttributes })) as QuestionUpdateTypeAPI[]
      // Remove answerOptionsAttributes if questionType doesn't have them
      // Remove expectedAnswer if it's an empty string or null
      // Remove shortName if it's an empty string
      // Set to null validationText, validationValue, maxValidations if toValidate is false
      //   even if the validation step is the third, we need to remove the validation attributes if toValidate is false
      //   because we can already have this attributes setted if the user has camed back from the third step
      questionsAttributes = questionsAttributes.map(q => {
        if (!QUESTION_TYPES[q.questionType].hasOptions) {
          delete q.answerOptionsAttributes
        }
        if (q.expectedAnswer === '' || q.expectedAnswer === null) { delete q.expectedAnswer }
        if (q.shortName === '') { delete q.shortName }
        if (!q.toValidate) {
          q.validationText = null
          q.validationValue = null
          q.maxValidations = null
        }
        return q
      })
      // Create request body
      return ({mission: {
        status: newStatus,
        title,
        description,
        questionsAttributes,
        value,
        isCrowdsource,
        walletEnabled
      }})
    },

    getWarnings(): string[] {
      const newWarnings = []
      if (title.length === 0) { newWarnings.push(i18n.t('MissionWizard:WarningNoTitle')) }
      if (description.length === 0) { newWarnings.push(i18n.t('MissionWizard:WarningNoDescription')) }
      questions.forEach((q, i) => {
        if (q.text.length === 0) { newWarnings.push(i18n.t('MissionWizard:WarningNoQuestion', {questionNumber: i+1})) }
        if (QUESTION_TYPES[q.questionType].hasOptions) {
          q.answerOptions.forEach((ao, j) => {
            if (ao.text.length === 0) { newWarnings.push(i18n.t('MissionWizard:WarningNoAnswerOption', {questionNumber: i+1, answerOptionNumber: j+1})) }
          })
        }
      })
      return newWarnings
    }
  }))


  const addQuestion = useCallback(() => {
    const newQuestion = DEFAULT_NEW_QUESTION() as QuestionTypeAPI
    newQuestion.order = questions.length
    setQuestions([...questions, newQuestion])
  }, [questions])


  const deleteQuestion = useCallback((index: number) => {
    let newQuestions = questions.slice(0, index).concat(questions.slice(index+1))
    newQuestions = newQuestions.map((q, i) => { q.order = i; return q })
    setQuestions(newQuestions)
  }, [questions])

  const updateQuestion = useCallback(({
    id,
    value,
    toUpdate
  }: UpdateQuestionParamsType) => {
    const newQuestion = {
      ...questions[id],
      [toUpdate]: value
    }
    if (toUpdate === 'questionType') {
      newQuestion.expectedAnswer = null
      if (newQuestion.answerOptions.length < 2)
        newQuestion.answerOptions = [{order: 0, text: ''}, {order: 1, text: ''}]
      if (!QUESTION_TYPES[value].validationEnabled)
        newQuestion.toValidate = false
    }
    if (toUpdate === 'requiresPhotoValidation' && value === false)
      newQuestion.toValidate = false
    if (toUpdate === 'image' && value === null)
      newQuestion.toValidate = false
    setQuestions(questions.slice(0, id).concat(newQuestion).concat(questions.slice(id+1)))
  }, [questions])


  const updateAnswerOption = useCallback((
    index: number,
    value: string,
    answerOptionIndex: number,
    action: 'add' | 'delete' | 'update'
  ) => {
    const newQuestion = questions[index]
    if (action === 'add') {
      newQuestion.answerOptions.push({
        text: '',
        order: newQuestion.answerOptions.length,
      })
    } else if (action === 'delete') {
      let newAnswerOptions = newQuestion.answerOptions.slice(0, answerOptionIndex).concat(newQuestion.answerOptions.slice(answerOptionIndex+1))
      newAnswerOptions = newAnswerOptions.map((ao, i) => { ao.order = i; return ao })
      newQuestion.answerOptions = newAnswerOptions
    } else if (action === 'update') {
      newQuestion.answerOptions[answerOptionIndex] = {
        ...newQuestion.answerOptions[answerOptionIndex],
        text: value
      }
    }
    setQuestions(questions.slice(0, index).concat(newQuestion).concat(questions.slice(index+1)))
  }, [questions])


  const tradeQuestions = useCallback((i: number) => {
    const newQuestions = questions.slice()
    newQuestions[i] = {...questions[i-1], order: i}
    newQuestions[i-1] = {...questions[i], order: i-1}
    setQuestions(newQuestions)
  }, [questions])


  return (
    <div className={styles.step1}>
      <TextInput
        title={i18n.t('Common:Name')}
        placeholder={i18n.t('MissionWizard:MissionName')}
        value={title}
        onChange={setTitle}
      />
      <DecimalNumberInput
        title={i18n.t('Common:Value')}
        value={value}
        onChange={v => v ? setValue(v) : setValue(0)}
        endSymbol='€'
      />
      <TextInput
        title={i18n.t('Common:Description')}
        placeholder={i18n.t('MissionWizard:WriteDescriptionHere', {numChars: MAX_CHARS_MISSION_DESCRIPTION})}
        value={description}
        onChange={setDescription}
        multiline
        maxLength={MAX_CHARS_MISSION_DESCRIPTION}
      />
      <Checkbox
        handler={() => setIsCrowdsource(!isCrowdsource)}
        checked={isCrowdsource}
        text={i18n.t('MissionWizard:IsCrowdsource')}
      />
      <Checkbox
        handler={() => setWalletEnabled(!walletEnabled)}
        checked={walletEnabled}
        text={i18n.t('MissionWizard:WalletEnabled')}
      />
      <div className={styles.questions}>
        <h3>
          {i18n.t('MissionWizard:MissionQuestions')}
        </h3>
        {questions.map((q, i) => (
          <div key={'question_'+i}>
            {i > 0 && (
              <div className={styles.tradeButtonContainer}>
                <AbsoluteButton
                  icon={ArrowTrade}
                  handler={() => tradeQuestions(i)}
                />
              </div>
            )}
            <NewQuestion
              id={i}
              question={q}
              handleOnDelete={deleteQuestion}
              handleUpdate={updateQuestion}
              handleUpdateAnswerOption={updateAnswerOption}
              onlyQuestion={questions.length === 1 ? true : false}
            />
          </div>
        ))}
      </div>
      <Button
        title={i18n.t('MissionWizard:AddQuestion')}
        handler={addQuestion}
        variant='primaryWhite'
        fullWidth
      />
    </div>
  )
})
