import React, { ReactNode, useMemo, useState } from 'react'

import styles from './ListCard.module.scss'

type MissionCardProps = {
  children: ReactNode
  handler: (() => void)
  grey?: boolean
  box?: boolean
  disabled?: boolean
}

export const ListCard = ({
  children,
  handler,
  grey,
  box,
  disabled
}: MissionCardProps) => {
  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)

  const considerHover = useMemo(() => !disabled && hover, [disabled, hover])
  const considerFocus = useMemo(() => !disabled && focus, [disabled, focus])

  return (
    <div
      className={`
        ${styles.listCard}
        ${grey && styles.grey}
        ${box && styles.box}
        ${considerHover && styles.hover}
        ${considerFocus && styles.focus}
        ${disabled && styles.disabled}
      `}
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      onClick={disabled ? () => {} : handler}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => { setHover(false); setFocus(false) }}
      onMouseDown={() => setFocus(true)}
      onMouseUp={() => setFocus(false)}
    >
      {children}
    </div>
  )
}
