import { Box, Button } from '@mui/material'
import {
  DateTimePicker as DateTimePickerMUI,
  DatePicker as DatePickerMUI,
  DatePickerProps as DatePickerMUIProps,
  DateTimePickerProps as DateTimePickerMUIProps,
  ClearIcon, PickersActionBarAction,
  LocalizationProvider
} from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { enUS, frFR, esES, ptBR } from '@mui/x-date-pickers/locales';
import dayjs, { Dayjs } from 'dayjs'
import React, {
  createElement,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import 'dayjs/locale/en'
import 'dayjs/locale/pt'
import 'dayjs/locale/fr'
import 'dayjs/locale/es'

import { useGenericFilterContext } from '../../contexts/GenericFilterContext'
import i18n from '../../localization'
import { parseDayJs } from '../../utils/dates';
import { formatValueByType, ValueType } from '../../utils/formatter'
import { isEmptyOrWhiteSpace, isMobile, isNullOrUndefined } from '../../utils/validator'

import styles from './DateTimePicker.module.scss'

//#region Enums

export enum DateTimePickerType {
  FILTER = 'filter',
  SELECTION = 'selection'
}

enum DateTimePickerSizeType {
  SMALL = 'small',
  MEDIUM = 'medium'
}

enum DayjsLocaleTagType {
  EN = 'en',
  PT = 'pt',
  ES = 'es',
  FR = 'fr'
}

enum DateTimePickerChangeEvents {
  ON_CHANGE = 'onChange',
  ON_ACCEPT = 'onAccept'
}

//#endregion Enums

//#region Types

type DateTimePickerSize = `${DateTimePickerSizeType}`

export type DateTimePickerProps = {
  label?: string
  onChange: (value: Dayjs | null) => void
  value?: Date | Dayjs | null
  disablePast?: boolean
  minDateTime?: Dayjs
  maxDateTime?: Dayjs
  timeSelection?: boolean
  size?: DateTimePickerSize
  clearable?: boolean
  disabled?: boolean
  filterField?: string
  type?: DateTimePickerType
}

type InternalProps<TDate> = DatePickerMUIProps<TDate> | DateTimePickerMUIProps<TDate>

//#endregion Types

//#region Interface

interface GridLocale {
  components: {
    MuiLocalizationProvider: {
      defaultProps: {
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        localeText: any
      }
    }
  }
}

//#endregion Interface

//#region Constants

export const DATE_FORMAT = 'DD/MM/YYYY'
export const DATE_TIME_FORMAT = 'DD/MM/YYYY HH:mm'

const ACTIONS = ['cancel', 'accept']
const IS_MOBILE = isMobile()

//#endregion Constants

export const DateTimePicker = (config: DateTimePickerProps) => {
  const {genericFilters, updateGenericFilter} = useGenericFilterContext()
  const [value, setValue] = useState(() => {
          const value = config.value ?? null
          if (!value && config.filterField && genericFilters[config.filterField]) {
            return dayjs(genericFilters[config.filterField] as string, DATE_FORMAT)
          }
          return parseDayJs(value)
        })


  const isFilter = useMemo(() => {
    return config.type === DateTimePickerType.FILTER
  }, [config.type])


  const [muiLang, dayjsLang]: [GridLocale, DayjsLocaleTagType] = useMemo(() => {
    switch(i18n.language) {
      case 'pt':
      case 'pt-PT':
        return [ptBR, DayjsLocaleTagType.PT]
      case 'es':
      case 'es-ES':
        return [esES, DayjsLocaleTagType.ES]
      case 'fr':
      case 'fr-FR':
        return [frFR, DayjsLocaleTagType.FR]
      default:
        return [enUS, DayjsLocaleTagType.EN]
    }
  }, [])


  const baseProps: InternalProps<Dayjs> = useMemo(() => ({
    label: config.label,
    value: value,
    disablePast: config.disablePast,
    className: [styles.dateTimePicker, styles[config.size ?? DateTimePickerSizeType.MEDIUM], value ? styles.filled : ''].join(' '),
    slotProps: isFilter ? {
      actionBar: {
        actions: ACTIONS as PickersActionBarAction[],
        className: styles.actions
      }
    } : undefined,
    disabled: config.disabled,
    closeOnSelect: !isFilter,
    localeText: muiLang?.components.MuiLocalizationProvider.defaultProps.localeText
  }), [config, value, muiLang, isFilter])


  const specifigProps = useMemo(() => {
    if (!config.timeSelection) {
      return{
        maxDate: config.maxDateTime,
        minDate: config.minDateTime,
        format: DATE_FORMAT
      } as DatePickerMUIProps<Dayjs>
    } else {
      return {
        maxDateTime: config.maxDateTime,
        minDateTime: config.minDateTime,
        ampm: false,
        format: DATE_TIME_FORMAT
      }  as DateTimePickerMUIProps<Dayjs>
    }
  }, [config])


  //eslint-disable-next-line @typescript-eslint/no-explicit-any
  const picker: FunctionComponent<any> = useMemo(() => {
    return config.timeSelection ? DateTimePickerMUI : DatePickerMUI
  }, [config.timeSelection])


  const desktopClearClasses = useMemo(() => {
    return [styles.desktopClear,!IS_MOBILE && !isEmptyOrWhiteSpace(value) ? undefined : styles.hidden].filter((value: string | undefined) => value).join(' ')
  }, [value])


  //#region Private Methods

  const updateValue = useCallback((value: Dayjs | null) => {
    setValue(() => parseDayJs(value))
    config.onChange(value)
  }, [config])

  const clearSelection = useCallback(() => updateValue(null), [updateValue])

  //#endregion Private Methods

  //#region Effects

  useEffect(() => {
    if (config.filterField) {
      updateGenericFilter(config.filterField, value ? formatValueByType(value, config.timeSelection ? ValueType.DATE_TIME : ValueType.DATE) : undefined)
    }
    // Disable eslint for updateGenericFilter and config.filterField
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, config.timeSelection])


  useEffect(() => {
    if (config.filterField && isNullOrUndefined(genericFilters[config.filterField]) && value) {
      updateValue(null)
    }
    // Disable eslint for updateValue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [genericFilters, config.filterField])


  useEffect(() => {
    setValue(parseDayJs(config.value))
  }, [config.value])

  //#endregion Effects

  return (
    <Box
      display='flex'
      alignItems='stretch'
      justifyContent='center'
      gap={1}
      position='relative'
      width={'100%'}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={dayjsLang}>
        {createElement(picker, {...baseProps, ...specifigProps, [isFilter ? DateTimePickerChangeEvents.ON_ACCEPT : DateTimePickerChangeEvents.ON_CHANGE]: updateValue})}
      </LocalizationProvider>
      {IS_MOBILE ? (
        <Button
          variant='outlined'
          onClick={clearSelection}
          className={styles.nativeClear}
          disabled={config.disabled || isEmptyOrWhiteSpace(value)}
        >
          <ClearIcon />
        </Button>
      ) : (
        <button
          onClick={clearSelection}
          className={desktopClearClasses}
          disabled={config.disabled}
        >
          <ClearIcon />
        </button>
      )}
    </Box>
  )
}
