import React, { useEffect, useState } from 'react'

import { useObserveElementWidth } from '../../hooks/useObserveElementWidth'

import styles from './PercentageBar.module.scss'


type PercentageBarProps = {
  percentage: number
  percentage2?: number
}

export const PercentageBar = ({
  percentage,
  percentage2,
}: PercentageBarProps) => {
  const percentageBarObserver = useObserveElementWidth<HTMLDivElement>()
  const [progressBarWidth, setProgressBarWidth] = useState(0)
  const [progressBarWidth2, setProgressBarWidth2] = useState(0)
  const [percentageOutside, setPercentageOutside] = useState(false)
  const [percentageOutside2, setPercentageOutside2] = useState(false)
  const progressBarObserver = useObserveElementWidth<HTMLDivElement>()
  const progressBarObserver2 = useObserveElementWidth<HTMLDivElement>()


  // Calculate the width of the progress bar(s)
  useEffect(() => {
    const percentageBarWidth = percentageBarObserver.width

    const newWidth = percentageBarWidth * percentage / 100
    setProgressBarWidth(newWidth)
    if (newWidth < 30) setPercentageOutside(true)
    else setPercentageOutside(false)

    if (percentage2 === undefined) return
    const newWidth2 = percentageBarWidth * percentage2 / 100
    setProgressBarWidth2(newWidth2 + newWidth)
    if (newWidth2 < 30) setPercentageOutside2(true)
    else setPercentageOutside2(false)
  }, [percentage, percentageBarObserver.ref, percentageBarObserver.width, progressBarObserver.ref, progressBarObserver.width, percentage2, progressBarObserver2.ref, progressBarObserver2.width])


  return (
    <div className={styles.percentageBar} ref={percentageBarObserver.ref}>
      <div className={styles.progressBar} style={{ width: progressBarWidth }} ref={progressBarObserver.ref}>
        {(!percentageOutside || percentage2 === undefined) && (
          <h6 className={`${styles.percentage} ${percentageOutside && styles.outside}`}>
            {`${percentage}%`}
          </h6>
        )}
      </div>
      {percentage2 !== undefined && (
        <div className={`${styles.progressBar} ${styles.second}`} style={{ width: progressBarWidth2 }} ref={progressBarObserver2.ref}>
          <h6 className={`${styles.percentage} ${percentageOutside2 && styles.outside}`}>
            {`${percentage + percentage2}%`}
          </h6>
        </div>
      )}
    </div>
  )
}
