import { Box, Grid } from "@mui/material";

import { DashboardChartType } from "../../../components/DashboardChart/DashboardChart";
import { KpiCardType } from "../../../components/KpiCard/KpiCard";
import i18n from "../../../localization";
import { DashboardWidgetType, getWidgetsRender, WidgetsStructure } from "../../../utils/dashboard";
import { WrapperHeight, WrapperWidth } from "../../../utils/wrapper";

//#region Constants

const YESTERDAY_KPIS_BLOCKS: WidgetsStructure[] = [
    {
      [KpiCardType.DAILY_SELLOUT]: {
        filters: true,
        width: WrapperWidth.FULL,
        height: WrapperHeight.SMALL
      },
      [KpiCardType.PREVIOUS_MONTH_SELLOUT]: {
        filters: true,
        width: WrapperWidth.ONE_THIRD,
        height: WrapperHeight.SMALL
      },
      [KpiCardType.WEEKEND_SELLOUT]: {
        filters: true,
        width: WrapperWidth.ONE_THIRD,
        height: WrapperHeight.SMALL
      },
      [KpiCardType.WEEK_SELLOUT]: {
        filters: true,
        width: WrapperWidth.ONE_THIRD,
        height: WrapperHeight.SMALL
      }
    }, {
      [KpiCardType.CURRENT_STOCK]: {
        filters: true,
        width: WrapperWidth.FULL,
        height: WrapperHeight.SMALL
      },
      [KpiCardType.PRES_STOCK]: {
        filters: true,
        width: WrapperWidth.HALF,
        height: WrapperHeight.SMALL
      },
      [KpiCardType.IN_SUPPLY]: {
        filters: true,
        width: WrapperWidth.HALF,
        height: WrapperHeight.SMALL
      },
      [KpiCardType.DAILY_EXPECTED]: {
        filters: true,
        width: WrapperWidth.HALF,
        height: WrapperHeight.SMALL
      },
      [KpiCardType.DAILY_INTRANSIT]: {
        filters: true,
        width: WrapperWidth.HALF,
        height: WrapperHeight.SMALL
      }
    }, {
      [KpiCardType.REPLACEMENT_CYCLES]: {
        filters: true,
        width: WrapperWidth.HALF,
        height: WrapperHeight.SMALL
      },
      [KpiCardType.STOCK_SELLOUT_RATIO]: {
        filters: true,
        width: WrapperWidth.HALF,
        height: WrapperHeight.SMALL
      }
    }
  ],
  STATUS_KPIS: WidgetsStructure = {
    [KpiCardType.DAY]: {
      filters: true,
      width: WrapperWidth.FULL,
      height: WrapperHeight.SMALL
    }
  },
  STATUS_CHARTS: WidgetsStructure = {
    [DashboardChartType.OUTAGE_RISK_3_DAYS]: {
      filters: true,
      width: WrapperWidth.FULL,
      keepRatio: true
    },
    [DashboardChartType.OUTAGE_RISK_5_DAYS]: {
      filters: true,
      width: WrapperWidth.FULL,
      keepRatio: true
    }
  },
  DAYS_RANGE_KPIS: WidgetsStructure = {
    [KpiCardType.PERCENTAGE_OUTAGE_30]: {
      filters: true,
      width: WrapperWidth.ONE_THIRD,
      height: WrapperHeight.SMALL
    },
    [KpiCardType.PERCENTAGE_OUTAGE_60]: {
      filters: true,
      width: WrapperWidth.ONE_THIRD,
      height: WrapperHeight.SMALL
    },
    [KpiCardType.PERCENTAGE_OUTAGE_120]: {
      filters: true,
      width: WrapperWidth.ONE_THIRD,
      height: WrapperHeight.SMALL
    },
    [KpiCardType.PERCENTAGE_DAYS_WITHOUT_SALES_30]: {
      filters: true,
      width: WrapperWidth.ONE_THIRD,
      height: WrapperHeight.SMALL
    },
    [KpiCardType.PERCENTAGE_DAYS_WITHOUT_SALES_60]: {
      filters: true,
      width: WrapperWidth.ONE_THIRD,
      height: WrapperHeight.SMALL
    },
    [KpiCardType.PERCENTAGE_DAYS_WITHOUT_SALES_120]: {
      filters: true,
      width: WrapperWidth.ONE_THIRD,
      height: WrapperHeight.SMALL
    },
    [KpiCardType.LOST_SALES_30]: {
      filters: true,
      width: WrapperWidth.ONE_THIRD,
      height: WrapperHeight.SMALL
    },
    [KpiCardType.LOST_SALES_60]: {
      filters: true,
      width: WrapperWidth.ONE_THIRD,
      height: WrapperHeight.SMALL
    },
    [KpiCardType.LOST_SALES_120]: {
      filters: true,
      width: WrapperWidth.ONE_THIRD,
      height: WrapperHeight.SMALL
    },
    [KpiCardType.PERCENTAGE_INCOMPLETE_LINEAR_DAYS_30]: {
      filters: true,
      width: WrapperWidth.ONE_THIRD,
      height: WrapperHeight.SMALL
    },
    [KpiCardType.PERCENTAGE_INCOMPLETE_LINEAR_DAYS_60]: {
      filters: true,
      width: WrapperWidth.ONE_THIRD,
      height: WrapperHeight.SMALL
    },
    [KpiCardType.PERCENTAGE_INCOMPLETE_LINEAR_DAYS_120]: {
      filters: true,
      width: WrapperWidth.ONE_THIRD,
      height: WrapperHeight.SMALL
    },
    [KpiCardType.PERCENTAGE_SELLOUT_VOLATILITY_60]: {
      filters: true,
      width: WrapperWidth.HALF,
      height: WrapperHeight.SMALL
    },
    [KpiCardType.PERCENTAGE_SELLOUT_VOLATILITY_120]: {
      filters: true,
      width: WrapperWidth.HALF,
      height: WrapperHeight.SMALL
    },
    [KpiCardType.INSIGHT_1]: {
      filters: true,
      width: WrapperWidth.FULL,
      height: WrapperHeight.SMALL
    },
    [KpiCardType.INSIGHT_2]: {
      filters: true,
      width: WrapperWidth.FULL,
      height: WrapperHeight.SMALL
    }
  },
  DAYS_TITLES: string[] = [
    '30Days',
    '60Days',
    '120Days'
  ];

//#endregion Constants

export const DailyObservatory = () => {
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item md={12} lg={4}>
          <h3 className='mb-2'>{i18n.t('Dashboard:YesterdayData')}</h3>
          <Grid container spacing={1}>
            {
              YESTERDAY_KPIS_BLOCKS.map((block, index: number) => {
                const content = getWidgetsRender(DashboardWidgetType.KPIS, block);

                if(index > 0) {
                  content.splice(0, 0, <Box key={`box-${index}`} width={WrapperWidth.FULL} height={WrapperHeight.TINY}/>);
                }
                
                return content;
              })
            }
          </Grid>
        </Grid>
        <Grid item md={12} lg={3}>
          <h3 className='mb-2'>{i18n.t('Dashboard:Status')}</h3>
          <Grid container spacing={1}>
            { getWidgetsRender(DashboardWidgetType.KPIS, STATUS_KPIS) }
            { getWidgetsRender(DashboardWidgetType.CHARTS, STATUS_CHARTS) }
          </Grid>
        </Grid>
        <Grid item md={12} lg={5}>
          <Grid container spacing={1}>
            {
              DAYS_TITLES.map(title => <Grid key={`title-${title}`} item width={WrapperWidth.ONE_THIRD} flexGrow={1}>
                <h3 className='mb-2'>{i18n.t(`Dashboard:${title}`)}</h3>
              </Grid>)
            }
          </Grid>
          <Grid container spacing={1}>
            { getWidgetsRender(DashboardWidgetType.KPIS, DAYS_RANGE_KPIS) }
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
