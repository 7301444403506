import React, { MouseEvent, useCallback, useState } from 'react'

import CheckMark from '../../assets/icons/checkMark.svg'
import Spinner from '../../assets/icons/spinner'

import styles from './Button.module.scss'


type ButtonProps = {
  handler: () => void
  variant?: 'primary'
          | 'primaryWhite'
          | 'secondary'
          | 'secondaryWhite'
          | 'neutral'
          | 'neutralWhite'
          | 'success'
          | 'successWhite'
          | 'danger'
          | 'dangerWhite'
          | 'alert'
          | 'alertWhite'
          | 'warning'
          | 'warningWhite'
          | 'dropdown'
          | 'noContainer'
          | 'link'
  title?: string
  tooltipText?: string
  titleSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  textLeft?: boolean
  icon?: string
  disabled?: boolean
  loading?: boolean
  fullWidth?: boolean
  fullHeight?: boolean
  circle?: boolean
  pill?: boolean
  borderRadiusSize?: 'small' | 'medium' | 'big'
  noShadow?: boolean
  noBorderRadius?: boolean
  noBorderRadiusTop?: boolean
  noBorderRadiusLeft?: boolean
  noBorderRadiusRight?: boolean
  noBorderLeft?: boolean
  noBorderRight?: boolean
  checkbox?: boolean
  checked?: boolean
  small?: boolean
  buttonType?: 'button' | 'submit' | 'reset'
}


export const Button = ({
  handler,
  variant = 'primary',
  title,
  tooltipText,
  titleSize,
  textLeft,
  icon,
  disabled,
  loading,
  fullWidth,
  fullHeight,
  circle,
  pill,
  borderRadiusSize = 'medium',
  noShadow,
  noBorderRadius,
  noBorderRadiusTop,
  noBorderRadiusLeft,
  noBorderRadiusRight,
  noBorderLeft,
  noBorderRight,
  checkbox,
  checked,
  small,
  buttonType = 'button'
}: ButtonProps) => {
  const [isHover, setIsHover] = useState(false)

  const buttonStyles: { [key: string]: string } = {
    primary: styles.primary,
    primaryWhite: styles.primaryWhite,
    secondary: styles.secondary,
    secondaryWhite: styles.secondaryWhite,
    neutral: styles.neutral,
    neutralWhite: styles.neutralWhite,
    success: styles.success,
    successWhite: styles.successWhite,
    danger: styles.danger,
    dangerWhite: styles.dangerWhite,
    alert: styles.alert,
    alertWhite: styles.alertWhite,
    warning: styles.warning,
    warningWhite: styles.warningWhite,
    dropdown: styles.dropdown,
    noContainer: styles.noContainer,
    link: styles.link
  }

  const titleStyles: { [key: string]: string } = {
    h1: styles.h1,
    h2: styles.h2,
    h3: styles.h3,
    h4: styles.h4,
    h5: styles.h5,
    h6: styles.h6,
  }

  const borderRadiusSizeStyles: { [key: string]: string } = {
    small: styles.borderRadiusSmall,
    medium: styles.borderRadiusMedium,
    big: styles.borderRadiusBig
  }


  const handlerAux = useCallback((e: MouseEvent) => {
    handler()
    e.stopPropagation()
  }, [handler])


  return (
    <div
      className={`
        ${styles.buttonElement}
        ${fullHeight && styles.fullHeight}
        ${fullWidth && styles.fullWidth}
      `}
    >
      <button
        onMouseEnter={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
        onClick={handlerAux}
        disabled={disabled}
        type={buttonType}
        className={`
          ${styles.button}
          ${buttonStyles[variant]}
          ${textLeft && styles.textLeft}
          ${disabled && styles.disabled}
          ${loading && styles.loading}
          ${circle && styles.circle}
          ${pill && styles.pill}
          ${borderRadiusSizeStyles[borderRadiusSize]}
          ${noShadow && styles.noShadow}
          ${noBorderRadius && styles.noBorderRadius}
          ${noBorderRadiusTop && styles.noBorderRadiusTop}
          ${noBorderRadiusLeft && styles.noBorderRadiusLeft}
          ${noBorderRadiusRight && styles.noBorderRadiusRight}
          ${noBorderLeft && styles.noBorderLeft}
          ${noBorderRight && styles.noBorderRight}
          ${small && styles.small}
        `}
      >
        {loading ? (
          <Spinner className={styles.spinner} />
        ) : (
          <div className={styles.children}>
            {checkbox && (
              <div className={`${styles.checkbox} ${checked && styles.checked} ${disabled && styles.disabled}`}>
                <img
                  src={CheckMark}
                  alt='V'
                  className={`${styles.checkMark} ${checked && styles.checked}`}
                  draggable={false}
                />
              </div>
            )}
            {icon && (
              <img
                src={icon}
                alt='buttonIcon'
                className={styles.icon}
                draggable={false}
              />
            )}
            {title &&
              <p className={`${styles.title} ${titleSize && titleStyles[titleSize]}`}>
                {title}
              </p>
            }
          </div>
        )}
        {variant.slice(-5) !== 'White' && (
          <div className={styles.focusVisibleIndicator} />
        )}
      </button>
      {(tooltipText && isHover) && (
        <div className={styles.tooltip}>
          <h5>
            {tooltipText}
          </h5>
        </div>
      )}
    </div>
  )
}
