import React, { useCallback } from 'react'

import arrowLeft from '../../assets/icons/arrowLeft.svg'
import arrowRight from '../../assets/icons/arrowRight.svg'
import useSearchParams from '../../utils/useSearchParams'
import { Button } from '../Button'

import styles from './PageIndicator.module.scss'


type PageIndicatorProps = {
  currentPage: number
  lastPage: number
  handler: (page: number) => void
  isSearchParam?: false
  loading?: boolean
} | {
  // If it's a search param, the handler doesn't exist
  currentPage: number
  lastPage: number
  handler?: undefined
  isSearchParam: true
  loading?: boolean
}


export const PageIndicator = ({
  currentPage,
  lastPage,
  handler,
  isSearchParam,
  loading
}: PageIndicatorProps) => {
  const { updateSearchParams } = useSearchParams()

  const handlePageChange = useCallback((page: number) => {
    if (handler) handler(page)
    else if (isSearchParam) updateSearchParams({ page: page.toString() })
  }, [handler, isSearchParam, updateSearchParams])


  return (
    <div className={styles.pageIndicator}>
      <Button
        variant='primaryWhite'
        icon={arrowLeft}
        handler={() => handlePageChange(currentPage - 1)}
        disabled={loading || currentPage === 1}
        circle
      />
      {currentPage <= 2 ? (
        <div className={styles.bigSpacer} />
      ) : (
        <>
          <Button
            variant='primaryWhite'
            title='1'
            handler={() => handlePageChange(1)}
            circle
            disabled={loading}
          />
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <span className={`${styles.dots} ${loading && styles.loading}`}>
            . . .
          </span>
        </>
      )}
      {currentPage === 1 ? (
        <div className={styles.buttonSpacer} />
      ) : (
        <Button
          variant='primaryWhite'
          title={`${currentPage - 1}`}
          handler={() => handlePageChange(currentPage - 1)}
          circle
          disabled={loading}
        />
      )}
      <Button
        title={`${currentPage}`}
        handler={() => handlePageChange(currentPage)}
        circle
        disabled={loading}
      />
      {currentPage === lastPage ? (
        <div className={styles.buttonSpacer} />
      ) : (
        <Button
          variant='primaryWhite'
          title={`${currentPage + 1}`}
          handler={() => handlePageChange(currentPage + 1)}
          circle
          disabled={loading}
        />
      )}
      {currentPage >= lastPage - 1 ? (
        <div className={styles.bigSpacer} />
      ) : (
        <>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          <span className={`${styles.dots} ${loading && styles.loading}`}>
            . . .
          </span>
          <Button
            variant='primaryWhite'
            title={`${lastPage}`}
            handler={() => handlePageChange(lastPage)}
            circle
            disabled={loading}
          />
        </>
      )}
      <Button
        variant='primaryWhite'
        icon={arrowRight}
        handler={() => handlePageChange(currentPage + 1)}
        disabled={loading || currentPage === lastPage}
        circle
      />
    </div>
  )
}
