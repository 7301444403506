import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'

import ArrowDown from '../../assets/icons/arrowDown.svg'
import ArrowUp from '../../assets/icons/arrowUp.svg'
import useSearchParams from '../../utils/useSearchParams'
import { Button } from '../Button'
import { Scroll } from '../Scroll'
import { TextInput } from '../TextInput'

import styles from './MultiDropdown.module.scss'


type MultiDropdownProps<T> = {
  options: T[]
  selected: T[]
  getOptionLabel: (item: T) => string
  getOptionId: (item: T) => string
  filterBy?: (item: T) => string
  title?: string
  disabled?: boolean
  height?: string
  pill?: boolean
  customWidth?: string
} & ({ // Conditional props below
  onChange: (v: T[]) => void
  searchParamName?: undefined
} | {
  // If it's a search param, the onChange doesn't exist
  onChange?: undefined
  searchParamName: string
})


export const MultiDropdown = <T, >({
  options,
  selected,
  onChange,
  getOptionLabel,
  getOptionId,
  filterBy,
  title,
  disabled,
  height,
  pill,
  searchParamName,
  customWidth
}: MultiDropdownProps<T>) => {
  const i18n = useTranslation()
  const { updateSearchParams } = useSearchParams()

  const [filter, setFilter] = useState('')
  const [isDroped, setIsDroped] = useState(false)
  const [isHoverTopContainer, setIsHoverTopContainer] = useState(false)
  const [isHoverOptions, setIsHoverOptions] = useState(false)

  const containerRef = useRef(null)
  const droppedListRef = useRef(null)

  const selectedIds = useMemo(() => selected.map(getOptionId), [selected, getOptionId])


  // Filter the options if filterBy is present
  const filteredOptions = useMemo(() => {
    if (!filterBy) return options

    return options.filter(option => filterBy(option).toLowerCase().includes(filter.toLowerCase()))
  }, [options, filter, filterBy])


  // Returns the label for the dropdown with info of the selected options
  const dropdownLabel = useMemo(() => {
    const countSelected = selected.length
    if (countSelected === 0) return ''
    if (countSelected === 1) return getOptionLabel(selected[0])
    return `${getOptionLabel(selected[0])}, ...(${countSelected})`
  }, [selected, getOptionLabel])


  // Handles the click outside the dropdown logic
  useEffect(() => {
    if (isHoverTopContainer) return () => null

    const handleOutsideClick = () => {
      if (!isHoverOptions) setIsDroped(false)
    }

    window.addEventListener('click', handleOutsideClick)
    return () => window.removeEventListener('click', handleOutsideClick)
  }, [isHoverOptions, isHoverTopContainer])


  // Clear filter when dropdown is closed
  useEffect(() => {
    isDroped ? null : setFilter('')
  }, [isDroped])


  // Handle adding or removing an option from the selected list
  const updateSelectedList = useCallback((opt: T) => {
    const optId = getOptionId(opt)
    const newSelected = selectedIds.includes(optId)
      ? selected.filter(s => getOptionId(s) !== optId)
      : [...selected, opt]

    if (onChange) {
      onChange(newSelected)
    } else if (searchParamName) {
      const newSelectedIds = newSelected.map(getOptionId)
      updateSearchParams({ [searchParamName]: newSelectedIds })
    }
  }, [selected, selectedIds, onChange, getOptionId, searchParamName, updateSearchParams])


  return (
    <div
      className={`${styles.dropdown} ${disabled && styles.disabled}`}
      style={{ width: customWidth }}
    >
      <button
        className={`${styles.topContainer} ${disabled && styles.disabled} ${pill && styles.pill}`}
        onClick={() => setIsDroped(!isDroped)}
        ref={containerRef}
        onMouseEnter={() => setIsHoverTopContainer(true)}
        onMouseLeave={() => setIsHoverTopContainer(false)}
      >
        <div className={styles.info}>
          {title && (
            <h4 className={styles.title}>
              {title}
            </h4>
          )}
          <span>
            {dropdownLabel}
          </span>
        </div>
        <div className={styles.imgContainer}>
          <img
            src={isDroped ? ArrowUp : ArrowDown}
            alt='DropdownArrow'
            draggable={false}
          />
        </div>
      </button>
      {isDroped && (
        <div className={styles.droppedContainer}>
          <div className={styles.droppedTopBackground} />
          <div
            className={styles.dropedList}
            ref={droppedListRef}
            onMouseEnter={() => setIsHoverOptions(true)}
            onMouseLeave={() => setIsHoverOptions(false)}
          >
            {filterBy && (
              <TextInput
                value={filter}
                onChange={v => setFilter(v)}
                placeholder={i18n.t('Common:SearchHere')}
                noBorders
              />
            )}
            <Scroll<T>
              options={filteredOptions}
              height={height}
              variant='dropdown'
              getOptionKey={option => getOptionId(option)}
              getOptionComponent={opt => (
                <Button
                  title={getOptionLabel(opt)}
                  handler={() => updateSelectedList(opt)}
                  fullWidth
                  textLeft
                  checkbox
                  checked={selectedIds.includes(getOptionId(opt))}
                  variant='dropdown'
                />
              )}
            />
          </div>
        </div>
      )}
    </div>
  )
}
