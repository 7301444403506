import { useCallback } from 'react'
import { useSearchParams as useSearchParamsRRD } from 'react-router-dom'


export type searchParamsType = {
  [key: string]: string | string[]
}


function useSearchParams() {
  const [searchParams, setSearchParams] = useSearchParamsRRD()


  const getSearchParams = useCallback(() => {
    return new URLSearchParams(searchParams.toString())
  }, [searchParams])


  const updateSearchParams = useCallback((params: searchParamsType) => {
    const newSearchParams = getSearchParams()
    Object.keys(params).forEach(key => {
      const value = params[key]
      if (Array.isArray(value)) {
        newSearchParams.delete(key)
        value.forEach(v => newSearchParams.append(key, v))
      } else {
        newSearchParams.set(key, value)
      }
    })
    setSearchParams(newSearchParams)
  }, [getSearchParams, setSearchParams])


  const removeSearchParams = useCallback((keys: string[] | string) => {
    const newSearchParams = getSearchParams()
    if (typeof keys === 'string') keys = [keys]
    keys.forEach(key => newSearchParams.delete(key))
    setSearchParams(newSearchParams)
  }, [getSearchParams, setSearchParams])


  return {
    // So that the user can access the original searchParams from the same place
    searchParams,
    setSearchParams,
    // Custom functions
    getSearchParams,
    updateSearchParams,
    removeSearchParams
  }
}


export default useSearchParams
