import { camelize, decamelize } from 'humps'

const isUndefined = value => value === undefined

const isNull = value => value === null

const isBoolean = value => typeof value === 'boolean'

const isObject = value => value === Object(value)

const isArray = value => Array.isArray(value)

const isDate = value => value instanceof Date

const isBlob = (value, isReactNative) => isStandardBlob(value) || (isReactNative && isReactNativeBlob(value))

const isStandardBlob = typeof Blob !== 'undefined'
  ? value => value instanceof Blob
  : value => value
        && typeof value.size === 'number'
        && typeof value.type === 'string'
        && typeof value.slice === 'function'

// Function used to camelize object keys from the server's JSON responses
export function camelizeReviver(key, val) {
  if (key) {
    const camelizedKey = camelize(key)
    if (key !== camelizedKey) {
      this[camelizedKey] = val
      return undefined
    }
  }
  return val
}

// Function used to turn object keys from camelCase to snake_case before sending to server
export function decamelizeDeep(obj) {
  if (!obj || typeof obj !== 'object') return obj
  const objType = Object.prototype.toString.bind(obj)()
  if (objType === '[object Date]') return obj
  if (objType === '[object Blob]') return obj
  if (objType === '[object RegExp]') return obj
  if (objType === '[object File]') return obj
  if (objType === '[object Array]') return obj.map(decamelizeDeep)
  return Object.keys(obj).reduce((acc, key) => {
    acc[decamelize(key)] = decamelizeDeep(obj[key])
    return acc
  }, {})
}

function isFormData(value) {
  return value instanceof FormData
}

export const serialize = (obj, cfg, fd, pre) => {
  if (isFormData(cfg)) {
    pre = fd
    fd = cfg
    cfg = null
  }

  cfg = cfg || {}

  cfg.indices = isUndefined(cfg.indices) ? false : cfg.indices

  cfg.nullsAsUndefineds = isUndefined(cfg.nullsAsUndefineds)
    ? false
    : cfg.nullsAsUndefineds

  cfg.booleansAsIntegers = isUndefined(cfg.booleansAsIntegers)
    ? false
    : cfg.booleansAsIntegers

  cfg.allowEmptyArrays = isUndefined(cfg.allowEmptyArrays)
    ? false
    : cfg.allowEmptyArrays

  fd = fd || new FormData()

  // ReactNative `FormData` has a non-standard `getParts()` method
  const isReactNative = typeof fd.getParts !== 'undefined'

  if (isUndefined(obj)) {
    return fd
  } if (isNull(obj)) {
    if (!cfg.nullsAsUndefineds) {
      fd.append(pre, '')
    }
  } else if (isBoolean(obj)) {
    if (cfg.booleansAsIntegers) {
      fd.append(pre, obj ? 1 : 0)
    } else {
      fd.append(pre, obj)
    }
  } else if (isArray(obj)) {
    if (obj.length) {
      obj.forEach((value, index) => {
        const key = `${pre}[${cfg.indices ? index : ''}]`

        serialize(value, cfg, fd, key)
      })
    } else if (cfg.allowEmptyArrays) {
      fd.append(`${pre}[]`, '')
    }
  } else if (isDate(obj)) {
    fd.append(pre, obj.toISOString())
  } else if (isObject(obj) && !isBlob(obj, isReactNative)) {
    Object.keys(obj).forEach(prop => {
      const value = obj[prop]

      if (isArray(value)) {
        while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2) {
          prop = prop.substring(0, prop.length - 2)
        }
      }

      const key = pre ? `${pre}[${prop}]` : prop

      serialize(value, cfg, fd, key)
    })
  } else {
    fd.append(pre, obj)
  }

  return fd
}
