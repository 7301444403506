// icon:125-spinner3 | Icomoon https://icomoon.io/ | Keyamoon
import * as React from 'react'


const Icon125Spinner3 = ({
  width = '32',
  height = '32',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      viewBox='0 0 16 16'
      fill='currentColor'
      width={width}
      height={height}
      {...props}
    >
      <path
        fill='currentColor'
        d='M8 4.736a.933.933 0 01-.933-.933V1.005a.933.933 0 011.866 0v2.798A.933.933 0 018 4.736zM8 15.577a.583.583 0 01-.583-.583v-2.798a.583.583 0 111.166 0v2.798a.583.583 0 01-.583.583zM5.902 5.24a.875.875 0 01-.758-.437L3.745 2.38a.874.874 0 011.514-.874l1.399 2.423a.874.874 0 01-.756 1.311zM11.498 14.582a.525.525 0 01-.455-.262l-1.399-2.423a.525.525 0 11.909-.525l1.399 2.423a.525.525 0 01-.454.787zM4.365 6.718a.813.813 0 01-.407-.109L1.535 5.21a.817.817 0 01.816-1.414l2.423 1.399a.817.817 0 01-.408 1.523zM14.057 11.964a.461.461 0 01-.233-.063l-2.423-1.399a.467.467 0 01.466-.808l2.423 1.399a.467.467 0 01-.233.87zM3.803 8.758H1.005a.758.758 0 010-1.516h2.798a.758.758 0 110 1.516zM14.995 8.466h-2.798a.466.466 0 110-.932h2.798a.466.466 0 110 .932zM1.943 12.197a.698.698 0 01-.35-1.305l2.423-1.399a.698.698 0 11.699 1.211l-2.423 1.399a.69.69 0 01-.349.094zM11.635 6.368a.466.466 0 01-.233-.87l2.423-1.399a.466.466 0 11.466.808l-2.423 1.399a.469.469 0 01-.233.063zM4.502 14.699a.64.64 0 01-.555-.962l1.399-2.423a.641.641 0 111.111.641l-1.399 2.423a.64.64 0 01-.556.321zM10.098 4.832a.467.467 0 01-.404-.7l1.399-2.423a.467.467 0 01.808.466l-1.399 2.423a.464.464 0 01-.404.233z'
      />
    </svg>
  )
}

export default Icon125Spinner3;
