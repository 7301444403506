import React from 'react'
import { useTranslation } from 'react-i18next'

import Spinner from '../../../assets/icons/spinner'

import styles from './Loading.module.scss'


type LoadingProps = {
  size?: string
  marginTop?: string
  marginBottom?: string
}


export const Loading = ({
  size = '32px',
  marginTop = '2rem',
  marginBottom = '0'
}: LoadingProps) => {
  const i18n = useTranslation()


  return (
    <div
      className={styles.loading}
      style={{ marginTop: marginTop, marginBottom: marginBottom }}
    >
      <Spinner
        width={size}
        height={size}
        className={styles.spinner}
      />
      <h1>
        {i18n.t('Common:Loading')}
      </h1>
    </div>
  )
}
