import dayjs, { Dayjs } from 'dayjs'

import i18n from '../localization'


//#region Enums

export enum DateUnitType {
  SECOND = 'second',
  MINUTE = 'minute',
  HOUR = 'hour',
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}

//#endregion Enums

export function humanTimeDiference(
  date1: string | undefined,
  date2: string | undefined
): string {
  if (!date1 || !date2) return ''

  const diff = dayjs(date2).diff(dayjs(date1), 'day', true)
  const days = Math.floor(diff);
  const hours = Math.floor((diff - days) * 24);
  if (days === 0) return `${hours} ${i18n.t('Common:Hours')}`
  if (hours === 0) return `${days} ${i18n.t('Common:Days')}`
  return `${days} ${i18n.t('Common:Days')} ${i18n.t('Common:And')} ${hours} ${i18n.t('Common:Hours')}`
}


export const dateDifference = (date1: Date, date2: Date, unit: DateUnitType) => Math.floor(dayjs(date2).diff(dayjs(date1), unit, true))

export const isDate = (value?: string | null | Dayjs | Date) => value ? dayjs(value).isValid() : false

export const parseDayJs = (value?: string | null | Dayjs | Date) => isDate(value) ? dayjs(value) : null

export const addDateTime = (date: Date | string | Dayjs, increment: number, unit: DateUnitType) => dayjs(date).add(increment, unit).toDate()

export const printDate = (date: Date | string | Dayjs, format?: string) => dayjs(date).format(format || 'DD/MM/YYYY - HH[h]mm')
