import React, { useState } from 'react'

import styles from './AbsoluteButton.module.scss'


type AbsoluteButtonProps = {
  icon: string
  handler: (() => void)
}


export const AbsoluteButton = ({
  icon,
  handler
}: AbsoluteButtonProps) => {
  const [hover, setHover] = useState(false)
  const [focus, setFocus] = useState(false)

  return (
    <div
      className={`${styles.absoluteButton} ${hover && styles.hover} ${focus && styles.focus}`}
      onClick={handler}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => { setHover(false); setFocus(false) }}
      onMouseDown={() => setFocus(true)}
      onMouseUp={() => setFocus(false)}
    >
      <img
        src={icon}
        alt='AbsoluteButtonIcon'
        className={styles.img}
        draggable={false}
      />
    </div>
  )
}
