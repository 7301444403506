import React, { forwardRef, useEffect, useImperativeHandle} from 'react'
import { useTranslation } from 'react-i18next'
import { useOutletContext } from 'react-router-dom'

import { MissionStatusType, MissionUpdateTypeAPI } from '../../../api/types'
import { MissionDetails } from '../../../components/MissionDetails'
import { WizardContextType } from '../Wizard'


export const Step4 = forwardRef(() => {
  const i18n = useTranslation()

  const { mission, setStep, stepRef } = useOutletContext<WizardContextType>()


  useEffect(() => {
    setStep(4)
  }, [setStep])


  useImperativeHandle(stepRef, () => ({
    updateData(newStatus: MissionStatusType): MissionUpdateTypeAPI {
      return({mission: {
        status: newStatus
      }})
    },

    getWarnings(): string[] {
      return []
    }
  }))


  return (
    <div>
      <h2>
        {i18n.t('MissionWizard:MissionSummary')}
      </h2>
      <MissionDetails
        mission={mission}
        storesToGet='temporaryPlaceOrStores'
      />
    </div>
  )
})
