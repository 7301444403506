import dayjs from 'dayjs'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { acceptAnswer, denyAnswer } from '../../api'
import { MissionStoreAnswersTypeAPI, QuestionTypeAPI, StoreAnswersHistoryTypeAPI } from '../../api/types'
import ArrowDown from '../../assets/icons/arrowDown.svg'
import ArrowUp from '../../assets/icons/arrowUp.svg'
import CheckMark from '../../assets/icons/checkMark.svg'
import Circle from '../../assets/icons/circle.svg'
import Cross from '../../assets/icons/cross.svg'
import ExclamationMark from '../../assets/icons/exclamationMark.svg'
import Photo from '../../assets/icons/photo.svg'
import { AnswersConsts } from '../../Constants'
import { Button } from '../../elements/Button'
import { ContinuousPill } from '../../elements/ContinuousPill'
import { CopyToClipboard } from '../../elements/CopyToClipboard'
import { ImagesWithPreview } from '../../elements/ImagesWithPreview'
import { Pill } from '../../elements/Pill'
import { ListCard } from '../ListCard'

import styles from './StoreAnswersHistoryCard.module.scss'


const { CANT_DO_ERRORS, COMPLETED_ERRORS } = AnswersConsts

type StoreAnswersHistoryCardProps = {
  storeAnswersHistory: StoreAnswersHistoryTypeAPI
  questions: QuestionTypeAPI[]
}


export const StoreAnswersHistoryCard = ({
  storeAnswersHistory,
  questions
}: StoreAnswersHistoryCardProps) => {
  const i18n = useTranslation()

  const [loading, setLoading] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [missionStore, setMissionStore] = useState(storeAnswersHistory[0])
  const [disabledChangeCardState, setDisabledChangeCardState] = useState(false)


  const getAnswer = useCallback((question: QuestionTypeAPI) => {
    return missionStore.answers.find(a => a.questionId === question.id)
  }, [missionStore])


  const hasPhotos = useCallback((question: QuestionTypeAPI) => {
    const ans = getAnswer(question)
    if (!ans) return false
    if (!ans.photos) return false
    return ans.photos.length > 0
  }, [getAnswer])


  const handleAccept = useCallback(() => {
    setLoading(true)
    acceptAnswer(missionStore.missionStoreId).then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:ErrorAcceptingAnswers'))
      } else {
        toast.success(i18n.t('ToastError:AnswerAccepted'))
        // eslint-disable-next-line i18next/no-literal-string
        missionStore.confirmationStatus = 'accepted'
      }
      setLoading(false)
    })
  }, [i18n, missionStore])


  const handleDeny = useCallback(() => {
    setLoading(true)
    denyAnswer(missionStore.missionStoreId).then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:ErrorDenyingAnswers'))
      } else {
        toast.success(i18n.t('ToastError:AnswerDenied'))
        // eslint-disable-next-line i18next/no-literal-string
        missionStore.confirmationStatus = 'denied'
      }
      setLoading(false)
    })
  }, [i18n, missionStore])


  return (
    <ListCard
      handler={() => setIsOpen(!isOpen)}
      box
      disabled={disabledChangeCardState}
    >
      <div className={styles.answerCard}>
        <div className={styles.header}>
          <h3>
            {storeAnswersHistory[0].storeName}
          </h3>
          <img
            className={styles.arrow}
            src={isOpen ? ArrowUp : ArrowDown}
            alt='photo_icon'
            draggable={false}
          />
        </div>
        <div className={styles.subHeader}>
          <div className={styles.subHeaderPartial}>
            <ContinuousPill<MissionStoreAnswersTypeAPI>
              options={storeAnswersHistory}
              getOptionId={ms => ms.missionStoreId.toString()}
              getOptionText={ms => dayjs(ms.answeredAt).format('DD/MM/YYYY')}
              onChange={setMissionStore}
              selected={missionStore}
              fullHeight
            />
            <Pill
              fullHeight
              title={missionStore.confirmationStatus === 'accepted'
                     ? i18n.t('Answers:AcceptedSingular')
                     : missionStore.confirmationStatus === 'denied'
                       ? i18n.t('Answers:DeniedSingular')
                       : i18n.t('Answers:ToValidate')
                    }
              icon={missionStore.confirmationStatus === 'accepted'
                    ? CheckMark
                    : missionStore.confirmationStatus === 'denied'
                      ? Cross
                      : Circle
                   }
              variant={missionStore.confirmationStatus === 'accepted'
                       ? 'success'
                       : missionStore.confirmationStatus === 'denied'
                         ? 'danger'
                         : 'warning'
                      }
            />
            {missionStore.cantDoErrors.length > 0 && (
              <Pill
                title={i18n.t('Answers:CantDoMission')}
                icon={ExclamationMark}
                variant='dangerWhite'
                fullHeight
              />
            )}
          </div>
          <div className={styles.subHeaderPartial}>
            {missionStore.completedErrors.map(error => (
              <Pill
                key={`completedError_${error}`}
                title={COMPLETED_ERRORS[error].label}
                variant='neutralWhite'
                fullHeight
              />
            ))}
          </div>
        </div>
        {isOpen && (
          <div className={styles.dates}>
            <ContinuousPill<string>
              variant='neutralWhite'
              getOptionText={date => date}
              fullHeight
              noDivider
              options={[
                `${i18n.t('Answers:CreatedAt')}: ${dayjs(missionStore.createdAt).format('DD/MM/YYYY')}`,
                `${i18n.t('Answers:AnsweredAt')}: ${dayjs(missionStore.answeredAt).format('DD/MM/YYYY')}`,
                `${i18n.t('Answers:ValidatedAt')}: ${missionStore.confirmedAt ? dayjs(missionStore.confirmedAt).format('DD/MM/YYYY') : i18n.t('Mission:ToBeValidated')}`
              ]}
            />
          </div>
        )}
        {missionStore.cantDoErrors.length === 0 ? (
          <div className={`${styles.questions} ${isOpen && styles.open}`}>
            {questions.map(q => {
              const answer = getAnswer(q)
              return (
                <div
                  key={`question_${q.id}_AnswerCard`}
                  className={styles.question}
                  onMouseEnter={() => setDisabledChangeCardState(true)}
                  onMouseLeave={() => setDisabledChangeCardState(false)}
                >
                  <div className={styles.left}>
                    <p>
                      {`${q.order+1}. ${q.text}`}
                    </p>
                    {Array.isArray(answer?.response) ? (
                      <div className={styles.answerContainer}>
                        <CopyToClipboard
                          textToCopy={answer?.response.join('\n') || ''}
                          tooltipText={i18n.t('Answer:CopyAnswerToClipboard')}
                        />
                        <div className={styles.answers}>
                          {answer?.response.map((r, i) => (
                            <h5 key={`response_${i}_Question_${q.id}_AnswerCard`}>
                              {r}
                            </h5>
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div className={styles.answerContainer}>
                        <CopyToClipboard
                          textToCopy={answer?.response || ''}
                          tooltipText={i18n.t('Answer:CopyAnswerToClipboard')}
                        />
                        <div className={styles.answers}>
                          <h5>
                            {answer?.response}
                          </h5>
                        </div>
                      </div>
                    )}
                  </div>
                  {hasPhotos(q) && (
                    isOpen ? (
                      <div className={styles.photosContainer}>
                        <ImagesWithPreview
                          images={answer?.photos || []}
                          withLabel
                        />
                      </div>
                    ) : (
                      <img
                        className={styles.photoIcon}
                        src={Photo}
                        alt='photo_icon'
                        draggable={false}
                      />
                    )
                  )}
                </div>
              )
            })}
          </div>
        ) : (
          <div className={styles.cantDoContainer}>
            <div className={styles.cantDoErrors}>
              {missionStore.cantDoErrors.map(error => ( 
                <Pill
                  key={`cantDoError_${error}_MissionStore_${missionStore.missionStoreId}`}
                  variant='dangerWhite'
                  fullHeight
                  title={
                    <div className={styles.errorPillText}>
                      <b>
                        {CANT_DO_ERRORS[error].label}
                      </b>
                      {CANT_DO_ERRORS[error].text_prop && (
                        <p>
                          {`: ${missionStore[CANT_DO_ERRORS[error].text_prop as keyof MissionStoreAnswersTypeAPI]}`}
                        </p>
                      )}
                    </div>
                  }
                />
              ))}
            </div>
            {isOpen ? (
              <div className={styles.photosContainer}>
                <ImagesWithPreview
                  images={missionStore?.cantDoPhotos || []}
                  withLabel
                />
              </div>
            ) : (
              <img
                className={styles.photoIcon}
                src={Photo}
                alt='photo_icon'
                draggable={false}
              />
            )}
          </div>
        )}
        {/* Confirmation status is null if missionStore is yet to be validated */}
        {!missionStore.confirmationStatus && (
          <div className={styles.validationButtons}>
            <Button
              title={i18n.t('Common:Deny')}
              handler={handleDeny}
              loading={loading}
              variant='neutral'
            />
            <Button
              title={i18n.t('Common:Accept')}
              handler={handleAccept}
              loading={loading}
            />
          </div>
        )}
      </div>
    </ListCard>
  )
}
