import { Box, Grid } from "@mui/material"

import { DashboardChartType } from "../../../components/DashboardChart/DashboardChart"
import { DashboardFiltersType, getFilterTags } from "../../../components/DashboardFilters/DashboardFilters"
import { KpiCardType } from "../../../components/KpiCard/KpiCard"
import ResumoMesesModel from "../../../models/ResumoMesesModel"
import { DashboardWidgetType, getWidgetsRender, WidgetsStructure } from "../../../utils/dashboard"
import { WrapperHeight, WrapperWidth } from "../../../utils/wrapper"

//#region Enums

enum TableTypes {
  MONTHLY_TABLE = 'MonthlyTable'
}

//#endregion Enums

//#region Constants

const FILTERS: string[] = getFilterTags([DashboardFiltersType.SELLERS, DashboardFiltersType.STORE, DashboardFiltersType.PRODUCT, DashboardFiltersType.SUB_BANNER]),
  KPIS: WidgetsStructure = {
    [KpiCardType.TOTAL_SELLOUT_YEAR]: {
      filters: FILTERS
    },
    [KpiCardType.TOTAL_STOCK_YEAR]: {
      filters: FILTERS
    },
    [KpiCardType.TOTAL_OUTAGES_YEAR]: {
      filters: FILTERS
    },
    [KpiCardType.TOTAL_DELIVERIES]: {
      filters: FILTERS
    },
    [KpiCardType.UNITS_DELIVERED]: {
      filters: FILTERS
    },
    [KpiCardType.UNITS_LINEAR]: {
      filters: FILTERS
    }
  },
  CHARTS: WidgetsStructure = {
    [DashboardChartType.MONTHLY_SELLOUT]: {
      filters: FILTERS,
      height: WrapperHeight.MEDIUM,
      width: WrapperWidth.FULL
    },
    [DashboardChartType.MONTHLY_STOCK]: {
      filters: FILTERS,
      height: WrapperHeight.MEDIUM,
      width: WrapperWidth.FULL
    },
    [DashboardChartType.MONTHLY_OUTAGES]: {
      filters: FILTERS,
      height: WrapperHeight.MEDIUM,
      width: WrapperWidth.FULL
    },
    [DashboardChartType.MONTHLY_LOST_VALUE]: {
      filters: FILTERS,
      height: WrapperHeight.MEDIUM,
      width: WrapperWidth.FULL
    }
  },
  TABLES: WidgetsStructure = {
    [TableTypes.MONTHLY_TABLE]: {
      model: ResumoMesesModel,
      filters: FILTERS,
      width: WrapperWidth.FULL
    }
  }

//#endregion Constants

export const MonthlyFluctuations = () => {
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            { getWidgetsRender(DashboardWidgetType.KPIS, KPIS) }
            { getWidgetsRender(DashboardWidgetType.CHARTS, CHARTS) }
          </Grid>
        </Grid>
        { getWidgetsRender(DashboardWidgetType.TABLES, TABLES) }
      </Grid>
    </Box>
  )
}
