import {
  Navigate,
  Route,
  Routes
} from 'react-router-dom'

import { LookerDashboard } from '../pages/LookerDashboard'

import { getPaths } from './routes'


const LookerRouter = () => {
  return (
    <Routes>
      <Route
        path={'/mission_dashboard/:id1/page/:id2'}
        element={<LookerDashboard />}
      />
      <Route
        path={'*'}
        element={<Navigate to={getPaths.managers.signIn} replace />}
      />
    </Routes>
  )
}

export default LookerRouter
