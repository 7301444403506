import React from 'react'
import { useTranslation } from 'react-i18next'

import AppConsts from '../../../Constants'

import styles from './Footer.module.scss'


const { WEBSITE_URL } = AppConsts


export const Footer = () => {
  const i18n = useTranslation()
  const links = [
    { name: i18n.t('Footer:TermsAndConditions'), link: `${WEBSITE_URL}/termos-condicoes` },
    { name: i18n.t('Footer:PrivacyPolicy'), link: `${WEBSITE_URL}/politica-privacidade` },
  ]

  /* eslint-disable i18next/no-literal-string */
  const brandName = 'Brands&Ninjas'
  const rightsReservedMessage = `© ${new Date().getFullYear()} Brands&Ninjas Lda. ${i18n.t('Footer:AllRightsReserved')}`
  /* eslint-enable i18next/no-literal-string */


  return (
    <footer className={styles.footer}>
      <a href={WEBSITE_URL} className={styles.brandsAndNinjas}>
        {brandName}
      </a>
      <div className={styles.links}>
        {links.map(link => (
          <a
            key={`link_to_${link.name}`}
            href={link.link}
            className={styles.link}
          >
            {link.name}
          </a>
        ))}
        <p className={styles.rightsReserved}>
          {rightsReservedMessage}
        </p>
      </div>
    </footer>
  )
}
