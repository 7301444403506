import { Card, CardContent } from "@mui/material";
import { ECharts, getInstanceByDom, init } from "echarts";
import saveAs from "file-saver";
import { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';

import { ServerResponseType } from "../../api";
import { useGenericFilterContext } from "../../contexts/GenericFilterContext";
import i18n from "../../localization";
import { base64ToBlob } from "../../utils/file";
import { extractConditionalObject } from "../../utils/formatter";
import { isEmptyOrWhiteSpace, isNullOrUndefined, objectsAreEqual } from "../../utils/validator";
import { wrapContent } from "../../utils/wrapper";
import { ErrorAlert } from "../ErrorAlert/ErrorAlert";

import styles from './Chart.module.scss';
import { DefaultChartActionType, ChartConfig, ChartEventParams, ChartOptions, ChartRawData, ChartStackBarsType, ChartType, DEFAULT_LOADING_CONFIG, DEFAULT_LOADING_NAME, ChartToolboxActionType, ChartZoomAction, DefaultChartTakeGlobalCursorActionKeyType, DefaultChartEventType, ChartZoomEvent } from "./ChartDefinition";
import { ChartToolbox, ChartToolboxRef } from "./ChartToolbox";

//#region Constants

const EXPORT_FILE_EXTENSION = 'png',
      EXPORT_BACKGROUND_COLOR = '#FFF',
      DEFAULT_EXPORT_NAME = 'export';

//#endregion Constants

export const Chart = (config: ChartConfig) => {
  const {genericFilters, genericChartAction, updateInUseFilters, updateGenericChartAction} = useGenericFilterContext(false),
        [error, setError] = useState(false),
        [filters, setFilters] = useState({ ...extractConditionalObject(genericFilters, config.bindGenericFilters) }),
        [chart, setChart] = useState(undefined as ECharts | undefined),
        [data, setData] = useState(config.data as ChartRawData[] | undefined),
        [pending, setPending] = useState(false),
        [chartType, setChartType] = useState(config.type),
        [options, setOptions] = useState(new ChartOptions(config)),
        globalActionBind = useMemo(() => config.globalActionBind ?? config.toolbox === 'All' ?? (config.toolbox as ChartToolboxActionType[])?.includes(ChartToolboxActionType.ZOOM), [config.globalActionBind, config.toolbox]),
        toolboxRef = useRef(null as ChartToolboxRef|null), 
        chartRef = useRef(null as HTMLDivElement|null),
        internalId = useMemo(() => uuidv4().toString(), []);

  //#region Private Methods

  const bindEvents = useCallback(() => {
    if(chartType === ChartType.BAR && config.stackBarsType === ChartStackBarsType.FILLED) {
      chart?.on(DefaultChartEventType.LEGEND_SELECT_CHANGED, (params: unknown) => {
        const { name } = params as ChartEventParams;
        // Suppress legend selection
        chart.dispatchAction({
          type: DefaultChartActionType.LEGEND_SELECT,
          name: name,
          animation: { duration: 0, delay: 0}
        });
      });
    }

    chart?.on(DefaultChartEventType.DATA_ZOOM, (params: unknown) => {
      const config = (params as ChartZoomEvent)?.batch?.[0] ?? params as ChartZoomEvent,
            action = {
              type: DefaultChartActionType.DATA_ZOOM,
              start: config.start,
              end: config.end,
              startValue: config.startValue,
              endValue: config.endValue,
            } as ChartZoomAction;

      if(globalActionBind) {
        updateGenericChartAction({triggerId: internalId, ...action});
      }

      toolboxRef.current?.forwardActionTrigger(action, true);
    });
  }, [chart, chartType, globalActionBind, internalId, config, updateGenericChartAction]);

  const updateOptions = () => {
    if(!isNullOrUndefined(data)) {
      setOptions(new ChartOptions({...config, type: chartType, data: data}));
    }
  };

  const renderChart = () => {
    let chartInstance = getInstanceByDom(chartRef.current as HTMLElement);
    if(!chartInstance) {
      chartInstance = init(chartRef.current);
      setChart(chartInstance);
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    chartInstance.setOption(options as any, false);
  };

  const exportChart = () => {
    if(chart) {
      const base64: string | undefined = chart.getDataURL({
        type: EXPORT_FILE_EXTENSION,
        backgroundColor: EXPORT_BACKGROUND_COLOR
      });
  
      saveAs(base64ToBlob(base64), `${config.title ?? DEFAULT_EXPORT_NAME}.${EXPORT_FILE_EXTENSION}`);
    }
  };

  const toggleZoomChart = (toggle: boolean) => {
    chart?.dispatchAction({
      type: DefaultChartActionType.TAKE_GLOBAL_CURSOR,
      key: DefaultChartTakeGlobalCursorActionKeyType.DATA_ZOOM_SELECT,
      // Activate or inactivate.
      dataZoomSelectActive: toggle
    });
  };

  const resetZoomChart = () => {
    chart?.dispatchAction({
      type: DefaultChartActionType.DATA_ZOOM,
      start: 0,
      end: 100
    } as ChartZoomAction);
  };

  const dispatchAction = (action: ChartToolboxActionType, toggle?: boolean) => {
    switch(action) {
      case ChartToolboxActionType.EXPORT:
        exportChart();
        break;
      case ChartToolboxActionType.SCATTER_SHIFT:
        setChartType(ChartType.SCATTER);
        break;
      case ChartToolboxActionType.BAR_SHIFT:
        setChartType(ChartType.BAR);
        break;
      case ChartToolboxActionType.LINE_SHIFT:
        setChartType(ChartType.LINE);
        break;
      case ChartToolboxActionType.ZOOM:
        toggleZoomChart(!!toggle);
        break;
      case ChartToolboxActionType.RESET_ZOOM:
        resetZoomChart();
        break;
    }
  };

  //#region Remote

  const fetchRemote = () => {
    setError(false);
    setPending(!!config.fetchMethod);
    config.fetchMethod?.(filters)
      .then(({data, success}: ServerResponseType<ChartRawData[]>) => {
        if(!success) {
          setError(true);
        }

        if(isNullOrUndefined(data)) {
          setData(undefined);
        } else {
          const translateSeriesGroup = typeof config.translateSeries === 'string' ? `${config.translateSeries}:` : '';
          
          setData(data?.map((item: ChartRawData) => ({
            ...item,
            groupLabel: config.translateSeries ? i18n.t(`${translateSeriesGroup}Chart:${item.groupLabel}`) : item.groupLabel
          })));
        }
      })
      .finally(() => {
        setPending(false);
      });
  };

  //#endregion Remote

  //#endregion Private Methods

  //#region Effects

  useEffect(() => {
    setChartType(config.type);
  }, [config.type]);

  useEffect(() => {
    setData(config.data);
  }, [config.data]);

  useEffect(() => {
    if(chart && globalActionBind && !isEmptyOrWhiteSpace(genericChartAction) && genericChartAction.triggerId !== internalId) {
      chart.dispatchAction(genericChartAction, { silent: true });
      toolboxRef.current?.forwardActionTrigger(genericChartAction);
    }
  }, [internalId, chart, genericChartAction, globalActionBind])

  useEffect(renderChart, [options]);

  useEffect(updateOptions, [data, chartType, config]);

  useEffect(fetchRemote, [filters, config]);

  useEffect(() => {
    if(config.bindGenericFilters) {
      const newFilters = {
        ...filters,
        ...extractConditionalObject(genericFilters, config.bindGenericFilters)
      };

      if(!objectsAreEqual(filters, newFilters)) {
        setFilters(newFilters);
      }
    }
  }, [filters, genericFilters, config.bindGenericFilters])

  useEffect(() => {
    if(isNullOrUndefined(chart)) {
      return;
    } else if (pending) {
      chart?.showLoading(DEFAULT_LOADING_NAME, DEFAULT_LOADING_CONFIG);
      return;
    }

    chart?.hideLoading();
  }, [chart, pending]);

  useLayoutEffect(() => {
    const resize = () => { chart?.resize(); };

    window.addEventListener('resize', resize);
    return () => { window.removeEventListener('resize', resize)};
  });

  useEffect(() => {
    bindEvents();
    return () => {
      if(!(chart?.isDisposed() ?? true)) {
        chart?.dispose();
      }
    };
  }, [chart, bindEvents]);

  useEffect(() => {
    if(config.bindGenericFilters) {
      updateInUseFilters(config.bindGenericFilters);
    }
    // Disable eslint for updateInUseFilters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.bindGenericFilters]);

  //#endregion Effects

  return wrapContent(
    <Card className={`${styles.relative} h-100 w-100`}>
      <ErrorAlert show={error} onReload={fetchRemote}/>
      <CardContent className={`${styles.relative} h-100 w-100`}>
        <ChartToolbox
          ref={toolboxRef}
          chartInstance={chart}
          chartType={chartType}
          values={config.toolbox ?? false}
          axisType={config.axisType}
          xAxisValueType={config.xAxisValueType}
          onAction={dispatchAction}/>
        <div className={`w-100 h-100`} ref={chartRef}></div>
      </CardContent>
    </Card>
  , config.wrapper);
};
