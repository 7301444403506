import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import { Loading } from '../../components/shared/Loading'

import styles from './Scroll.module.scss'


type ScrollProps<T> = {
  options: T[]
  getOptionComponent: (item: T) => JSX.Element
  getOptionKey: (item: T) => string
  variant?: 'secondary' | 'dropdown'
  handleFetch?: () => void
  hasMoreData?: boolean
  height?: string
  finalText?: string
}


export const Scroll = <T, >({
  options,
  getOptionComponent,
  getOptionKey,
  variant = 'secondary',
  handleFetch = () => null,
  hasMoreData = false,
  height,
  finalText
 }: ScrollProps<T>) => (
  <div className={`${styles.scroll} ${variant === 'dropdown' && styles.dropdown}`}>
    <InfiniteScroll
      dataLength={options.length}
      next={handleFetch}
      hasMore={hasMoreData}
      height={height}
      className={styles.infinityScroll}
      loader={(
        <Loading
          marginTop='1rem'
          marginBottom='1rem'
        />
      )}
      endMessage={
        <p className={styles.finalText}>
          <b>
            {finalText}
          </b>
        </p>
      }
    >
      <ul>
        {options.map((opt: T) => (
          <li
            key={`scrollOption_${getOptionKey(opt)}`}
            className={styles.item}
          >
            {getOptionComponent(opt)}
          </li>
        ))}
      </ul>
    </InfiniteScroll>
  </div>
)
