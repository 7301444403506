import { Box, Grid } from "@mui/material";

import { DashboardFiltersType, getFilterTags } from "../../../components/DashboardFilters/DashboardFilters";
import { KpiCardType } from "../../../components/KpiCard/KpiCard";
import DadosDiaActualModel from "../../../models/DadosDiaActualModel";
import RankingLojas30DiasModel from "../../../models/RankingLojas30DiasModel";
import RankingProdutos30DiasModel from "../../../models/RankingProdutos30DiasModel";
import { DashboardWidgetType, getWidgetsRender, WidgetsStructure } from "../../../utils/dashboard";
import { WrapperWidth } from "../../../utils/wrapper";

//#region Enums

enum TableTypes {
  STORE_AND_PRODUCTS = 'StoreAndProducts',
  STORE_RANKING = 'StoreRanking',
  PRODUCT_RANKING = 'ProductRanking'
}

//#endregion Enums

//#region Constants

const TABLES: WidgetsStructure = {
  [TableTypes.STORE_AND_PRODUCTS]: {
    model: DadosDiaActualModel,
    filters: true,
    width: WrapperWidth.FULL
  },
  [TableTypes.STORE_RANKING]: {
    model: RankingLojas30DiasModel,
    filters: getFilterTags([DashboardFiltersType.SELLERS, DashboardFiltersType.STORE, DashboardFiltersType.DATES]),
    width: WrapperWidth.FULL
  },
  [TableTypes.PRODUCT_RANKING]: {
    model: RankingProdutos30DiasModel,
    filters: getFilterTags([DashboardFiltersType.SUB_FAMILY, DashboardFiltersType.PRODUCT, DashboardFiltersType.DATES]),
    width: WrapperWidth.FULL
  }
};

const KPIS: WidgetsStructure = {
  [KpiCardType.OUTAGES]: {
    filters: true
  },
  [KpiCardType.AVERAGE_OUTAGES_30]: {
    filters: true
  },
  [KpiCardType.DAYS_WITHOUT_SALES_30]: {
    filters: true
  },
  [KpiCardType.MAIN_TOTAL_LOST_SALES]: {
    filters: true
  },
  [KpiCardType.MAIN_ESTIMATED_LOST_VALUE]: {
    filters: true
  },
  [KpiCardType.CLOSING_DATA]: {
    filters: true
  }
};

//#endregion Constants


export const Main = () => {
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item flexGrow={1}>
          <Grid container spacing={1}>
            { getWidgetsRender(DashboardWidgetType.KPIS, KPIS) }
          </Grid>
        </Grid>
        { getWidgetsRender(DashboardWidgetType.TABLES, TABLES) }
      </Grid>
    </Box>
  );
}
