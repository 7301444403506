import { Box, Grid } from "@mui/material"

import { DashboardChartType } from "../../../components/DashboardChart/DashboardChart"
import { KpiCardType } from "../../../components/KpiCard/KpiCard"
import DadosUltimos30DiasModel from "../../../models/DadosUltimos30DiasModel"
import { DashboardWidgetType, getWidgetsRender, WidgetsStructure } from "../../../utils/dashboard"
import { wrapContent, WrapperConfig, WrapperHeight, WrapperType, WrapperWidth } from "../../../utils/wrapper"

//#region Enums

enum TableTypes {
  LAST_MONTH_DATA_BASE = 'LastMonthDataBase'
}

//#endregion Enums

//#region Interface

interface WrapWidgetStructure {
  wrapConfig: Partial<WrapperConfig>;
  widgets: WidgetsStructure
}

//#endregion Interface

//#region Constants

const LEFT_CHARTS: WidgetsStructure = {
    [DashboardChartType.SELLOUT]: {
      filters: true,
      width: WrapperWidth.FULL,
      height: WrapperHeight.MEDIUM
    },
    [DashboardChartType.STOCK]: {
      filters: true,
      width: WrapperWidth.FULL,
      height: WrapperHeight.MEDIUM
    },
    [DashboardChartType.IN_SUPPLY]: {
      filters: true,
      width: WrapperWidth.FULL,
      height: WrapperHeight.MEDIUM
    },
    [DashboardChartType.IN_OUTAGE]: {
      filters: true,
      width: WrapperWidth.FULL,
      height: WrapperHeight.MEDIUM
    },
    [DashboardChartType.LOST_SALES]: {
      filters: true,
      width: WrapperWidth.FULL,
      height: WrapperHeight.MEDIUM
    }
  },
  TABLE: WidgetsStructure = {
    [TableTypes.LAST_MONTH_DATA_BASE]: {
      model: DadosUltimos30DiasModel,
      filters: true,
      width: WrapperWidth.FULL
    }
  },
  WRAP_KPIS: WrapWidgetStructure[] = [
    {
      wrapConfig: {
        height: WrapperHeight.MEDIUM
      },
      widgets: {
        [KpiCardType.AVERAGE_SELLOUT]: {
          filters: true,
          height: WrapperHeight.SMALL,
          width: WrapperWidth.HALF
        },
        [KpiCardType.MONTHLY_TOTAL_SELLOUT]: {
          filters: true,
          height: WrapperHeight.SMALL,
          width: WrapperWidth.HALF
        },
        [KpiCardType.PREVIOUS_WEEK_SELLOUT]: {
          filters: true,
          height: WrapperHeight.MEDIUM,
          width: WrapperWidth.HALF
        }
      }
    }, {
      wrapConfig: {
        height: WrapperHeight.MEDIUM
      },
      widgets: {
        [KpiCardType.AVERAGE_STOCK]: {
          filters: true,
          height: WrapperHeight.MEDIUM,
          width: WrapperWidth.HALF
        },
        [KpiCardType.PREVIOUS_WEEK_STOCK]: {
          filters: true,
          height: WrapperHeight.MEDIUM,
          width: WrapperWidth.HALF
        }
      }
    }, {
      wrapConfig: {
        height: WrapperHeight.MEDIUM
      },
      widgets: {
        [KpiCardType.MONTHLY_TOTAL_SUPPLY]: {
          filters: true,
          height: WrapperHeight.MEDIUM,
          width: WrapperWidth.HALF
        },
        [KpiCardType.MONTHLY_EXPECTED]: {
          filters: true,
          height: WrapperHeight.SMALL,
          width: WrapperWidth.HALF
        },
        [KpiCardType.MONTHLY_INTRANSIT]: {
          filters: true,
          height: WrapperHeight.SMALL,
          width: WrapperWidth.HALF
        }
      }
    }, {
      wrapConfig: {
        height: WrapperHeight.MEDIUM
      },
      widgets: {
        [KpiCardType.MONTHLY_TOTAL_OUTAGES]: {
          filters: true,
          height: WrapperHeight.SMALL,
          width: WrapperWidth.HALF
        },
        [KpiCardType.PERCENTAGE_OUTAGE]: {
          filters: true,
          height: WrapperHeight.SMALL,
          width: WrapperWidth.HALF
        },
        [KpiCardType.PREVIOUS_WEEK_OUTAGES]: {
          filters: true,
          height: WrapperHeight.MEDIUM,
          width: WrapperWidth.HALF
        }
      }
    }, {
      wrapConfig: {
        height: WrapperHeight.MEDIUM
      },
      widgets: {
        [KpiCardType.MONTHLY_TOTAL_LOST_SALES]: {
          filters: true,
          height: WrapperHeight.MEDIUM,
          width: WrapperWidth.HALF
        },
        [KpiCardType.WEEK_LOST_SALES]: {
          filters: true,
          height: WrapperHeight.MEDIUM,
          width: WrapperWidth.HALF
        }
      }
    }
  ],
  RIGHT_CHARTS: WidgetsStructure = {
    [DashboardChartType.WEEKLY_FLUCTUATION]: {
      filters: true,
      width: WrapperWidth.FULL,
      height: WrapperHeight.HIGH
    },
    [DashboardChartType.WEEKLY_OUTAGES]: {
      filters: true,
      width: WrapperWidth.FULL,
      height: WrapperHeight.HIGH
    }
  },
  RIGHT_KPIS: WidgetsStructure = {
    [KpiCardType.WEEK_LOST_VALUE]: {
      filters: true,
      height: WrapperHeight.MEDIUM,
      width: WrapperWidth.HALF
    },
    [KpiCardType.MONTHLY_ESTIMATED_LOST_VALUE]: {
      filters: true,
      height: WrapperHeight.MEDIUM,
      width: WrapperWidth.HALF
    }
  }

//#endregion Constants

export const LastMonth = () => {
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item md={12} lg={6}>
              <Grid container spacing={1}>
              { getWidgetsRender(DashboardWidgetType.CHARTS, LEFT_CHARTS) }
              </Grid>
            </Grid>
            <Grid item md={12} lg={3}>
              <Grid container spacing={1}>
                {WRAP_KPIS.map(({wrapConfig, widgets}) => (
                  wrapContent(<Grid height={wrapConfig.height} flexDirection={'column'} container spacing={1}>{getWidgetsRender(DashboardWidgetType.KPIS, widgets)}</Grid>, {...wrapConfig, type: WrapperType.GRID, genKey: true})
                ))}
              </Grid>
            </Grid>
            <Grid item md={12} lg={3}>
              <Grid container spacing={1}>
                { getWidgetsRender(DashboardWidgetType.CHARTS, RIGHT_CHARTS) }
                { getWidgetsRender(DashboardWidgetType.KPIS, RIGHT_KPIS) }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        { getWidgetsRender(DashboardWidgetType.TABLES, TABLE) }
      </Grid>
    </Box>
  )
}
