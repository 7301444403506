import { CustomGridColDef, GridColSortType } from "../components/Table/RemoteTable";
import { ValueType } from "../utils/formatter";

//#region Constants

const TABLE_NAME = 'dadosTotal';

//#endregion Constants

export default class DadosAnoModel {
  //#region Public Properties

  /* eslint-disable @typescript-eslint/no-inferrable-types*/
  public data: Date = new Date();
  public descArtigo: string = '';
  public storeName: string = '';
  public vendedor: string = '';
  public fluxo: string = '';
  public codInterno?: string = '';
  public ean: number = 0;
  public store: number = 0;
  public familia?: string;
  public subFamilia?: string;
  public intransit1DiasAntes: number = 0;
  public expected1DiasAntes: number = 0;
  public rotura: number = 0;
  public preRotura: number = 0;
  public stock1DiasAntes: number = 0;
  public presStock1DiasAntes: number = 0;
  public sellout1DiasAntes: number = 0;
  public sellout: number = 0;
  public stock: number = 0;
  public intransit: number = 0;
  public expected: number = 0;
  public fornecimento: number = 0;
  public presStock: number = 0;
  public grupoProduto?: string;
  public segmento?: string;
  public semana: number = 0;

  //#endregion Public Properties

  //#region Static Methods

  public static getColumnsConfig = (): { [key in keyof DadosAnoModel]?: CustomGridColDef<DadosAnoModel>} => ({
    data: {
      formatType: ValueType.DATE
    },
    vendedor: {
      formatType: ValueType.STRING
    },
    storeName: {
      formatType: ValueType.STRING
    },
    descArtigo: {
      formatType: ValueType.STRING
    },
    fluxo: {
      formatType: ValueType.STRING
    },
    subFamilia: {
      formatType: ValueType.STRING
    },
    grupoProduto: {
      formatType: ValueType.STRING
    },
    rotura: {
      formatType: ValueType.NUMBER,
      defaultSorted: GridColSortType.DESC
    },
    sellout: {
      formatType: ValueType.NUMBER
    },
    stock: {
      formatType: ValueType.NUMBER
    },
    presStock: {
      formatType: ValueType.NUMBER
    },
    fornecimento: {
      formatType: ValueType.NUMBER
    },
    intransit: {
      formatType: ValueType.NUMBER
    },
    expected: {
      formatType: ValueType.NUMBER
    },
    familia: {
      formatType: ValueType.STRING
    },
    segmento: {
      formatType: ValueType.STRING
    }
  })

  public static getCustomFilters = (): { [key: string]: string} => ({ table: TABLE_NAME});

  public static getTableName = () => TABLE_NAME;


  //#endregion Static Methods
}
