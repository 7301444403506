import { CustomGridColDef, GridCellWidgetType, GridColSortType } from "../components/Table/RemoteTable";
import { ValueType } from "../utils/formatter";

//#region Constants

const TABLE_NAME = 'rankingLojas30Dias';

//#endregion Constants

export default class RankingLojas30DiasModel {

  //#region Public Properties

  /* eslint-disable @typescript-eslint/no-inferrable-types*/
  public data: Date = new Date();
  public storeName: string = '';
  public vendedor: string = '';
  public selloutTotal: number = 0;
  public totalVendasPerdidas: number = 0;
  public percentagemRoturas: number = 0;
  public percentagemDiasSemVendas: number = 0;
  public taxaConversaoMedia: number = 0;
  public diasDuracaoLinearMedio?: number;
  public totalValorPerdido: number = 0;
  public totalRendimento: number = 0;
  public store: number = 0;


  //#endregion Public Properties

  //#region Static Methods

  public static getColumnsConfig = (): { [key in keyof RankingLojas30DiasModel]?: CustomGridColDef<RankingLojas30DiasModel>} => ({
    vendedor: {
      formatType: ValueType.STRING
    },
    storeName: {
      formatType: ValueType.STRING
    },
    selloutTotal: {
      formatType: ValueType.NUMBER,
      defaultSorted: GridColSortType.DESC,
      widget: GridCellWidgetType.PROGRESS
    },
    totalRendimento: {
      formatType: ValueType.CURRENCY
    },
    taxaConversaoMedia: {
      formatType: ValueType.NUMBER
    },
    percentagemRoturas: {
      formatType: ValueType.PERCENTAGE,
      // eslint-disable-next-line i18next/no-literal-string
      headerResourceName: 'PercentagemRoturas30'
    },
    percentagemDiasSemVendas: {
      formatType: ValueType.PERCENTAGE,
      // eslint-disable-next-line i18next/no-literal-string
      headerResourceName: 'PercentagemDiasSemVendas30'
    },
    totalVendasPerdidas: {
      formatType: ValueType.NUMBER
    },
    totalValorPerdido: {
      formatType: ValueType.CURRENCY
    }
  });

  public static getCustomFilters = (): { [key: string]: string} => ({ table: TABLE_NAME});

  //#endregion Static Methods

}
