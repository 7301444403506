import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CheckMark from '../../assets/icons/checkMark.svg'
import CopyToClipboardSvg from '../../assets/icons/copyToClipboard.svg'
import { Button } from '../Button'


type CopyToClipboardProps = {
  textToCopy: string
  tooltipText: string
}


export const CopyToClipboard = ({
  textToCopy,
  tooltipText
}: CopyToClipboardProps) => {
  const i18n = useTranslation()
  const [myTooltipText, setMyTooltipText] = useState(tooltipText)
  const [myIcon, setIcon] = useState(CopyToClipboardSvg)


  const handleClick = useCallback(() => {
    navigator.clipboard.writeText(textToCopy)
    setMyTooltipText(i18n.t('Common:Copied'))
    setIcon(CheckMark)
    setTimeout(() => {
      setMyTooltipText(tooltipText)
      setIcon(CopyToClipboardSvg)
    }, 2000)
  }, [tooltipText, i18n, textToCopy])


  return (
    <Button
      handler={handleClick}
      icon={myIcon}
      variant='primaryWhite'
      small
      tooltipText={myTooltipText}
    />
  )
}
