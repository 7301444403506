import React from 'react'

import { Button } from '../Button'
import { Pill } from '../Pill'

import styles from './ContinuousPill.module.scss'


type ContinuousPillProps<T> = {
  // When is a continuous pill without handler -> simple pills
  variant?: 'primary'
          | 'primaryWhite'
          | 'secondary'
          | 'secondaryWhite'
          | 'neutral'
          | 'neutralWhite'
          | 'success'
          | 'successWhite'
          | 'danger'
          | 'dangerWhite'
          | 'alert'
          | 'alertWhite'
          | 'warning'
          | 'warningWhite'
  options: T[]
  selected?: undefined,
  getOptionId?: (item: T) => string
  getOptionText: (item: T) => string
  getOptionIcon?: (item: T) => string
  onChange?: undefined
  fullHeight?: boolean
  noDivider?: boolean
} | {
  // When is a continuous pill with handler -> buttons pills
  variant?: undefined
  options: T[]
  selected: T
  getOptionId: (item: T) => string
  getOptionText: (item: T) => string
  getOptionIcon?: (item: T) => string
  onChange: (opt: T) => void
  fullHeight?: boolean
  noDivider?: boolean
}


export const ContinuousPill = <T, >({
  variant,
  options,
  selected,
  getOptionId,
  getOptionText,
  getOptionIcon,
  onChange,
  fullHeight,
  noDivider
}: ContinuousPillProps<T>) => (
  <div className={`${styles.continuousPill} ${fullHeight && styles.fullHeight}`}>
    {onChange ? (
      options.map((opt: T, idx: number) => (
        <Button
          key={`continuousPill_${getOptionId(opt)}`}
          variant={getOptionId(opt) === getOptionId(selected) ? 'primary' : 'primaryWhite'}
          title={getOptionText(opt)}
          titleSize='h6'
          icon={getOptionIcon && getOptionIcon(opt)}
          fullHeight
          handler={() => onChange(opt)}
          pill
          noBorderRadiusLeft={idx !== 0}
          noBorderRadiusRight={idx !== options.length - 1}
          noBorderLeft={idx !== 0}
          noBorderRight={noDivider && idx !== options.length - 1}
        />
      ))
    ) : (
      options.map((opt: T, idx: number) => (
        <Pill
          key={`continuousPill_${getOptionText(opt)}_${idx}`}
          variant={variant}
          title={getOptionText(opt)}
          titleSize='h6'
          icon={getOptionIcon && getOptionIcon(opt)}
          fullHeight
          noBorderRadiusLeft={idx !== 0}
          noBorderRadiusRight={idx !== options.length - 1}
          noBorderLeft={idx !== 0}
          noBorderRight={noDivider && idx !== options.length - 1}
        />
      ))
    )}
  </div>
)
