import 'leaflet/dist/leaflet.css'
import { Icon, divIcon } from 'leaflet'
import React from 'react'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'

import { StoreStatusType } from '../../api/types'
import storeCompleted from '../../assets/icons/storeCompleted.svg'
import storeNeutral from '../../assets/icons/storeNeutral.svg'
import storeRejected from '../../assets/icons/storeRejected.svg'
import storeToValidate from '../../assets/icons/storeToValidate.svg'
import storeUndone from '../../assets/icons/storeUndone.svg'

import styles from './Map.module.scss'


// ICONS if needed to redo colors:
//   to_validate: https://www.svgrepo.com/svg/185996/store-search
//   others: https://www.svgrepo.com/svg/185997/store
// The colors used are mathcing the ones in the lists on the StoresSummary.tsx file (both the backgroundColor and the borderColor)

export type MarkerProps = {
  position: [number, number]
  popUp: string
  status: StoreStatusType
}

type MapProps = {
  markers: MarkerProps[]
}


export const Map = ({markers}: MapProps) => {
  const icons: { [key: string]: string } = {
    storeCompleted: storeCompleted,
    storeNeutral: storeNeutral,
    storeRejected: storeRejected,
    storeToValidate: storeToValidate,
    storeUndone: storeUndone,
  }

  const createCustomClusterIcon = (cluster: { getChildCount: () => number }) => {
    return divIcon({
      html: `<div><span>${cluster.getChildCount()}</span></div>`,
      className: styles.clusterIcon,
      iconSize: [40, 40]
    })
  }

  const getIconName = (status: string) => {
    /* eslint-disable i18next/no-literal-string */
    if (status === 'completed') return 'storeCompleted'
    if (status === 'analysis') return 'storeToValidate'
    if (status === 'denied') return 'storeRejected'
    if (status === 'undone') return 'storeUndone'
    return 'storeNeutral'
    /* eslint-enable i18next/no-literal-string */
  }


  return (
    <MapContainer
      center={[39.65, -7.1]}
      zoom={7}
      scrollWheelZoom={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
      />
      <MarkerClusterGroup
        chunkedLoading
        iconCreateFunction={createCustomClusterIcon}
      >
        {markers.map(marker => (
            <Marker
              key={`mapMarker_pos${marker.position[0]}_${marker.position[1]}_popUp_${marker.popUp}`}
              position={marker.position}
              icon={new Icon({
                iconUrl: icons[getIconName(marker.status)],
                iconSize: [38, 38]
              })}
            >
              <Popup>
                <h3>
                  {marker.popUp}
                </h3>
              </Popup>
            </Marker>
          ))
        }
      </MarkerClusterGroup>
    </MapContainer>
  )
}
