import { FilterAltOff } from "@mui/icons-material";
import { Box, Button, Grid } from "@mui/material";
import { useMemo } from "react";

import { getDashboardSelector } from "../../api";
import { useGenericFilterContext } from "../../contexts/GenericFilterContext";
import { DateRangePicker } from "../../elements/DateRangePicker/DateRangePicker";
import { DateTimePickerType } from "../../elements/DateTimePicker/DateTimePicker";
import { RemoteDropdown, RemoteDropdownType } from "../../elements/Dropdown/RemoteDropdown";
import { useSticky } from "../../hooks/useSticky";
import i18n from "../../localization";
import { formatTitle } from "../../utils/formatter";
import { isEmptyOrWhiteSpace, isMobile } from "../../utils/validator";

import styles from "./DashboardFilters.module.scss";

//#region Enums

enum DashboardFilterWidgetType {
  SELECT = 'select',
  DATE_PICKER = 'date-picker'
}

export enum DashboardFiltersType {
  SELLERS = 'sellers',
  STORE = 'store',
  SUB_FAMILY = 'subFamily',
  PRODUCT = 'product',
  SUB_BANNER = 'subBanner',
  DATES = 'dates'
}

//#endregion Enums

//#region Interfaces

export interface DashboardSelectorFilters {
  column: string
}

interface DashboardFilterInternalConfig {
  widget: DashboardFilterWidgetType,
  baseFilters: DashboardSelectorFilters
}

//#endregion Interfaces

//#region Constants

const IS_MOBILE = isMobile(),
  MIN_MAX_ARRAY = ['min', 'max'],
  DATE_PICKER_FLEX_BASIS = '6rem',
  DROPDOWN_MIN_WIDTH = IS_MOBILE ? '12rem' : '9rem',
  DATE_PICKER_MIN_WIDTH = '26.5rem',
  FILTERS_CONFIG: {[key in DashboardFiltersType]: DashboardFilterInternalConfig} = {
    sellers: {
      widget: DashboardFilterWidgetType.SELECT,
      baseFilters: {
        column: 'vendedor'
      }
    },
    store: {
      widget: DashboardFilterWidgetType.SELECT,
      baseFilters: {
        column: 'storeName'
      }
    },
    subFamily: {
      widget: DashboardFilterWidgetType.SELECT,
      baseFilters: {
        column: 'subFamilia'
      }
    },
    product: {
      widget: DashboardFilterWidgetType.SELECT,
      baseFilters: {
        column: 'descArtigo'
      }
    },
    subBanner: {
      widget: DashboardFilterWidgetType.SELECT,
      baseFilters: {
        column: 'subinsignia'
      }
    },
    dates: {
      widget: DashboardFilterWidgetType.DATE_PICKER,
      baseFilters: {
        column: 'data'
      }
    }
  }

//#endregion Constants

//#region Auxiliar Methods

export const getFilterTags = (types: DashboardFiltersType[]) => types.reduce((acc: string[], type: DashboardFiltersType) => {
  const {widget, baseFilters} = FILTERS_CONFIG[type],
        { column } = baseFilters;

  switch (widget) {
    case DashboardFilterWidgetType.DATE_PICKER:
      return [...acc, ...MIN_MAX_ARRAY.map((item: string) => {
        return `${item}${formatTitle(column)}`
      })];
    default:
      return [...acc, column];
  }
}, []);

export const getFilterNames = (columns: string[]) => Object.entries(FILTERS_CONFIG).reduce((acc: DashboardFiltersType[], [name, config]: [string, DashboardFilterInternalConfig]) => {
  const {baseFilters} = config;
  if(columns.includes(baseFilters.column)) {
    acc.push(name as DashboardFiltersType);
  }
  return acc;
}, []);

//#endregion Auxiliar Methods


export const DashboardFilters = () => {
  const {genericFilters, genericZoomDateFilter, inUseFilters, cleanupGenericFilters} = useGenericFilterContext(),
        disableCleanup = Object.entries(genericFilters).every(([,value]) => isEmptyOrWhiteSpace(value)),
        zoomActive = useMemo(() => genericZoomDateFilter.some((value: unknown) => !!value),[genericZoomDateFilter]),
        {ref, isSticky} = useSticky();

  return (
    <Box className={`${styles.float} ${isSticky ? styles.floating : ''}`} ref={ref}>
      <Grid container columnGap={2} rowGap={zoomActive ? 4 : 2} flexWrap='wrap'>
        {
          Object.entries(FILTERS_CONFIG)
            .map(([label, config]: [string, DashboardFilterInternalConfig]) => {
              const { column } = config.baseFilters,
                    disabled = Array.isArray(inUseFilters) && !!inUseFilters.length && !inUseFilters.includes(column);

              switch(config.widget) {
                case DashboardFilterWidgetType.SELECT:
                  return (
                    <Grid key={`${label}-grid`} item flexGrow={1} flexShrink={1} width={0} minWidth={DROPDOWN_MIN_WIDTH}>
                      <RemoteDropdown
                        key={`${label}-widget`}
                        bindGenericFilters={true}
                        label={i18n.t(`Dashboard:${formatTitle(label)}`)}
                        multiple={true}
                        type={RemoteDropdownType.FILTER}
                        filterField={column}
                        fetchMethod={(otherFilters?: object) => getDashboardSelector({...otherFilters, ...config.baseFilters} as DashboardSelectorFilters)}
                        disabled={disabled}
                      />
                    </Grid>
                  );
                case DashboardFilterWidgetType.DATE_PICKER:
                default:
                  return (
                    <Grid key={`${label}-grid`} item flexGrow={1} width={0} flexBasis={DATE_PICKER_FLEX_BASIS} minWidth={DATE_PICKER_MIN_WIDTH}>
                      <DateRangePicker
                        type={DateTimePickerType.FILTER}
                        size='small'
                        filterField={column}
                        disabled={disabled}/>
                    </Grid>
                  );
              }
            })
        }
        {
          <Button className={styles.clear} disabled={disableCleanup} variant='outlined' onClick={cleanupGenericFilters}>
            <FilterAltOff/>
          </Button>
        }
      </Grid>
    </Box>
  );
}
