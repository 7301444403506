import React from 'react'
import { useParams } from 'react-router-dom'


export const LookerDashboard = () => {
  const { id1, id2 } = useParams<{ id1: string; id2: string }>()

  return (
    <iframe
      src={`https://lookerstudio.google.com/embed/reporting/${id1}/page/${id2}`}
      // eslint-disable-next-line no-inline-styles/no-inline-styles
      style={{ border: '5px solid', borderColor: '#015EC5' ,width: '100%', height: 'calc(100vh - 12rem)', minHeight: 'calc(100vh - 12rem)' }}
      allowFullScreen
      sandbox='allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox'
    ></iframe>
  )
}
