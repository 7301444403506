import { MoreVert } from "@mui/icons-material";
import { Box, ListItemIcon, Menu, MenuItem } from "@mui/material";
import { ReactNode, useState, MouseEvent, ComponentType, createElement, useEffect, useMemo } from "react";

import i18n from "../../localization";
import { formatTitle } from "../../utils/formatter";
import { isNullOrUndefined } from "../../utils/validator";

import styles from './ActionsMenu.module.scss';

//#region Interfaces

export interface ActionsMenuItem<T> {
  icon?: ComponentType;
  id: T;
  toggle?: boolean;
  active?: boolean;
  disabled?: boolean;
  hiddenCondition?: () => boolean;
}

interface ActionsMenuConfig<T> {
  context?: string;
  children?: ReactNode;
  actions: ActionsMenuItem<T>[];
  absolute?: boolean;
  onClick?: (id: T, toggled?: boolean) => void;
}

//#endregion Interfaces

//#region Constants

const DEFAULT_CONTEXT = 'Common';

//#endregion Constants

export const ActionsMenu = <T=string>({children, context, actions: externalActions, absolute, onClick}: ActionsMenuConfig<T>) => {
  const [anchorEl, setAnchorEl] = useState(null as null | HTMLElement),
        [actions, setActions] = useState(externalActions),
        boxClasses = useMemo(() => [styles.box, absolute ? styles.absolute : undefined].filter((value?: string) => !isNullOrUndefined(value)).join(' '), [absolute]);

  //#region Private Methods

  const open = ({currentTarget}: MouseEvent<HTMLElement>) => {
    setAnchorEl(currentTarget);
  }

  const close = () => {
    setAnchorEl(null);
  }

  const onClose = () => {
    close();
  }

  const onMenuItemClick = (triggerId: T) => {
    close();
    const allActions = [...actions],
          action = allActions.find(({id}: ActionsMenuItem<T>) => id === triggerId);

    if(action && action.toggle) {
      action.active = !action.active;
      setActions(allActions);
    }
    
    onClick?.(triggerId, action?.active);
  }

  //#endregion Private Methods

  //#region Effects

  useEffect(() => {
    setActions((prevActions: ActionsMenuItem<T>[]) => {
      return externalActions.map((action: ActionsMenuItem<T>) => ({
        ...action,
        active: prevActions.find(({id}: ActionsMenuItem<T>) => id === action.id)?.active
      }));
    });
  }, [externalActions]);

  //#endregion Effects

  return (
    <Box className={boxClasses} display='flex' gap={1} alignItems='center'>
      {children}
      <button className={styles.toggler} onClick={open}>
        <MoreVert/>
      </button>
      <Menu
        anchorEl={anchorEl}
        onClose={onClose}
        open={!!anchorEl}
        className={styles.menu}>
        {
          actions
            .filter(({hiddenCondition}: ActionsMenuItem<T>) => !hiddenCondition?.() ?? true)
            .map(({id, icon, disabled, active}) => (
              <MenuItem
                key={`menu-item-${(id as string).toLowerCase()}`}
                onClick={() => onMenuItemClick(id)}
                disabled={disabled}
                selected={active}>
                  <ListItemIcon>
                    {icon ? createElement(icon) : undefined}
                  </ListItemIcon>
                  {i18n.t(`${context ?? DEFAULT_CONTEXT}:${formatTitle(id as string)}`)}
              </MenuItem>
            ))
        }
      </Menu>
    </Box>
  );
}
