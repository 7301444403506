import { Grid } from '@mui/material';

import { getDashboardTable } from '../api';
import { DashboardChart, DashboardChartType } from '../components/DashboardChart/DashboardChart';
import { KpiCard, KpiCardType } from '../components/KpiCard/KpiCard';
import { RemoteTable } from '../components/Table/RemoteTable';
import i18n from '../localization';
import BaseTableModel from '../models/base/BaseTableModel';

import { formatTitle } from './formatter';
import { WrapperWidth, WrapperType, WrapperHeight } from './wrapper';

//#region Enums

export enum DashboardWidgetType {
  TABLES = 'tables',
  KPIS = 'kpis',
  CHARTS = 'charts'
}

//#endregion Enums

//#region Interfaces

export interface DashboardWidgetConfig {
  filters?: boolean | string[];
  model?: BaseTableModel;
  width?: WrapperWidth;
  height?: WrapperHeight;
  keepRatio?: boolean;
}

//#endregion Interfaces

//#region Types

export type WidgetsStructure = { [key: string]: DashboardWidgetConfig };

//#endregion Types

export const getWidgetsRender = (type: DashboardWidgetType, structure: WidgetsStructure) => {
  return Object.entries(structure).map(([key, config]: [string, DashboardWidgetConfig]) => {
    switch(type) {
      case DashboardWidgetType.KPIS:
        return <KpiCard
                  key={`kpi-${key}`}
                  bindGenericFilters={config.filters}
                  type={key as KpiCardType}
                  wrapper={{
                    type: WrapperType.GRID,
                    width: config.width,
                    height: config.height
                  }}/>;
      case DashboardWidgetType.TABLES:
        return <RemoteTable
                  key={`table-${key}`}
                  fetchMethod={getDashboardTable}
                  model={config.model as BaseTableModel}
                  title={i18n.t(`Dashboard:${formatTitle(key)}`)}
                  bindGenericFilters={config.filters}
                  wrapper={{
                    type: WrapperType.GRID,
                    width: config.width
                  }}/>;
      case DashboardWidgetType.CHARTS:
        const chart = (
          <DashboardChart
            key={`chart-${key}`}
            type={key as DashboardChartType}
            filters={config.filters}
            wrapper={{
              type: WrapperType.GRID,
              width: config.width,
              height: config.height
            }}/>
        );

        return config.keepRatio ? (
          <Grid item flexGrow={1} width={WrapperWidth.FULL} key={`keep-ratio-chart-${key}`}>
            <Grid container width={WrapperWidth.FULL} className='keep-ratio'>
              {chart}
            </Grid>
          </Grid>
        ) : chart;
      default:
        return <></>;
    }
  })
}
