import dayjs from "dayjs";

import { isEmptyOrWhiteSpace, isNullOrUndefined } from "./validator";

//#region Enums

export enum ValueType {
  PERCENTAGE = 'percentage',
  CURRENCY = 'currency',
  NUMBER = 'number',
  DATE = 'date',
  DATE_TIME = 'datatime',
  STRING = 'string',
  WEEK_INTERVAL = 'weekInterval'
}

//#endregion Enums

//#region Constants

const SYMBOLS: string[] = ['', 'k', 'M', 'B'];
export const EMPTY_VALUE = '--';

//#endregion Constants


export const fullAbreviateNumber = (value: number, decimalPlaces = 2): string  => {
  let remnant = value,
      symbolIndex = 0;

  while(remnant >= 1000) {
    remnant /= 1000;
    symbolIndex++;
  }

  decimalPlaces = remnant % 1 === 0 ? 0 : decimalPlaces;

  return `${remnant.toFixed(decimalPlaces)}${SYMBOLS[symbolIndex]}`;
}

export const formatString = (value: string, ...replacements: (string|number)[]): string => {
  return replacements.reduce((formattedValue: string, replacement: string|number, index: number) => {
    return formattedValue.replaceAll(`{${index}}`, replacement.toString());
  }, value);
}

export const formatTitle = (text?: string): string => {
  return text ? `${text[0].toUpperCase()}${text.substring(1)}` : '';
}

export const formatVariable = (text?: string): string => {
  return text ? `${text[0].toLowerCase()}${text.substring(1)}` : '';
}

export const formatSnakeToCamelCase = (value: string) => {
  return value
          .split('_')
          .map((word: string, index: number) => `${index === 0 ? word[0] : word[0].toUpperCase()}${word.substring(1)}`)
          .join('');
}

export const formatCamelToSnakeCase = (value: string) => {
  return value.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

export const formatFileName = (value: string) => {
  return value.toLowerCase()
              .normalize("NFD").replace(/\p{Diacritic}/gu, "") // Remove diacritics from string
              .replaceAll(/\ /g, '_'); // Replace spaces by underscores
}

export const formatValueByType = (value: unknown, type?: ValueType) => {
  let formattedValue = '';

  if(isNullOrUndefined(value)) {
    return EMPTY_VALUE;
  }

  switch(type) {
    case ValueType.PERCENTAGE:
    case ValueType.CURRENCY:
    case ValueType.NUMBER:
      formattedValue = fullAbreviateNumber(value as number);
      break;
    case ValueType.DATE:
    case ValueType.WEEK_INTERVAL:
      formattedValue = dayjs(value as string).format('DD/MM/YYYY');
      break;
    case ValueType.DATE_TIME:
      formattedValue = dayjs(value as string).format('DD/MM/YYYY HH:mm');
      break;
    default:
      return value as string;
  }

  switch(type) {
    case ValueType.PERCENTAGE:
      return `${formattedValue}%`;
    case ValueType.CURRENCY:
      return `${formattedValue}€`;
    default:
      return formattedValue;
  }
}


export const extractPartialObject = <T, U = keyof T>(obj: T, keys: U[]): Partial<T> => {
  return keys.reduce((newObj: Partial<T>, key: U) => {
    return {
      ...newObj,
      [key as string]: obj[key as keyof T]
    };
  }, {} as Partial<T>);
}

export const extractConditionalObject = <T, U = keyof T>(obj: T, keys?: boolean | U[]): Partial<T> => {
  return !keys
            ? {}
            : (
              keys === true
                ? {...obj}
                : extractPartialObject(obj, keys)
            );
}

export const cleanupObject = (obj: object) =>  {
  Object.keys(obj).forEach((key: string) => {
    if(isEmptyOrWhiteSpace(obj[key])) {
      delete obj[key];
    }
  })
  return obj;
}

export const clearObject = (obj: object, keepType?: boolean) => {
  Object.keys(obj).forEach((key: string) => {
    if(keepType) {
      obj[key] = (() => {
        switch(typeof obj[key]) {
          case 'object':
            return Array.isArray(obj[key]) ? [] : {};
          default:
            return undefined;
        }
      })();
    } else {
      delete obj[key];
    }
  })
  return obj;
}

export const roundToDecimalPlaces = (value: number, decimalPlaces = 2) => {
  const roundPlacer = Math.pow(10, decimalPlaces);
  return Math.round(value * roundPlacer)/roundPlacer;
}


export const formatSearchParameters = (obj: object) => {
  return Object.entries(obj).reduce((acc: object, [key, value]: [string, unknown]) => {
    const newKey = Array.isArray(value) ? `${key}[]` : key;
    return {...acc, [newKey]: value};
  }, {});
}

export const unformatSearchParameters = (obj: object) => {
  return Object.entries(obj).reduce((acc: object, [key, value]: [string, unknown]) => {
    const isArray = /\[\]/.test(key),
          newKey = /[^\[\]]*/.exec(key)?.[0] ?? key;
    return {...acc, [newKey]: isArray && !Array.isArray(value) ? [value] : value};
  }, {});
}

export const dataUrlToBlob = (dataUrl: string): Blob => {
  const [mimeInfo, rawData] = dataUrl.split(','),
        mime = mimeInfo.match(/:(.*?);/)?.[1],
        decodedRawData = atob(rawData),
        u8Array = [...decodedRawData].reverse().reduce((acc: Uint8Array, value: string, index: number) => {
          acc[index] = value.charCodeAt(0);
          return acc;
        }, new Uint8Array(decodedRawData.length));

  return new Blob([u8Array.reverse()], {type: mime});
}
