import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getPermissions, login, logout } from '../api'
import { PermissionsTypeAPI, PermissionType } from '../api/types'
import i18n from '../localization'
import { getPaths } from '../routing/routes'


type UserProviderProps = {
  children: ReactNode
}

type UserContextType = {
  isLoggedIn: boolean
  handleLogin: (email: string, password: string, rememberMe: boolean) => void
  handleLogout: () => void
  permissions: PermissionsTypeAPI | undefined
  hasPermissions: (permission: PermissionType) => boolean
  loading: boolean
}

const UserContext = createContext({} as UserContextType)
const { Provider } = UserContext


export const UserProvider = ({ children }: UserProviderProps ) => {
  const navigate = useNavigate()
  const [isLoggedIn, setisLoggedIn] = useState<boolean>()
  const [permissions, setPermissions] = useState<PermissionsTypeAPI>()
  const [authLoading, setAuthLoading] = useState<boolean>(true)
  const [permissionLoading, setPermissionsLoading] = useState<boolean>(true)
  const [cookies, setCookie, removeCookie] = useCookies(['email', 'authenticationToken'])


  // Check if the user is logged in when loading the app
  useEffect(() => {
    const email = cookies.email || localStorage.getItem('email')
    const authenticationToken = cookies.authenticationToken  || localStorage.getItem('authenticationToken')

    if (!!email && !!authenticationToken) {
      // Set permissions loading to true to avoid showing the user the page before the permissions are loaded
      setPermissionsLoading(true)
      setisLoggedIn(true)
    } else {
      setisLoggedIn(false)
    }

    setAuthLoading(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Only run once


  // When the user logs in, get the permissions
  // When the user logs out, remove the permissions
  useEffect(() => {
    if (authLoading) return

    if (!isLoggedIn) {
      setPermissions(undefined)
      setPermissionsLoading(false)
      return
    }

    setPermissionsLoading(true)
    getPermissions().then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:ErrorGettingPermissions'))
        setPermissionsLoading(false)
        return
      }
      setPermissions(response.data)
      setPermissionsLoading(false)
    })
  }, [authLoading, isLoggedIn])


  // Handle login and set the cookies
  const handleLogin = useCallback((
    email: string,
    password: string,
    rememberMe: boolean
  ) => {
    setAuthLoading(true)
    login({manager: { email, password }}).then(response => {
      if (!response.success) {
        toast.error(i18n.t('ToastError:LoginFailed'))
        setAuthLoading(false)
        return
      }

      setCookie('email', response.data.email)
      setCookie('authenticationToken', response.data.authenticationToken)
      // Set permissions loading to true to avoid showing the user the page before the permissions are loaded
      setPermissionsLoading(true)
      setisLoggedIn(true)
      setAuthLoading(false)

      if (rememberMe) {
        localStorage.setItem('email', response.data.email)
        localStorage.setItem('authenticationToken', response.data.authenticationToken)
      } else {
        localStorage.removeItem('email')
        localStorage.removeItem('authenticationToken')
      }

      navigate(getPaths.brands.missions.ongoing)
    })
  }, [setCookie, navigate])


  // Handle logout and remove the cookies
  const handleLogout = useCallback(() => {
    setAuthLoading(true)
    logout().then(() => {
      setisLoggedIn(false)
      setPermissions(undefined)
      removeCookie('email')
      removeCookie('authenticationToken')
      localStorage.removeItem('email')
      localStorage.removeItem('authenticationToken')
      setAuthLoading(false)
      navigate(getPaths.managers.signIn)
    })
  }, [removeCookie, navigate])


  // Check if the user has the permission
  const hasPermissions = useCallback((permission: PermissionType) => {
    return permissions?.includes(permission) ?? false
  }, [permissions])


  // Create the context value
  const value: UserContextType = useMemo(() => ({
    isLoggedIn: isLoggedIn || false,
    handleLogout,
    handleLogin,
    permissions,
    hasPermissions,
    loading: authLoading || permissionLoading
  }), [isLoggedIn, handleLogout, handleLogin, permissions, hasPermissions, authLoading, permissionLoading])


  return (
    <Provider value={value}>
      {children}
    </Provider>
  )
}

export const useUser = () => useContext(UserContext)
