import { CustomGridColDef } from "../components/Table/RemoteTable";
import { ValueType } from "../utils/formatter";
import { TableColorType } from "../utils/table";

import BaseAvisoModel from "./base/BaseAvisoModel";

//#region Enums

enum StateType {
  UNRESPONSIVE_RUPTURE = 'Rotura sem Resposta',
  IN_SUPPLY = 'Em Fornecimento'
}

//#endregion Enums

//#region Constants

const TABLE_NAME = 'avisoRoturaActual';

//#endregion Constants

export default class AvisoRoturaActualModel extends BaseAvisoModel {
  //#region Public Properties

  /* eslint-disable @typescript-eslint/no-inferrable-types*/
  public previsaoBn: string = '';
  public grupoProduto: string = '';
  public segmento: string = '';
  public estado: string = '';
  public sellout1DiasAntes: number = 0;
  public balanceSmart: number = 0;
  public selloutPrevisto: number = 0;
  public mediaDiasParaEntrega60: number = 0;

  //#endregion Public Properties

  //#region Static Methods

  public static getColumnsConfig = (): { [key in keyof AvisoRoturaActualModel]?: CustomGridColDef<AvisoRoturaActualModel>} => ({
    // eslint-disable-next-line i18next/no-literal-string
    ...BaseAvisoModel.getColumnsDefinition(['data', 'vendedor', 'storeName', 'descArtigo', 'fluxo', 'sellout', 'stock']),
    roturasConsecutivas: {
      formatType: ValueType.NUMBER,
      // eslint-disable-next-line i18next/no-literal-string
      headerResourceName: 'DiasRotura',
      cellConditionalFormat: {
        condition: (value: unknown) => value as number > 1,
        color: TableColorType.RED
      }
    },
    fornecimento: {
      formatType: ValueType.NUMBER,
      cellConditionalFormat: {
        condition: (value: unknown) => value as number > 0,
        color: TableColorType.GREEN
      }
    },
    mediaDiasParaEntrega60: {
      formatType: ValueType.NUMBER,
      // eslint-disable-next-line i18next/no-literal-string
      headerResourceName: 'MediaDiasParaEntrega'
    },
    estado: {
      formatType: ValueType.STRING,
      cellConditionalFormat: {
        condition: (value: unknown) => value === StateType.UNRESPONSIVE_RUPTURE,
        color: TableColorType.RED
      }
    }
  });

  public static getCustomFilters = (): { [key: string]: string} => ({ table: TABLE_NAME});

  //#endregion Static Methods
}
