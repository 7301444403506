import React from 'react'

import { TextInput } from '../TextInput'


type DecimalNumberInputProps = {
  title?: string
  placeholder?: string
  value: number | string
  onChange: (value: number) => void
  multiline?: boolean
  noBorders?: boolean
  decimalPlaces?: number
  endSymbol?: string
  disabled?: boolean
}


export const DecimalNumberInput = ({
  title,
  placeholder,
  value,
  onChange,
  noBorders,
  decimalPlaces = 2,
  endSymbol,
  disabled
}: DecimalNumberInputProps) => {
  const parsedValue = parseInitialValue(value, decimalPlaces)


  return (
    <TextInput
      title={title}
      placeholder={placeholder}
      value={parsedValue}
      onChange={v => onChangeValue(v, decimalPlaces, onChange)}
      noBorders={noBorders}
      disabled={disabled}
      endSymbol={endSymbol}
    />
  )
}


const onChangeValue = (
  string: string,
  decimalPlaces: number,
  onChange: (value: number) => void
) => {
  const cleanNumber = removeSymbols(string)

  if (
    cleanNumber === '00'
    || cleanNumber === null
    || cleanNumber === undefined
    || cleanNumber === ''
    || Number.isNaN(Number(cleanNumber))
  ) {
    onChange(0)
    return true
  }
  const number = Number(cleanNumber) / 10 ** decimalPlaces
  onChange(number)
  return true
}

const removeSymbols = (value: string | number) => String(value)
  .replace(/\D+/g, '')
  .replace(/\s+/g, '')
  .replace(/\.+/g, '')
  .replace(/,+/g, '')
  .replace(/-+/g, '')

const parseInitialValue = (
  value: string | number | null | undefined,
  decimalPlaces: number
) => (value === null || value === undefined
  ? ''
  : !Number.isNaN(value)
    ? Number.parseFloat(String(value)).toFixed(decimalPlaces)
    : '')
