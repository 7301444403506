import { Alert, AlertTitle, Button } from '@mui/material'
import { GridLoadIcon } from '@mui/x-data-grid'
import { useMemo } from 'react'

import i18n from '../../localization'

import styles from './ErrorAlert.module.scss'

//#region Interfaces

interface ErrorAlertConfig {
  show: boolean
  lineAlignment?: boolean
  onReload: () => void
}

//#endregion Interfaces

export const ErrorAlert = ({show, lineAlignment, onReload}: ErrorAlertConfig) => {
  const alertClasses = useMemo(() => [styles.alert, lineAlignment ? styles.line : undefined].filter((value: string | undefined) => value).join(' '), [lineAlignment])

  //#region Partials

  const reloadButton = (
    <Button onClick={onReload} className={styles.button} color='error'>
      <GridLoadIcon/>
    </Button>
  )

  //#endregion Partials

  return show ? (
    <Alert variant='standard' className={alertClasses} severity='error'>
      <AlertTitle>
        <span>{i18n.t('Error:APIFetchError')}</span>
        {lineAlignment ? reloadButton : undefined}
      </AlertTitle>
      {lineAlignment ? undefined : reloadButton}
    </Alert>
  ) : <></>
}
