import { Box, Tab, Tabs } from '@mui/material';
import React, { SyntheticEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Navigate, useParams, useSearchParams } from 'react-router-dom';

import { DashboardFilters } from '../../components/DashboardFilters/DashboardFilters';
import { GenericFilterProvider } from '../../contexts/GenericFilterContext';
import { getPaths } from '../../routing/routes';
import { formatCamelToSnakeCase, formatSnakeToCamelCase, formatTitle } from '../../utils/formatter';

import styles from './Dashboard.module.scss';
import { CurrentState } from './tabs/CurrentState';
import { DailyObservatory } from './tabs/DailyObservatory';
import { LastMonth } from './tabs/LastMonth';
import { LinearFitness } from './tabs/LinearFitness';
import { Main } from './tabs/Main';
import { MonthlyFluctuations } from './tabs/MonthlyFluctuations';
import { NextWeek } from './tabs/NextWeek';
import { PreviousWeek } from './tabs/PreviousWeek';
import { YearAnalysis } from './tabs/YearAnalysis';

//#region Constants

const DASHBOARD_TABS = {
  mainDashboard: {
    id: 'mainDashboard',
    tab: 'main_dashboard',
    content: <Main />
  },
  previousWeek: {
    id: 'previousWeek',
    tab: 'previous_week',
    content: <PreviousWeek />
  },
  currentState: {
    id: 'currentState',
    tab: 'current_state',
    content: <CurrentState />
  },
  nextWeek: {
    id: 'nextWeek',
    tab: 'next_week',
    content: <NextWeek />
  },
  dailyObservatory: {
    id: 'dailyObservatory',
    tab: 'daily_observatory',
    content: <DailyObservatory />
  },
  lastMonth: {
    id: 'lastMonth',
    tab: 'last_month',
    content: <LastMonth />
  },
  monthlyFluctuations: {
    id: 'monthlyFluctuations',
    tab: 'monthly_fluctuations',
    content: <MonthlyFluctuations />
  },
  yearAnalysis: {
    id: 'yearAnalysis',
    tab: 'year_analysis',
    content: <YearAnalysis />
  },
  linearFitness: {
    id: 'linearFitness',
    tab: 'linear_fitness',
    content: <LinearFitness />
  }
} as const

export const DashboardTabsTypeArray = Object.values(DASHBOARD_TABS).map(tab => tab.tab)

//#endregion Constants

export const Dashboard = () => {
  const { tab } = useParams(),
        i18n = useTranslation(),
        navigate = useNavigate(),
        [searchParams] = useSearchParams(),
        tabType = useMemo(() => tab ? formatSnakeToCamelCase(tab) : undefined, [tab]),
        content = useMemo(() => {
          return tabType ? DASHBOARD_TABS[tabType].content : undefined
        }, [tabType])

  //#region Private Methods

  const onTabChange = (event: SyntheticEvent, value: string) => {
    navigate({
      pathname: getPaths.brands.dashboard.tab(formatCamelToSnakeCase(value)),
      search: searchParams.toString()
    })
  }

  //#endregion Private Methods

  if(!tabType) {
    return <Navigate to={{pathname: getPaths.brands.dashboard.tab(formatCamelToSnakeCase(DASHBOARD_TABS.mainDashboard.tab)), search: searchParams.toString()}} replace/>
  }

  return (
    <GenericFilterProvider>
      <Box
        display={'flex'}
        flexDirection={'column'}
        gap={0}
      >
        <h1>
          {i18n.t('Common:Dashboard')}
        </h1>
        <Box
          display={'flex'}
          flexDirection={'column'}
          gap={2}
        >
          <Tabs
            variant='scrollable'
            onChange={onTabChange}
            value={tabType}
            className={styles.tabs}
            scrollButtons='auto'
          >
            {Object.values(DASHBOARD_TABS).map(tab =>
              <Tab
                key={tab.id}
                value={tab.id}
                label={i18n.t(`Dashboard:${formatTitle(tab.id)}`)}
              />
            )}
          </Tabs>
          <DashboardFilters />
          {content}
        </Box>
      </Box>
    </GenericFilterProvider>
  )
}
