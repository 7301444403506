import { CustomGridColDef } from "../components/Table/RemoteTable";
import { ValueType } from "../utils/formatter";
import { TableColorType } from "../utils/table";

import BaseAvisoModel from "./base/BaseAvisoModel";

//#region Constants

const TABLE_NAME = 'avisoSemanaAnteriorRoturaConsecutiva';

//#endregion Constants

export default class AvisoSemanaAnteriorRoturaConsecutivaModel extends BaseAvisoModel{
  
  //#region Public Properties
  
  /* eslint-disable @typescript-eslint/no-inferrable-types*/
  public selloutMedio: number = 0;
  public vendasPerdidas: number = 0;
  public valorPerdido?: number;
  
  //#endregion Public Properties

  //#region Static Methods

  public static getColumnsConfig = (): { [key in keyof AvisoSemanaAnteriorRoturaConsecutivaModel]?: CustomGridColDef<AvisoSemanaAnteriorRoturaConsecutivaModel>} => ({
    // eslint-disable-next-line i18next/no-literal-string
    ...BaseAvisoModel.getColumnsDefinition(['data','vendedor','storeName','subFamilia','descArtigo','stock','sellout','fornecimento']),
    roturasConsecutivas: {
      formatType: ValueType.NUMBER,
      // eslint-disable-next-line i18next/no-literal-string
      headerResourceName: 'DiasRotura',
      cellConditionalFormat: {
        condition: (value: unknown) => value as number > 2,
        color: TableColorType.RED
      }
    },
    selloutMedio: {
      formatType: ValueType.NUMBER,
      cellConditionalFormat: {
        condition: (value: unknown) => value as number > 2,
        color: TableColorType.ORANGE
      }
    },
    vendasPerdidas: {
      formatType: ValueType.NUMBER,
      // eslint-disable-next-line i18next/no-literal-string
      headerResourceName: 'VendasPerdidasEstimadas'
    },
    // eslint-disable-next-line i18next/no-literal-string
    ...BaseAvisoModel.getColumnsDefinition(['percentagemDiasSemVendas60', 'percentagemRoturas60']),
    valorPerdido: {
      formatType: ValueType.NUMBER,
      // eslint-disable-next-line i18next/no-literal-string
      headerResourceName: 'ValorPerdidoEstimado'
    }
  });

  public static getCustomFilters = (): { [key: string]: string} => ({ table: TABLE_NAME});

  //#endregion Static Methods

}
