 
 
import React from 'react'
import { useTranslation } from 'react-i18next'

import { QuestionTypeAPI } from '../../api/types'
import { WizardConsts } from '../../Constants'

import styles from './QuestionAndValidations.module.scss'
import { Validation } from './Validation'
import { ValidationType } from './Validation'


const { QUESTION_TYPES } = WizardConsts

type QuestionAndValidationsProps = {
  question: QuestionTypeAPI
  validations: ValidationType[]
}


export const QuestionAndValidations = ({
  question,
  validations
}: QuestionAndValidationsProps) => {
  const i18n = useTranslation()


  return (
    <div className={styles.questionValidations}>
      <div className={styles.id}>
        <span className={styles.idText}>
          {question.order+1}
        </span>
      </div>
      <div className={styles.questionValidationInfo}>
        <div className={styles.questionInfo}>
          <div className={`${styles.imgContainer} ${!question.image && styles.noImg}`}>
            {question.image ? (
              <img
                src={question.image?.toString()}
                alt='questionImage'
                draggable={false}
              />
            ) : (
              <h5 className={styles.noPhoto}>
                {i18n.t('Common:WithoutPhoto')}
              </h5>
            )}
          </div>
          <div className={styles.questionTable}>
            <div className={styles.questionTableTop}>
              <div className={styles.questionTitle}>
                <h4>
                  {i18n.t('Common:Question')}
                </h4>
                <h5>
                  {question.text}
                </h5>
              </div>
              <div className={styles.questionInfoSection}>
                <div className={styles.questionInfo}>
                  <div className={styles.title}>
                    <h4>
                      {i18n.t('Mission:QuestionType')}
                    </h4>
                  </div>
                  <div className={styles.info}>
                    <h5>
                      {QUESTION_TYPES[question.questionType].label}
                    </h5>
                  </div>
                </div>
                <div className={styles.questionInfo}>
                  <div className={styles.title}>
                    <h4>
                      {i18n.t('Mission:ExpectedAnswer')}
                    </h4>
                  </div>
                  <div className={styles.info}>
                    <h5 className={question.expectedAnswer ? undefined : styles.noInfo}>
                      {question.expectedAnswer ? question.expectedAnswer : i18n.t('MissionWizard:NoExpectedAnswer')}
                    </h5>
                  </div>
                </div>
                <div className={styles.questionInfo}>
                  <div className={styles.title}>
                    <h4>
                      {i18n.t('MissionWizard:NeedsValidationPhoto')}
                    </h4>
                  </div>
                  <div className={styles.info}>
                    <h5>
                      {question.requiresPhotoValidation ? i18n.t('Common:Yes').toUpperCase() : i18n.t('Common:No').toUpperCase()}
                    </h5>
                  </div>
                </div>
                <div className={styles.questionInfo}>
                  <div className={styles.title}>
                    <h4>
                      {i18n.t('Mission:ShortName')}
                    </h4>
                  </div>
                  <div className={styles.info}>
                    <h5 className={question.shortName ? undefined : styles.noInfo}>
                      {question.shortName ? question.shortName : i18n.t('MissionWizard:NoShortName')}
                    </h5>
                  </div>
                </div>
                <div className={styles.questionInfo}>
                  <div className={styles.title}>
                    <h4>
                      {i18n.t('MissionWizard:ToValidate')}
                    </h4>
                  </div>
                  <div className={styles.info}>
                    <h5>
                      {question.toValidate ? i18n.t('Common:Yes').toUpperCase() : i18n.t('Common:No').toUpperCase()}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            {QUESTION_TYPES[question.questionType].hasOptions && (
              <div className={styles.answerOptionsTable}>
                <h4>
                  {i18n.t('Mission:AnswerOptions')}
                </h4>
                <div>
                  {question.answerOptions.map((ao, i) => (
                    <div 
                      key={`question_${question.id}_answerOption_${ao.order}`}
                      className={styles.answerOption}
                    >
                      <div className={styles.choiceId}>
                        {i+1}
                      </div>
                      <h5>
                        {ao.text}
                      </h5>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {question.toValidate && (
          <div className={styles.validations}>
            <div className={styles.validation}>
              <span className={styles.idText}>
                {validations.length === 1 ? i18n.t('Common:Validation') : i18n.t('Common:Validations')}
              </span>
            </div>
            <div className={styles.validationsInfo}>
              {validations.map((validation, i) => (
                <Validation
                  key={`question_${question.id}_validation_${i}`}
                  id={i+1}
                  validation={validation}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
