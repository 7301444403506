import { CustomGridColDef, GridColSortType } from "../components/Table/RemoteTable";
import { ValueType } from "../utils/formatter";

//#region Constants

const TABLE_NAME = 'resumoMeses';

//#endregion Constants


export default class ResumoMesesModel {
  //#region Public Properties

  /* eslint-disable @typescript-eslint/no-inferrable-types*/
  data: Date = new Date();
  ean: number = 0;
  descArtigo: string = '';
  subFamilia?: string;
  store: number = 0;
  storeName: string = '';
  vendedor: string = '';
  fluxo: string = '';
  rotura1DiasAntes: number = 0;
  unidadesEncomendadas: number = 0;
  sellout1DiasAntes: number = 0;
  stock1DiasAntes: number = 0;
  diasParaEntrega?: number;
  valorPerdido: number = 0;
  presStock1DiasAntes: number = 0;
  selloutMedio: number = 0;
  numeroFornecimento: number = 0;
  stockMedio: number = 0;
  conversao: number = 0;

  //#endregion Public Properties

  //#region Static Methods

  public static getColumnsConfig = (): { [key in keyof ResumoMesesModel]?: CustomGridColDef<ResumoMesesModel>} => ({
    vendedor: {
      formatType: ValueType.STRING,
      defaultSorted: GridColSortType.DESC
    },
    storeName: {
      formatType: ValueType.STRING
    },
    descArtigo: {
      formatType: ValueType.STRING
    },
    sellout1DiasAntes: {
      formatType: ValueType.NUMBER,
      // eslint-disable-next-line i18next/no-literal-string
      headerResourceName: 'sellout'
    },
    stock1DiasAntes: {
      formatType: ValueType.NUMBER,
      // eslint-disable-next-line i18next/no-literal-string
      headerResourceName: 'stock'
    },
    rotura1DiasAntes: {
      formatType: ValueType.NUMBER,
      // eslint-disable-next-line i18next/no-literal-string
      headerResourceName: 'rotura'
    },
    selloutMedio: {
      formatType: ValueType.NUMBER,
    },
    presStock1DiasAntes: {
      formatType: ValueType.NUMBER,
      // eslint-disable-next-line i18next/no-literal-string
      headerResourceName: 'presStock'
    },
    subFamilia: {
      formatType: ValueType.STRING
    },
    valorPerdido: {
      formatType: ValueType.NUMBER
    }
  })

  public static getCustomFilters = (): { [key: string]: string} => ({ table: TABLE_NAME});

  public static getTableName = () => TABLE_NAME;

  //#endregion Static Methods
}
