import React, { MouseEventHandler } from 'react'

import CheckMark from '../../assets/icons/checkMark.svg'

import styles from './Checkbox.module.scss'

type CheckboxProps = {
  handler: MouseEventHandler<HTMLButtonElement>
  checked: boolean
  text?: string
  disabled?: boolean
  fullHeight?: boolean
}

export const Checkbox = ({
  handler,
  checked,
  text,
  disabled,
  fullHeight
}: CheckboxProps) => {
  return (
    <div className={`${styles.checkboxContainer} ${disabled && styles.disabled}`}>
      <button
        className={`
          ${styles.checkbox}
          ${checked && styles.checked}
          ${disabled && styles.disabled}
          ${fullHeight && styles.fullHeight}
        `}
        /* eslint-disable-next-line @typescript-eslint/no-empty-function */
        onClick={ disabled ? () => {} : handler }
      >
        <img
          src={CheckMark}
          alt='V'
          className={`${styles.checkMark} ${checked && styles.checked}`}
          draggable={false}
        />
      </button>
      <h5 className={`${disabled && styles.textDisabled}`}>
        {text}
      </h5>
    </div>
  )
}
