import { Grid } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

//#region Enums

export enum WrapperWidth {
  FULL = '100%',
  HALF = '50%',
  ONE_THIRD = '33%',
  ONE_FOURTH = '25%'
}

export enum WrapperHeight {
  TINY = '5rem',
  SMALL = '10rem',
  MEDIUM = '20rem',
  HIGH = '40rem'
}

export enum WrapperType {
  GRID = 'grid'
}

//#endregion Enums

//#region Interfaces

export interface WrapperConfig {
  type: WrapperType;
  width?: WrapperWidth;
  height?: WrapperHeight;
  genKey?: boolean;
}

//#endregion Interfaces

//#region Methods

const genKey = () => uuidv4();

export const wrapContent = (content: JSX.Element, config?: WrapperConfig): JSX.Element => {
  const { type, width, height } = config ?? {};

  switch(type) {
    case WrapperType.GRID:
      return (
        <Grid item width={width} height={height} flexGrow={1} key={config?.genKey ? `grid-${genKey()}` : undefined}>
          {content}
        </Grid>
      );
    default:
      return content;
  }
}

//#endregion Methods
