import { useCallback, useMemo } from "react";

import { getDashboardChart, getDashboardKpi, ServerResponseType } from "../../api";
import i18n from "../../localization";
import BaseTableModel from "../../models/base/BaseTableModel";
import DadosAnoModel from "../../models/DadosAnoModel";
import DadosUltimos30DiasModel from "../../models/DadosUltimos30DiasModel";
import ResumoMesesModel from "../../models/ResumoMesesModel";
import { formatTitle, roundToDecimalPlaces, ValueType } from "../../utils/formatter";
import { TableColorType } from "../../utils/table";
import { WrapperWidth, WrapperType, WrapperConfig } from "../../utils/wrapper";
import { Chart } from "../Chart/Chart";
import { ChartAxisType, ChartConfig, ChartGaugeInterval, ChartRawData, ChartType } from "../Chart/ChartDefinition";
import { DashboardKpiFilters, getBaseKpiConfig, KpiAggregatorType, KpiCardType, KpiQueryConfig } from "../KpiCard/KpiCard";

//#region Enums

export enum DashboardChartType {
  //#region Daily Observatory Tab
  OUTAGE_RISK_3_DAYS = 'outageRisk3Days',
  OUTAGE_RISK_5_DAYS = 'outageRisk5Days',
  //#endregion Daily Observatory Tab

  //#region Last Month Tab
  SELLOUT = 'sellout',
  STOCK = 'stock',
  IN_SUPPLY = 'inSupply',
  IN_OUTAGE = 'inOutage',
  LOST_SALES = 'lostSales',
  WEEKLY_FLUCTUATION = 'weeklyFluctuation',
  WEEKLY_OUTAGES = 'weeklyOutages',
  //#endregion Last Month Tab

  //#region Monthly Fluctuations Tab
  MONTHLY_SELLOUT = 'monthlySellout',
  MONTHLY_STOCK = 'monthlyStock',
  MONTHLY_OUTAGES = 'monthlyOutages',
  MONTHLY_LOST_VALUE = 'monthlyLostValue',
  //#endregion Monthly Fluctuations Tab

  //#region Current Year Tab
  SELLOUT_YEAR = 'selloutYear',
  STOCK_YEAR = 'stockYear',
  SUPPLY_YEAR = 'supplyYear',
  OUTAGES_YEAR = 'outagesYear'
  //#endregion Current Year Tab
}

enum CustomGroupBy {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year'
}

//#endregion Enums

//#region Interfaces

interface DashboardChartExternalConfig {
  type: DashboardChartType;
  filters?: boolean | string[];
  wrapper?: WrapperConfig;
}

interface DashboardChartInternalConfig {
  chartConfig: ChartConfig;
  queryParams?: DashboardChartQueryConfig;
}

//#endregion Interfaces

//#region Classes

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class DashboardChartQueryConfig<T = any> extends KpiQueryConfig<T> {
  groupBy: keyof T | CustomGroupBy;
  extraColumns?: (keyof T)[];

  constructor(table: Partial<BaseTableModel>, column: keyof T, aggregator: KpiAggregatorType, groupBy: keyof T | CustomGroupBy, extraColumns?: (keyof T)[]) {
    super(table, column, aggregator);
    this.groupBy = groupBy;
    this.extraColumns = extraColumns;
  }
}

//#endregion Classes

//#region Types

export type DashboardChartFilters = DashboardChartQueryConfig | { table: string }

//#endregion Types

//#region Auxiliar Methods

const gaugeFetchMethod = (kpi: KpiCardType) => {
  const baseParams = getBaseKpiConfig(kpi);
  return (filters?: object) => {
    return new Promise<ServerResponseType<ChartRawData[]>>(resolve => {
      getDashboardKpi({...baseParams, ...filters} as DashboardKpiFilters)
        .then(({data, ...other}) => {
          resolve({
            ...other,
            data: [{
              label: '',
              value: roundToDecimalPlaces(data as number)
            }] as ChartRawData[]
          } as ServerResponseType<ChartRawData[]>);
        })
    });
  };
}

//#endregion Auxiliar Methods

//#region Constants

const RISK_INTERVALS: ChartGaugeInterval[] = [
    {
      interval: [0, 0.6],
      color: TableColorType.GREEN,
      label: i18n.t('Dashboard:SafeRisk')
    },
    {
      interval: [0.6, 1.1],
      color: TableColorType.YELLOW,
      label: i18n.t('Dashboard:ModerateRisk')
    },
    {
      interval: [1.1, 5],
      color: TableColorType.RED,
      label: i18n.t('Dashboard:HighRisk')
    }
  ],
  CONFIGS: {[key in DashboardChartType]: DashboardChartInternalConfig} = {
    //#region Daily Observatory Tab
    [DashboardChartType.OUTAGE_RISK_3_DAYS]: {
      chartConfig: {
        type: ChartType.GAUGE,
        gaugeIntervals: RISK_INTERVALS,
        fetchMethod: gaugeFetchMethod(KpiCardType.OUTAGE_RISK_3_DAYS)
      }
    },
    [DashboardChartType.OUTAGE_RISK_5_DAYS]: {
      chartConfig: {
        type: ChartType.GAUGE,
        gaugeIntervals: RISK_INTERVALS,
        fetchMethod: gaugeFetchMethod(KpiCardType.OUTAGE_RISK_5_DAYS)
      }
    },
    //#endregion Daily Observatory Tab

    //#region Last Month Tab
    [DashboardChartType.SELLOUT]: {
      queryParams: new DashboardChartQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'sellout', KpiAggregatorType.SUMMATORY, 'data'),
      chartConfig: {
        type: ChartType.LINE,
        axisType: ChartAxisType.TIME,
        yAxisValueType: ValueType.NUMBER,
        showSeriesLabel: true,
        showLinearRegression: true
      }
    },
    [DashboardChartType.STOCK]: {
      queryParams: new DashboardChartQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'stock', KpiAggregatorType.SUMMATORY, 'data'),
      chartConfig: {
        type: ChartType.LINE,
        axisType: ChartAxisType.TIME,
        yAxisValueType: ValueType.NUMBER,
        showLinearRegression: true
      }
    },
    [DashboardChartType.IN_SUPPLY]: {
      queryParams: new DashboardChartQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'fornecimento', KpiAggregatorType.SUMMATORY, 'data'),
      chartConfig: {
        type: ChartType.LINE,
        axisType: ChartAxisType.TIME,
        yAxisValueType: ValueType.NUMBER,
        showLinearRegression: true
      }
    },
    [DashboardChartType.IN_OUTAGE]: {
      queryParams: new DashboardChartQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'rotura', KpiAggregatorType.SUMMATORY, 'data'),
      chartConfig: {
        type: ChartType.BAR,
        axisType: ChartAxisType.TIME,
        yAxisValueType: ValueType.NUMBER,
        showSeriesLabel: true,
        showLinearRegression: true
      }
    },
    [DashboardChartType.LOST_SALES]: {
      queryParams: new DashboardChartQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'vendasPerdidas', KpiAggregatorType.SUMMATORY, 'data'),
      chartConfig: {
        type: ChartType.LINE,
        axisType: ChartAxisType.TIME,
        yAxisValueType: ValueType.NUMBER,
        areaFill: true
      }
    },
    [DashboardChartType.WEEKLY_FLUCTUATION]: {
      queryParams: new DashboardChartQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'sellout', KpiAggregatorType.SUMMATORY, CustomGroupBy.WEEK, ['stock']),
      chartConfig: {
        type: ChartType.LINE,
        axisType: ChartAxisType.TIME,
        yAxisValueType: ValueType.NUMBER,
        xAxisValueType: ValueType.WEEK_INTERVAL,
        multipleYAxis: [i18n.t('Dashboard:Chart:Sellout'), i18n.t('Dashboard:Chart:Stock')]
      }
    },
    [DashboardChartType.WEEKLY_OUTAGES]: {
      queryParams: new DashboardChartQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'rotura', KpiAggregatorType.AVERAGE, CustomGroupBy.WEEK),
      chartConfig: {
        type: ChartType.LINE,
        axisType: ChartAxisType.TIME,
        yAxisValueType: ValueType.PERCENTAGE
      }
    },
    //#endregion Last Month Tab

    //#region Monthly Fluctuations Tab
    [DashboardChartType.MONTHLY_SELLOUT]: {
      queryParams: new DashboardChartQueryConfig<ResumoMesesModel>(ResumoMesesModel, 'sellout1DiasAntes', KpiAggregatorType.SUMMATORY, CustomGroupBy.MONTH),
      chartConfig: {
        type: ChartType.LINE,
        axisType: ChartAxisType.TIME,
        yAxisValueType: ValueType.NUMBER,
        showSeriesLabel: true,
        areaFill: true
      }
    },
    [DashboardChartType.MONTHLY_STOCK]: {
      queryParams: new DashboardChartQueryConfig<ResumoMesesModel>(ResumoMesesModel, 'stock1DiasAntes', KpiAggregatorType.SUMMATORY, CustomGroupBy.MONTH),
      chartConfig: {
        type: ChartType.LINE,
        axisType: ChartAxisType.TIME,
        yAxisValueType: ValueType.NUMBER,
        showSeriesLabel: true,
        areaFill: true
      }
    },
    [DashboardChartType.MONTHLY_OUTAGES]: {
      queryParams: new DashboardChartQueryConfig<ResumoMesesModel>(ResumoMesesModel, 'rotura1DiasAntes', KpiAggregatorType.SUMMATORY, CustomGroupBy.MONTH),
      chartConfig: {
        type: ChartType.LINE,
        axisType: ChartAxisType.TIME,
        yAxisValueType: ValueType.NUMBER,
        showSeriesLabel: true,
        areaFill: true
      }
    },
    [DashboardChartType.MONTHLY_LOST_VALUE]: {
      queryParams: new DashboardChartQueryConfig<ResumoMesesModel>(ResumoMesesModel, 'valorPerdido', KpiAggregatorType.SUMMATORY, CustomGroupBy.MONTH),
      chartConfig: {
        type: ChartType.LINE,
        axisType: ChartAxisType.TIME,
        yAxisValueType: ValueType.NUMBER,
        showSeriesLabel: true,
        areaFill: true
      }
    },
    //#endregion Monthly Fluctuations Tab
  
    //#region Current Year Tab
    [DashboardChartType.SELLOUT_YEAR]: {
      queryParams: new DashboardChartQueryConfig<DadosAnoModel>(DadosAnoModel, 'sellout', KpiAggregatorType.SUMMATORY, CustomGroupBy.DAY),
      chartConfig: {
        type: ChartType.LINE,
        axisType: ChartAxisType.TIME,
        yAxisValueType: ValueType.NUMBER,
        showLinearRegression: true
      }
    },
    [DashboardChartType.STOCK_YEAR]: {
      queryParams: new DashboardChartQueryConfig<DadosAnoModel>(DadosAnoModel, 'stock', KpiAggregatorType.SUMMATORY, CustomGroupBy.DAY),
      chartConfig: {
        type: ChartType.SCATTER,
        axisType: ChartAxisType.TIME,
        yAxisValueType: ValueType.NUMBER,
        showLinearRegression: true
      }
    },
    [DashboardChartType.SUPPLY_YEAR]: {
      queryParams: new DashboardChartQueryConfig<DadosAnoModel>(DadosAnoModel, 'fornecimento', KpiAggregatorType.SUMMATORY, CustomGroupBy.DAY),
      chartConfig: {
        type: ChartType.SCATTER,
        axisType: ChartAxisType.TIME,
        yAxisValueType: ValueType.NUMBER
      }
    },
    [DashboardChartType.OUTAGES_YEAR]: {
      queryParams: new DashboardChartQueryConfig<DadosAnoModel>(DadosAnoModel, 'rotura', KpiAggregatorType.SUMMATORY, CustomGroupBy.DAY),
      chartConfig: {
        type: ChartType.BAR,
        axisType: ChartAxisType.TIME,
        yAxisValueType: ValueType.NUMBER,
        showLinearRegression: true,
        showSeriesLabel: true
      }
    }
    //#endregion Current Year Tab
  },
  DASHBOARD = 'Dashboard',
  ALL_TOOLBOX = 'All'

//#endregion Constants

export const DashboardChart = ({type, filters, wrapper}: DashboardChartExternalConfig) => {
  const { chartConfig, queryParams } = useMemo(() => CONFIGS[type], [type]),
        title = useMemo(() => i18n.t(`Dashboard:Chart:${formatTitle(type)}`), [type]);

  const defaultFetch = useCallback((filters?: object) => {
    return new Promise<ServerResponseType<ChartRawData[]>>(resolve => {
      getDashboardChart({...filters, ...queryParams?.extract()} as DashboardChartFilters)
        .then(({data, ...other}) => {
          resolve({
            ...other,
            data: data?.map(({value, ...other}: ChartRawData) => ({
              ...other,
              value: (() => {
                switch (chartConfig.yAxisValueType) {
                  case ValueType.PERCENTAGE:
                    return value as number * 100;
                  default:
                    return value;
                }
              })()
            }))
          } as ServerResponseType<ChartRawData[]>);
        })
    });
  }, [queryParams, chartConfig])

  return <Chart
            {...chartConfig}
            title={title}
            fetchMethod={chartConfig.fetchMethod ?? defaultFetch}
            bindGenericFilters={filters ?? true}
            dataLabel={title}
            translateSeries={queryParams?.extraColumns ? DASHBOARD : false}
            toolbox={ALL_TOOLBOX}
            wrapper={{
              type: WrapperType.GRID,
              width: WrapperWidth.FULL,
              ...wrapper
            }}/>;
}
