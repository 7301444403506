import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import en from './en.json'
import es from './es.json'
import fr from './fr.json'
import pt from './pt.json'

/* eslint-disable i18next/no-literal-string */
const fallbacklanguage = 'en'
const defaultNS = 'common'
/* eslint-enable i18next/no-literal-string */

const locales = {
  pt, es, en, fr
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: locales,
    fallbackLng: fallbacklanguage,
    ns: Object.keys(locales),
    defaultNS: defaultNS,
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  })


export default i18n
