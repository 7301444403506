import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import LogoIcon from '../../../assets/icons/logo.svg'
import { useUser } from '../../../contexts/User'
import { Button } from '../../../elements/Button'
import { getPaths } from '../../../routing/routes'

import styles from './Header.module.scss'


export const Header = () => {
  const i18n = useTranslation()
  const navigate = useNavigate()
  const { hasPermissions, isLoggedIn, handleLogout } = useUser()


  return (
    <header className={styles.header}>
      <button 
        onClick={() => navigate(getPaths.brands.missions.ongoing)}
        className={styles.logoContainer}
      >
        <img
          src={LogoIcon}
          alt='icon'
          className={styles.logo}
          draggable={false}
        />
      </button>
      {isLoggedIn && (
        <div className={styles.links}>
          {/* eslint-disable-next-line i18next/no-literal-string */}
          {hasPermissions('missions_viewer') && (
            <Button
              title={i18n.t('Common:Missions')}
              handler={() => navigate(getPaths.brands.missions.ongoing)}
              variant='link'
            />
          )}
          {/* eslint-disable-next-line i18next/no-literal-string */}
          {hasPermissions('dashboard_viewer') && (
            <Button
              title={i18n.t('Common:Dashboard')}
              handler={() => navigate(getPaths.brands.dashboard.tab('main_dashboard'))}
              variant='link'
            />
          )}
          <Button
            title={i18n.t('Header:EndSession')}
            handler={handleLogout}
            variant='link'
          />
        </div>
      )}
    </header>
  )
}
