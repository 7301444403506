import { Box, Button, Checkbox, CircularProgress, FormControl, FormControlLabel, InputAdornment, InputLabel, ListItem, ListItemText, ListSubheader, MenuItem, MenuProps, Select, SelectChangeEvent, TextField } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";
import { ChangeEvent, MouseEvent, SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { ServerResponseType } from "../../api";
import { ErrorAlert } from "../../components/ErrorAlert/ErrorAlert";
import { useGenericFilterContext } from "../../contexts/GenericFilterContext";
import i18n from "../../localization";
import { extractConditionalObject } from "../../utils/formatter";
import { containsText, isEmptyOrWhiteSpace, isMobile, objectsAreEqual } from "../../utils/validator";

import styles from "./Dropdown.module.scss";

//#region Enums

export enum RemoteDropdownType {
  FILTER = 'filter',
  SELECTION = 'selection'
}

//#endregion Enums

//#region Constants

const SELECT_AS_STRING = 'select',
      LABEL_AS_STRING = 'label',
      IS_MOBILE = isMobile();

const MENU_PROPS: Partial<MenuProps> = {
  autoFocus: false,
  slotProps: {
    paper: {
      style: {
        maxHeight: '25rem'
      }
    }
  },
  MenuListProps: {
    style: {
      padding: 0
    }
  }
};

//#endregion Constants

//#region Interfaces

export interface RemoteDropdownData {
  value: unknown;
  label?: string;
}

interface RemoteDropdownConfig {
  label: string;
  multiple?: boolean;
  type? : RemoteDropdownType;
  filterField?: string;
  bindGenericFilters?: boolean | string[];
  fetchMethod?: (filters?: object) => Promise<ServerResponseType<RemoteDropdownData[]>>;
  disabled?: boolean;
}

//#endregion Interfaces

export const RemoteDropdown = (config: RemoteDropdownConfig) => {
  const mobileRef = useRef(null as null | HTMLSelectElement),
        {genericFilters, updateGenericFilter} = useGenericFilterContext(),
        [internalFilters, setInternalFilters] = useState(() => {
          const filters = {...extractConditionalObject(genericFilters, config.bindGenericFilters)};
          if(config.filterField) {
            delete filters[config.filterField];
          }
          return filters;
        }),
        [show, setShow] = useState(false),
        [selection, setSelection] = useState(() => {
          const empty: unknown = config.multiple ? [] : '';
          if(config.filterField) {
            const value = genericFilters[config.filterField] ?? empty;
            if(config.multiple && !Array.isArray(value)) {
              return [value];
            } else if (!config.multiple && Array.isArray(value)) {
              return value[0];
            }
            return value;
          }
          return empty;
        }),
        [tempSelection, setTempSelection] = useState([] as unknown[]),
        [error, setError] = useState(false),
        [data, setData] = useState(() => {
          return isEmptyOrWhiteSpace(selection)
                    ? [] as RemoteDropdownData[]
                    : Array.isArray(selection)
                      ? selection.map((value: unknown) => ({value} as RemoteDropdownData))
                      : [{value: selection} as RemoteDropdownData];
        }),
        [searchText, setSearchText] = useState(undefined as string | undefined),
        [isFilter, isSelection] = useMemo(() => [config.type === RemoteDropdownType.FILTER, !config.type || config.type === RemoteDropdownType.SELECTION], [config.type]),
        [pending, setPending] = useState(isSelection ? true : false),
        [isOutdated, setIsOutdated] = useState(isFilter ? true : false),
        formattedData = useMemo(() => {
          const filteredData = data.filter(({value, label}: RemoteDropdownData) => {
                                  return searchText ? containsText(label ?? value as string, searchText) : true;
                                });
          return isSelection
                  ? filteredData
                  : filteredData
                    .sort((a: RemoteDropdownData,b: RemoteDropdownData) => {
                      if(a.label && b.label) {
                        return (a.label as string) < (b.label as string) ? -1 : 1;  
                      }
                      return (a.value as string | number) < (b.value as string | number) ? -1 : 1;
                    })
                    .sort(({value}: RemoteDropdownData) => {
                      if(config.multiple) {
                        return ((selection as unknown[]).includes(value)) ? -1 : 1;
                      }
                      return 0;
                    });
        } ,[data, selection, isSelection, searchText, config.multiple]);

  //#region Private Methods

  //#region Remote

  const fetchRemote = useCallback(() => {
    setError(false);
    setPending(true);
    config.fetchMethod?.(internalFilters)
      .then(({data, success}: ServerResponseType<RemoteDropdownData[]>) => {
        if(success) {
          setIsOutdated(false);
        } else {
          setError(true);
          setShow(false);
        }
        
        setData(data ?? []);
      })
      .finally(() => {
        setPending(false);
      })
  }, [internalFilters, config])

  //#endregion Remote

  //#region Updaters

  const onMobileChange = ({target}: SelectChangeEvent<unknown>) => {
    const {options} = target as HTMLSelectElement;
    if(config.multiple) {
      setSelection([...options].filter(({selected}: HTMLOptionElement) => selected).map(({value}: HTMLOptionElement) => value));
    } else {
      setSelection([...options].find(({selected}: HTMLOptionElement) => selected)?.value);
    }
  }

  const updateSelection = ({target}: SelectChangeEvent<unknown>) => {
    setSelection(typeof target.value === 'string' ? target.value?.split(',') : target.value);
  }

  const updateTempSelection = ({target}: SyntheticEvent, checked: boolean) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { value } = (target as any);

    if(checked) {
      setTempSelection((selection: unknown[]) => [...selection, value]);
    } else {
      setTempSelection((selection: unknown[]) => selection.filter(item => item !== value));
    }
  }

  const onAddFilterClick = () => {
    setSelection(tempSelection);
    setShow(false);
  }

  const clearSelection = useCallback((event: MouseEvent) => {
    setSelection(() => config.multiple ? [] : '');
    event.stopPropagation();
    event.preventDefault();
  }, [config.multiple, setSelection])

  //#endregion Updaters

  //#region Formatters

  const displaySelection = selection => {
    switch (typeof selection) {
      case 'string':
        return selection;
      default:
        return selection.length > 1 ? `${selection.length} ${i18n.t('Common:Selections').toLowerCase()}` : selection[0];
    }
  }

  //#endregion Formatters

  //#region Search Box

  const onSearchKeyDown = event => {
    if(event.key !== 'Escape') {
      event.stopPropagation();
    }
  }

  const updateSearchText = ({target}: ChangeEvent<HTMLInputElement>) => {
    setSearchText(target.value);
  }

  //#endregion Search Box

  //#region Visibility

  const onOpen = () => {
    if(isOutdated) {
      fetchRemote();
    }

    setShow(true);

    if(isFilter && config.multiple) {
      setTempSelection(selection as unknown[]);
    }
  }

  const onClose = () => {
    setSearchText(undefined);
    setShow(false);
  }

  //#endregion Visibility

  //#endregion Private Methods
  
  //#region Effects

  useEffect(() => {
    if(isSelection) {
      fetchRemote();
    }
  }, [config.fetchMethod, isSelection, fetchRemote])

  useEffect(() => {
    if(isFilter && config.filterField) {
      updateGenericFilter(config.filterField, selection)
    }

    // Disable eslint for updateGenericFilter
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config.filterField, selection, isFilter])

  useEffect(() => {
    if(isFilter && config.filterField && isEmptyOrWhiteSpace(genericFilters[config.filterField])) {
      setSelection(() => config.multiple ? [] : '');
    }
  }, [genericFilters, isFilter, config.filterField, config.multiple])

  useEffect(() => {
    if(config.bindGenericFilters) {
      const newInternalFilter = {
        ...extractConditionalObject(genericFilters, config.bindGenericFilters)
      };

      if(isFilter && config.filterField) {
        delete newInternalFilter[config.filterField];
      }

      setInternalFilters(prevInternalFilters => {
        if(!objectsAreEqual(prevInternalFilters, newInternalFilter)) {
          setIsOutdated(true);
        }
        return newInternalFilter;
      });
    }
  }, [genericFilters, isFilter, config.filterField, config.bindGenericFilters])

  //#endregion Effects

  //#region Partial Elements

  const endAdornment = (
      <InputAdornment position='end'>
        <button className={styles.clearButton} onClick={clearSelection} disabled={config.disabled}><ClearIcon className={styles.clearIcon}/></button>
      </InputAdornment>
    ),
    desktopStructure = (
      <Select<unknown>
        open={show}
        labelId={`${config.label.toLowerCase()}-${SELECT_AS_STRING}-${LABEL_AS_STRING}`}
        id={`${config.label.toLowerCase()}-${SELECT_AS_STRING}`}
        label={config.label}
        multiple={config.multiple}
        value={selection}
        renderValue={displaySelection}
        onOpen={onOpen}
        onClose={onClose}
        onChange={isSelection ? updateSelection : undefined}
        MenuProps={MENU_PROPS}
        className={isFilter && !isEmptyOrWhiteSpace(selection) ? styles.filled : undefined}
        endAdornment={isEmptyOrWhiteSpace(selection) ? undefined : endAdornment}>
        <ListSubheader className={styles.searcher}>
          <TextField
            placeholder={i18n.t('Common:SearchOnList')}
            size='small'
            autoFocus
            fullWidth
            onChange={updateSearchText}
            onKeyDown={onSearchKeyDown}/>
        </ListSubheader>
        {
          pending
            ? <Box display={'flex'} justifyContent={'center'} padding={2}>
              <CircularProgress />
            </Box>
            : formattedData
              .map(({value, label}: RemoteDropdownData) => (
                <MenuItem key={`${config.label.toLowerCase()}-value-${value}`} className={styles.item} value={value as string | number}>
                  {
                    config.multiple
                      ? (isFilter
                        ? <FormControlLabel
                            onChange={updateTempSelection}
                            className={styles.control}
                            control={<Checkbox value={value} checked={tempSelection.includes(value)}/>}
                            label={<ListItemText primary={label ?? value as string}/>}/>
                        : (
                            <ListItem className={styles.control}>
                              <Checkbox checked={(selection as unknown[]).includes(value)}/>
                              <ListItemText primary={label ?? value as string}/>
                            </ListItem>
                          )
                        )
                      : <ListItemText primary={label ?? value as string}/>
                  }
                </MenuItem>
              ))
        }
        {
          isFilter
            ? (
              <Box className={styles.stickyBottom}>
                <Button onClick={onAddFilterClick}>
                  {i18n.t('Common:AddFilter')}
                </Button>
              </Box>
            ) : undefined
        }
      </Select>
    ),
    mobileStructure = (
      <Box display='flex' alignItems='stretch' justifyContent='center' gap={1}>
        <Select<unknown>
          ref={mobileRef}
          native
          value={selection}
          open={show}
          multiple={config.multiple}
          onClick={onOpen}
          onChange={onMobileChange}
          label={config.label}
          className={styles.nativeSelect}
          inputProps={{
            id: `${config.label.toLowerCase()}-${SELECT_AS_STRING}`,
          }}>
          {
            pending || error
              ? <option disabled>{i18n.t(`Common:${error ? 'Error' : 'Loading'}`)}</option>
              : !formattedData.length
                ? <option disabled>{i18n.t(`Common:Empty`)}</option>
                : formattedData
                .map(({value, label}: RemoteDropdownData) => (
                  <option key={`${config.label.toLowerCase()}-value-${value}`} value={value as string | number}>
                    {label ?? value as string}
                  </option>
                ))
          }
        </Select>
        <Button variant='outlined' onClick={clearSelection} className={styles.nativeClear} disabled={config.disabled || isEmptyOrWhiteSpace(selection)}>
          {
            pending
              ? <CircularProgress />
              : <ClearIcon className={styles.clearIcon}/>
          }
        </Button>
      </Box>
    )

  //#endregion Partial Elements

  return (
    <FormControl size='small' fullWidth disabled={config.disabled}>
        <ErrorAlert show={error} onReload={onOpen} lineAlignment/>
        <InputLabel shrink={IS_MOBILE ? true : undefined} htmlFor={`${config.label.toLowerCase()}-${SELECT_AS_STRING}`} id={`${config.label.toLowerCase()}-${SELECT_AS_STRING}-${LABEL_AS_STRING}`}>{config.label}</InputLabel>
        {IS_MOBILE ? mobileStructure : desktopStructure}
    </FormControl>
  )
}
