import React, { useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import { PermissionType } from '../../../api/types'
import { useUser } from '../../../contexts/User'
import { getPaths, redirectPermissions } from '../../../routing/routes'
import { Loading } from '../Loading'


type CheckPermissionProps = {
  permission: PermissionType
}


export const CheckPermission = ({ permission }: CheckPermissionProps) => {
  const navigate = useNavigate()
  const { hasPermissions } = useUser()
  const [loading, setLoading] = useState(true)

  // If the user doesn't have the permission, redirect to the first permission that the user has
  useEffect(() => {
    if (!hasPermissions(permission)) {
      redirectPermissions[permission].redirects.forEach(redirect => {
        if (hasPermissions(redirect)) {
          navigate(redirectPermissions[redirect].path, { replace: true })
          return
        }
      })
      navigate(getPaths.brands.no_permissions.index, { replace: true })
    }
    setLoading(false)
  }, [hasPermissions, navigate, permission])


  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Outlet />
      )}
    </>
  )
}
