import React, { useCallback, useState, MouseEvent } from 'react'
import { useTranslation } from 'react-i18next'

import arrowLeft from '../../assets/icons/arrowLeft.svg'
import arrowRight from '../../assets/icons/arrowRight.svg'
import { Button } from '../Button'

import styles from './ImagesWithPreview.module.scss'


type ImagesWithPreviewProps = {
  images: string[]
  withLabel?: boolean
}


export const ImagesWithPreview = ({
  images,
  withLabel
}: ImagesWithPreviewProps) => {
  const i18n = useTranslation()

  const [isPreviewMode, setIsPreviewMode] = useState(false)
  const [currentImageIdx, setCurrentImageIdx] = useState(0)


  const handleSmallImgClick = useCallback((e: MouseEvent) => {
    setIsPreviewMode(true)
    e.stopPropagation()
  }, [])


  const handleQuitPreviewMode = useCallback((e: MouseEvent) => {
    setIsPreviewMode(false)
    e.stopPropagation()
  }, [])


  const handleImageChange = useCallback((idx: number) => {
    if (idx < 0) {
      setCurrentImageIdx(images.length - 1)
    } else if (idx >= images.length) {
      setCurrentImageIdx(0)
    } else {
      setCurrentImageIdx(idx)
    }
  }, [images.length])


  return (
    <div className={styles.imgContainer}>
      {(withLabel && images.length > 0) && (
        <div className={styles.label}>
          <span>{`${images.length} ${images.length === 1 ? i18n.t('Common:Photo') : i18n.t('Common:Photos')}`}</span>
        </div>
      )}
      {images.length === 0 ? (
        <h5 className={styles.noPhoto}>
          {i18n.t('Common:WithoutPhoto')}
        </h5>
      ) : (
        <img
          src={images[0]}
          className={styles.photoSmall}
          alt='AnswerPhoto'
          draggable={false}
          onClick={handleSmallImgClick}
        />
      )}
      {isPreviewMode && (
        <div
          className={styles.previewContainer}
          onClick={handleQuitPreviewMode}
        >
          <img
            src={images[currentImageIdx]}
            className={styles.photoBig}
            alt='questionImg'
          />
          {images.length > 1 && (
            <div className={styles.buttons}>
              <Button
                icon={arrowLeft}
                handler={() => handleImageChange(currentImageIdx - 1)}
                circle
              />
              <Button
                icon={arrowRight}
                handler={() => handleImageChange(currentImageIdx + 1)}
                circle
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
