import { ArrowDownward, ArrowUpward, Info, SaveAlt } from "@mui/icons-material";
import { Alert, AlertTitle, Box, Card, CardContent, CircularProgress, Tooltip } from "@mui/material";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { getDashboardKpi, ServerResponseType } from "../../api";
import { useGenericFilterContext } from "../../contexts/GenericFilterContext";
import BaseTableModel from "../../models/base/BaseTableModel";
import DadosAnoModel from "../../models/DadosAnoModel";
import DadosDiaActualModel from "../../models/DadosDiaActualModel";
import DadosUltimos30DiasModel from "../../models/DadosUltimos30DiasModel";
import ResumoMesesModel from "../../models/ResumoMesesModel";
import { dataUrlToBlob, EMPTY_VALUE, extractConditionalObject, formatFileName, formatString, formatTitle, formatValueByType, ValueType } from "../../utils/formatter";
import { isEmptyOrWhiteSpace, isNullOrUndefined, objectsAreEqual } from "../../utils/validator";
import { wrapContent, WrapperConfig } from "../../utils/wrapper";
import { ActionsMenu, ActionsMenuItem } from "../ActionsMenu/ActionsMenu";
import { DashboardFiltersType, getFilterNames, getFilterTags } from "../DashboardFilters/DashboardFilters";
import { ErrorAlert } from "../ErrorAlert/ErrorAlert";

import styles from "./KpiCard.module.scss";

//#region Enums

export enum KpiCardType {
  //#region Main Tab
  OUTAGES = 'outages',
  AVERAGE_OUTAGES_30 = 'averageOutages30',
  DAYS_WITHOUT_SALES_30 = 'daysWithoutSales30',
  MAIN_TOTAL_LOST_SALES = 'mainTotalLostSales',
  MAIN_ESTIMATED_LOST_VALUE = 'mainEstimatedLostValue30',
  CLOSING_DATA = 'closingData',
  //#endregion Main Tab

  //#region Daily Observatory Tab
  DAILY_SELLOUT = 'dailySellout',
  PREVIOUS_MONTH_SELLOUT = 'previousMonthSellout',
  WEEKEND_SELLOUT = 'weekendSellout',
  WEEK_SELLOUT = 'weekSellout',
  CURRENT_STOCK = 'currentStock',
  PRES_STOCK = 'presStock',
  IN_SUPPLY = 'inSupply',
  DAILY_EXPECTED = 'dailyExpected',
  DAILY_INTRANSIT = 'dailyIntransit',
  REPLACEMENT_CYCLES = 'replacementCycles',
  STOCK_SELLOUT_RATIO = 'stockSelloutRatio',
  DAY = 'day',
  OUTAGE_RISK_3_DAYS = 'outageRisk3Days',
  OUTAGE_RISK_5_DAYS = 'outageRisk5Days',
  PERCENTAGE_OUTAGE_30 = 'percentageOutage30',
  PERCENTAGE_OUTAGE_60 = 'percentageOutage60',
  PERCENTAGE_OUTAGE_120 = 'percentageOutage120',
  PERCENTAGE_DAYS_WITHOUT_SALES_30 = 'percentageDaysWithoutSales30',
  PERCENTAGE_DAYS_WITHOUT_SALES_60 = 'percentageDaysWithoutSales60',
  PERCENTAGE_DAYS_WITHOUT_SALES_120 = 'percentageDaysWithoutSales120',
  LOST_SALES_30 = 'lostSales30',
  LOST_SALES_60 = 'lostSales60',
  LOST_SALES_120 = 'lostSales120',
  PERCENTAGE_INCOMPLETE_LINEAR_DAYS_30 = 'percentageIncompleteLinearDays30',
  PERCENTAGE_INCOMPLETE_LINEAR_DAYS_60 = 'percentageIncompleteLinearDays60',
  PERCENTAGE_INCOMPLETE_LINEAR_DAYS_120 = 'percentageIncompleteLinearDays120',
  PERCENTAGE_SELLOUT_VOLATILITY_60 = 'percentageSelloutVolatility60',
  PERCENTAGE_SELLOUT_VOLATILITY_120 = 'percentageSelloutVolatility120',
  INSIGHT_1 = 'insight1',
  INSIGHT_2 = 'insight2',
  //#endregion Daily Observatory Tab

  //#region Last Month Tab
  AVERAGE_SELLOUT = 'averageSellout',
  MONTHLY_TOTAL_SELLOUT = 'monthlyTotalSellout',
  PREVIOUS_WEEK_SELLOUT = 'previousWeekSellout',
  AVERAGE_STOCK = 'averageStock',
  PREVIOUS_WEEK_STOCK = 'previousWeekStock',
  MONTHLY_TOTAL_SUPPLY = 'monthlyTotalSupply',
  MONTHLY_EXPECTED = 'monthlyExpected',
  MONTHLY_INTRANSIT = 'monthlyIntransit',
  MONTHLY_TOTAL_OUTAGES = 'monthlyTotalOutages',
  MONTHLY_PERCENTAGE_OUTAGE = 'monthlyPercentageOutage',
  PREVIOUS_WEEK_OUTAGES = 'previousWeekOutages',
  MONTHLY_TOTAL_LOST_SALES = 'monthlyTotalLostSales',
  WEEK_LOST_SALES = 'weekLostSales',
  WEEK_LOST_VALUE = 'weekLostValue',
  MONTHLY_ESTIMATED_LOST_VALUE = 'monthlyEstimatedLostValue',
  //#endregion Last Month Tab

  //#region Monthly Fluctuations Tab
  TOTAL_SELLOUT_YEAR = 'totalSelloutYear',
  TOTAL_STOCK_YEAR = 'totalStockYear',
  TOTAL_OUTAGES_YEAR = 'totalOutagesYear',
  TOTAL_DELIVERIES = 'totalDeliveries',
  UNITS_DELIVERED = 'unitsDelivered',
  UNITS_LINEAR = 'unitsLinear',
  //#endregion Monthly Fluctuations Tab

  //#region Year Tab
  DAILY_AVERAGE_SELLOUT = 'dailyAverageSellout',
  TOTAL_SELLOUT = 'yearTotalSellout',
  TOTAL_STOCK = 'totalStock',
  TOTAL_SUPPLY = 'totalSupply',
  TOTAL_OUTAGES = 'totalOutages',
  PERCENTAGE_OUTAGE = 'percentageOutage'
  //#endregion Year Tab
}

export enum KpiAggregatorType {
  AVERAGE = 'avgOrNull',
  SUMMATORY = 'SUM',
  MAXIMUM = 'maxOrNull',
  MOST_REPEATED = 'mostRepeated'
}

enum KpiTimelineComparisonType {
  WEEK = 'week'
}

enum KpiActionMenuType {
  EXPORT = 'export'
}

//#endregion Enums

//#region Interface

interface KpiComparisonData {
  current: number | string,
  previous: number | string,
  date: Date
}

interface KpiCardExternalConfig {
  type: KpiCardType;
  bindGenericFilters?: boolean | string[];
  wrapper?: WrapperConfig;
}

interface KpiCardInternalConfig {
  valueType: ValueType;
  revertNegativeContext?: boolean;
  queryParams: KpiQueryConfig;
  requiredFilters?: string[];
}

//#endregion Interface

//#region Classes

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class KpiQueryConfig<T = any> {
  table: Partial<BaseTableModel>;
  column: keyof T;
  aggregator: KpiAggregatorType;
  comparison?: KpiTimelineComparisonType;


  constructor(table: Partial<BaseTableModel>, column: keyof T, aggregator: KpiAggregatorType, comparison?: KpiTimelineComparisonType) {
    this.table = table;
    this.column = column;
    this.aggregator = aggregator;
    this.comparison = comparison;
  }

  public extract = () => ({
    ...Object.entries(this).reduce((acc: object, [key, value]: [string, unknown]) => {
      return key !== 'extract' ? {...acc, [key]: value} : acc;
    }, {}),
    table: this.table.getTableName?.() as string
  }) as DashboardKpiFilters
}

//#endregion Classes

//#region Types

export type KpiData = number | string | KpiComparisonData;

export type DashboardKpiFilters = KpiQueryConfig | { table: string }

type KpiInternalData = Partial<KpiComparisonData> & { difference?: number | string, negativeDifference?: boolean }

//#endregion Types

//#region Constants

const STORE_PRODUCT_FILTERS = getFilterTags([DashboardFiltersType.STORE, DashboardFiltersType.PRODUCT]),
  ACTIONS_MENU_CONTEXT = 'Kpi',
  PNG_MIME_TYPE = 'image/png',
  ACTIONS: ActionsMenuItem<KpiActionMenuType>[] = [
    {
      id: KpiActionMenuType.EXPORT,
      icon: SaveAlt
    }
  ],
  CONFIGS: {[key in KpiCardType]: KpiCardInternalConfig} = {
    //#region Main Tab
    [KpiCardType.OUTAGES]: {
      valueType: ValueType.PERCENTAGE,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'rotura', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.AVERAGE_OUTAGES_30]: {
      valueType: ValueType.PERCENTAGE,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'percentagemRoturas30Dias', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.DAYS_WITHOUT_SALES_30]: {
      valueType: ValueType.PERCENTAGE,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'percentagemDiasSemVendas30Dias', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.MAIN_TOTAL_LOST_SALES]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'vendasPerdidas', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.MAIN_ESTIMATED_LOST_VALUE]: {
      valueType: ValueType.CURRENCY,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'valorPerdido', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.CLOSING_DATA]: {
      valueType: ValueType.DATE,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'data', KpiAggregatorType.MAXIMUM)
    },
    //#endregion Main Tab
    
    //#region Daily Observatory Tab
    [KpiCardType.DAILY_SELLOUT]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'sellout', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.PREVIOUS_MONTH_SELLOUT]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'mediaSelloutMensal', KpiAggregatorType.SUMMATORY) // TODO: mediaSelloutMensalMesAnterior
    },
    [KpiCardType.WEEKEND_SELLOUT]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'selloutFdsMedio30Dias', KpiAggregatorType.SUMMATORY) // TODO: selloutFdsMedio
    },
    [KpiCardType.WEEK_SELLOUT]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'selloutSemanaMedio30Dias', KpiAggregatorType.SUMMATORY) //TODO: selloutSemanaMedio
    },
    [KpiCardType.CURRENT_STOCK]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'stock', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.PRES_STOCK]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'presStock', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.IN_SUPPLY]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'fornecimento', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.DAILY_EXPECTED]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'expected', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.DAILY_INTRANSIT]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'intransit', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.REPLACEMENT_CYCLES]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'ciclos', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.STOCK_SELLOUT_RATIO]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'balanceRaw', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.DAY]: {
      valueType: ValueType.DATE,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'data', KpiAggregatorType.MAXIMUM)
    },
    [KpiCardType.OUTAGE_RISK_3_DAYS]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'balanceSmart3Dias', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.OUTAGE_RISK_5_DAYS]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'balanceSmart5Dias', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.PERCENTAGE_OUTAGE_30]: {
      valueType: ValueType.PERCENTAGE,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'percentagemRoturas30Dias', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.PERCENTAGE_OUTAGE_60]: {
      valueType: ValueType.PERCENTAGE,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'percentagemRoturas60Dias', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.PERCENTAGE_OUTAGE_120]: {
      valueType: ValueType.PERCENTAGE,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'percentagemRoturas120Dias', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.PERCENTAGE_DAYS_WITHOUT_SALES_30]: {
      valueType: ValueType.PERCENTAGE,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'percentagemDiasSemVendas30Dias', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.PERCENTAGE_DAYS_WITHOUT_SALES_60]: {
      valueType: ValueType.PERCENTAGE,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'percentagemDiasSemVendas60Dias', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.PERCENTAGE_DAYS_WITHOUT_SALES_120]: {
      valueType: ValueType.PERCENTAGE,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'percentagemDiasSemVendas120Dias', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.LOST_SALES_30]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'vendasPerdidas', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.LOST_SALES_60]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'vendasPerdidasEm60Dias', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.LOST_SALES_120]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'vendasPerdidasEm120Dias', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.PERCENTAGE_INCOMPLETE_LINEAR_DAYS_30]: {
      valueType: ValueType.PERCENTAGE,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'percentagemDiasLinearIncompleto30Dias', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.PERCENTAGE_INCOMPLETE_LINEAR_DAYS_60]: {
      valueType: ValueType.PERCENTAGE,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'percentagemDiasLinearIncompleto60Dias', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.PERCENTAGE_INCOMPLETE_LINEAR_DAYS_120]: {
      valueType: ValueType.PERCENTAGE,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'percentagemDiasLinearIncompleto120Dias', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.PERCENTAGE_SELLOUT_VOLATILITY_60]: {
      valueType: ValueType.PERCENTAGE,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'percentagemVolatilidade60Dias', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.PERCENTAGE_SELLOUT_VOLATILITY_120]: {
      valueType: ValueType.PERCENTAGE,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'percentagemVolatilidade120Dias', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.INSIGHT_1]: {
      valueType: ValueType.STRING,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'conclusao1', KpiAggregatorType.MOST_REPEATED),
      requiredFilters: STORE_PRODUCT_FILTERS
    },
    [KpiCardType.INSIGHT_2]: {
      valueType: ValueType.STRING,
      queryParams: new KpiQueryConfig<DadosDiaActualModel>(DadosDiaActualModel, 'conclusao2', KpiAggregatorType.MOST_REPEATED),
      requiredFilters: STORE_PRODUCT_FILTERS
    },
    //#endregion Daily Observatory Tab

    //#region Last Month Tab
    [KpiCardType.AVERAGE_SELLOUT]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'sellout', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.MONTHLY_TOTAL_SELLOUT]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'sellout', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.PREVIOUS_WEEK_SELLOUT]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'sellout', KpiAggregatorType.SUMMATORY, KpiTimelineComparisonType.WEEK)
    },
    [KpiCardType.AVERAGE_STOCK]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'stock', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.PREVIOUS_WEEK_STOCK]: {
      revertNegativeContext: true,
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'stock', KpiAggregatorType.SUMMATORY, KpiTimelineComparisonType.WEEK)
    },
    [KpiCardType.MONTHLY_TOTAL_SUPPLY]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'fornecimento', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.MONTHLY_EXPECTED]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'expected', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.MONTHLY_INTRANSIT]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'intransit', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.MONTHLY_TOTAL_OUTAGES]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'rotura', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.MONTHLY_PERCENTAGE_OUTAGE]: {
      valueType: ValueType.PERCENTAGE,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'rotura', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.PREVIOUS_WEEK_OUTAGES]: {
      revertNegativeContext: true,
      valueType: ValueType.PERCENTAGE,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'rotura', KpiAggregatorType.AVERAGE, KpiTimelineComparisonType.WEEK)
    },
    [KpiCardType.MONTHLY_TOTAL_LOST_SALES]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'vendasPerdidas', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.WEEK_LOST_SALES]: {
      revertNegativeContext: true,
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'vendasPerdidas', KpiAggregatorType.SUMMATORY, KpiTimelineComparisonType.WEEK)
    },
    [KpiCardType.WEEK_LOST_VALUE]: {
      revertNegativeContext: true,
      valueType: ValueType.CURRENCY,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'valorPerdido', KpiAggregatorType.SUMMATORY, KpiTimelineComparisonType.WEEK)
    },
    [KpiCardType.MONTHLY_ESTIMATED_LOST_VALUE]: {
      valueType: ValueType.CURRENCY,
      queryParams: new KpiQueryConfig<DadosUltimos30DiasModel>(DadosUltimos30DiasModel, 'valorPerdido', KpiAggregatorType.SUMMATORY)
    },
    //#endregion Last Month Tab

    //#region Monthly Fluctuations Tab
    [KpiCardType.TOTAL_SELLOUT_YEAR]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<ResumoMesesModel>(ResumoMesesModel, 'sellout1DiasAntes', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.TOTAL_STOCK_YEAR]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<ResumoMesesModel>(ResumoMesesModel, 'stock1DiasAntes', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.TOTAL_OUTAGES_YEAR]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<ResumoMesesModel>(ResumoMesesModel, 'rotura1DiasAntes', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.TOTAL_DELIVERIES]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<ResumoMesesModel>(ResumoMesesModel, 'numeroFornecimento', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.UNITS_DELIVERED]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<ResumoMesesModel>(ResumoMesesModel, 'unidadesEncomendadas', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.UNITS_LINEAR]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<ResumoMesesModel>(ResumoMesesModel, 'presStock1DiasAntes', KpiAggregatorType.SUMMATORY)
    },
    //#endregion Monthly Fluctuations Tab

    //#region Year Tab
    [KpiCardType.DAILY_AVERAGE_SELLOUT]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosAnoModel>(DadosAnoModel, 'sellout', KpiAggregatorType.AVERAGE)
    },
    [KpiCardType.TOTAL_SELLOUT]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosAnoModel>(DadosAnoModel, 'sellout', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.TOTAL_STOCK]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosAnoModel>(DadosAnoModel, 'stock', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.TOTAL_SUPPLY]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosAnoModel>(DadosAnoModel, 'fornecimento', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.TOTAL_OUTAGES]: {
      valueType: ValueType.NUMBER,
      queryParams: new KpiQueryConfig<DadosAnoModel>(DadosAnoModel, 'rotura', KpiAggregatorType.SUMMATORY)
    },
    [KpiCardType.PERCENTAGE_OUTAGE]: {
      valueType: ValueType.PERCENTAGE,
      queryParams: new KpiQueryConfig<DadosAnoModel>(DadosAnoModel, 'rotura', KpiAggregatorType.AVERAGE)
    }
    //#endregion Year Tab
  };

//#endregion Constants

//#region Aux Methods

export const getBaseKpiConfig = (kpi: KpiCardType): DashboardKpiFilters => CONFIGS[kpi].queryParams.extract();

//#endregion Aux Methods

export const KpiCard = ({type, bindGenericFilters, wrapper}: KpiCardExternalConfig) => {
  const cardRef = useRef(null as null | HTMLDivElement),
        {genericFilters, updateInUseFilters} = useGenericFilterContext(),
        [error, setError] = useState(false),
        [data, setData] = useState(undefined as KpiInternalData | undefined),
        {current, previous, date, difference, negativeDifference } = useMemo(() => data ?? {}, [data]),
        [pending, setPending] = useState(false),
        { queryParams, valueType, requiredFilters, revertNegativeContext } = useMemo(() => CONFIGS[type], [type]),
        arrowConditionalStyle = useMemo(() => {
          const negative = revertNegativeContext ? !negativeDifference : negativeDifference;
          return negative ? styles.negative : styles.positive;
        },[negativeDifference, revertNegativeContext]),
        [filters, setFilters] = useState({
          ...queryParams.extract(),
          ...extractConditionalObject(genericFilters, bindGenericFilters)
        } as DashboardKpiFilters),
        validFetch = useMemo(() => {
          return requiredFilters?.every((item: string) => {
            return !isEmptyOrWhiteSpace(filters[item]);
          }) ?? true;
        }, [filters, requiredFilters]),
        filterNames = useMemo(() => getFilterNames(requiredFilters ?? []),[requiredFilters]),
        i18n = useTranslation(),
        label = useMemo(() => i18n.t(`Kpi:${formatTitle(type)}Label`), [type, i18n]),
        tooltip = useMemo(() => {
          // eslint-disable-next-line i18next/no-literal-string
          const resource = `Kpi:${formatTitle(type)}Tooltip`;
          return i18n.i18n.exists(resource) ? i18n.t(resource) : undefined;
        }, [type, i18n]);

  //#region Private Methods

  const formatValue = useCallback((value: unknown) => {
    switch(valueType) {
      case ValueType.PERCENTAGE:
        value = value as number * 100;
        break;
    }

    return formatValueByType(value, valueType);
  }, [valueType]);

  const fetchRemote = () => {
    setError(false);
    if(!requiredFilters || validFetch) {
      setPending(true);
      getDashboardKpi(filters)
        .then(({data, success}: ServerResponseType<KpiData>) => {
          if(!success) {
            setError(true);
          }
          
          if(isNullOrUndefined(data)) {
            setData(undefined);
          } else {
            if(typeof data === 'object') {
              const current = data.current as number,
                    previous = data.previous as number,
                    difference = previous > 0 ? ((current - previous)/previous) * 100 : NaN;
  
              setData({
                current: formatValue(current),
                previous: formatValue(previous),
                date: data.date,
                difference: isNaN(difference) ? EMPTY_VALUE :formatValueByType(Math.abs(difference), ValueType.PERCENTAGE),
                negativeDifference: difference < 0
              });
            } else {
              setData({current: formatValue(data)});
            }
          }
        })
        .finally(() => {
          setPending(false);
        });
    } else {
      setData(undefined);
    }
  };

  const exportKpi = async () => {
    if(cardRef.current) {
      const canvas = await html2canvas(cardRef.current),
            dataUrl = canvas.toDataURL(PNG_MIME_TYPE, 1.0);

      saveAs(dataUrlToBlob(dataUrl), formatFileName(label));
    }
  };

  const onAction = (action: KpiActionMenuType) => {
    switch(action) {
      case KpiActionMenuType.EXPORT:
        exportKpi();
        break;
    }
  };

  //#endregion Private Methods

  //#region Effects
  
  useEffect(fetchRemote, [filters, requiredFilters, validFetch, formatValue]);

  useEffect(() => {
    const newFilters = {
      ...filters,
      ...queryParams.extract()
    }

    if(!objectsAreEqual(filters, newFilters)) {
      setFilters(newFilters);
    }
  }, [filters, queryParams]);

  useEffect(() => {
    if(bindGenericFilters) {
      const newFilters = {
        ...filters,
        ...extractConditionalObject(genericFilters, bindGenericFilters)
      }

      if(!objectsAreEqual(filters, newFilters)) {
        setFilters(newFilters);
      }
    }
  }, [filters, genericFilters, bindGenericFilters]);

  useEffect(() => {
    if(bindGenericFilters) {
      updateInUseFilters(bindGenericFilters);
    }
    // Disable eslint for updateInUseFilters
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bindGenericFilters]);

  //#endregion Effects

  //#region Partials

  const getComparisonBlock = useCallback((extra?: ReactNode) => {
          return <Box display={'flex'} gap={0.5} alignItems={'center'} className={`${styles['comparison-box']} ${arrowConditionalStyle}`}>
                  { negativeDifference ? <ArrowDownward className={styles.icon}/> : <ArrowUpward className={styles.icon}/> }
                  <b>{difference}</b>
                  {extra}
                </Box>
        }, [arrowConditionalStyle, negativeDifference, difference]),
        valueDisplay = pending
                        ? <CircularProgress/>
                        : <b> { data === undefined ? '--' : current } </b>,
        dateDisplay = date ? <span>{formatValueByType(date, ValueType.DATE)}</span> : undefined,
        labelTooltipDisplay = tooltip && !error
                                ? <Tooltip title={tooltip} placement='top' arrow>
                                    <Info className={styles.info}/>
                                  </Tooltip>
                                : undefined,
        comparisonDisplay = difference
                              ? <Tooltip className={styles.pointer} title={getComparisonBlock(<span>{i18n.t('Kpi:PreviousWeekComparison')}: {previous}</span>)} placement='bottom' arrow>
                                  {getComparisonBlock(<span>({previous})</span>)}
                                </Tooltip>
                              : undefined
        

  //#endregion Partials

  return wrapContent(
    <Card className={styles.card} elevation={2} variant='elevation'>
      <ActionsMenu<KpiActionMenuType>
        absolute
        actions={ACTIONS}
        context={ACTIONS_MENU_CONTEXT}
        onClick={onAction}/>
      <ErrorAlert show={error} onReload={fetchRemote}/>
      {
        !validFetch
          ? <Alert variant='standard' className={styles.alert} severity='info'>
              <AlertTitle>{formatString(i18n.t('Info:KpiNeededFilters'), filterNames.map((name: string) => i18n.t(`Dashboard:${formatTitle(name)}`)).join(', '))}</AlertTitle>
            </Alert>
          : undefined
      }
      <CardContent className={styles.content} ref={cardRef}>
        { dateDisplay }
        <span className={`${styles.value} ${styles[valueType]}`}>{ valueDisplay }</span>
        { comparisonDisplay }
        <Box className={styles.label}>
          <span>{label}</span>
          { labelTooltipDisplay }
        </Box> 
      </CardContent>
    </Card>
  , wrapper);
};
