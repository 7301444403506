import { Box, Grid } from "@mui/material";

import { DashboardFiltersType, getFilterTags } from "../../../components/DashboardFilters/DashboardFilters";
import AvisoRoturaSemanaSeguinteModel from "../../../models/AvisoRoturaSemanaSeguinteModel";
import { DashboardWidgetType, getWidgetsRender, WidgetsStructure } from "../../../utils/dashboard";
import { WrapperWidth } from "../../../utils/wrapper";

//#region Constants

const TABLES: WidgetsStructure = {
  'NextWeek': {
    model: AvisoRoturaSemanaSeguinteModel,
    filters: getFilterTags([DashboardFiltersType.SELLERS, DashboardFiltersType.STORE, DashboardFiltersType.SUB_FAMILY, DashboardFiltersType.PRODUCT]),
    width: WrapperWidth.FULL
  }
}

//#endregion Constants


export const NextWeek = () => {
  return (
    <Box>
      <Grid container>
        { getWidgetsRender(DashboardWidgetType.TABLES, TABLES) }
      </Grid>
    </Box>
  )
}
