import { useEffect, useRef, useState } from "react"

//#region Constants

const ROOT_MARGIN = '-1px 0px 0px 0px'

//#endregion Constants

export const useSticky = <T extends HTMLElement>() => {
  const ref = useRef<T>(null),
        [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    if(!ref.current) {
      return;
    }

    const observer = new IntersectionObserver(([event]: IntersectionObserverEntry[]) => setIsSticky(event.intersectionRatio < 1), {threshold: [1], rootMargin: ROOT_MARGIN});

    observer.observe(ref.current);

    //eslint-disable-next-line consistent-return
    return () => observer.disconnect();
  }, []);

  return { ref, isSticky };
}
