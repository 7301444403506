import React from 'react'
import { useTranslation } from 'react-i18next'

import styles from './Validation.module.scss'

// README: In the future, when a question can have many validations,
// this type probably should go to api/types and be updated to the props then defined
// also, this component will need to be updated to show the new information the validations will have
export type ValidationType = {
  text: string
  value: number
  maxValidations: number
}

type ValidationProps = {
  id: number
  validation: ValidationType
}

export const Validation = ({
  id,
  validation,
}: ValidationProps) => {
  const i18n = useTranslation()

  return (
    <div className={styles.validation}>
      <div className={styles.id}>
        <span className={styles.idText}>
          {id}
        </span>
      </div>
      <div className={styles.validationTable}>
        <div className={styles.validationTableRow}>
          <div className={styles.title}>
            <h4>
              {i18n.t('MissionWizard:ValidationQuestion')}
            </h4>
          </div>
          <div className={styles.info}>
            <h5>
              {validation.text}
            </h5>
          </div>
        </div>
        <div className={styles.validationTableRow}>
          <div className={styles.validationHalfRow}>
            <div className={styles.title}>
              <h4>
                {i18n.t('Common:Value')}
              </h4>
            </div>
            <div className={styles.info}>
              <h5>
                {`${Number(validation.value).toFixed(2)}€`}
              </h5>
            </div>
          </div>
          <div className={styles.validationHalfRow}>
            <div className={styles.title}>
              <h4>
                {i18n.t('Mission:MaxValidations')}
              </h4>
            </div>
            <div className={styles.info}>
              <h5>
                {validation.maxValidations}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
