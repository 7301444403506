import UAParser from "ua-parser-js";

//#region Constants

const DATE_TIME_METHODS: (keyof Date)[] = ['getHours', 'getMinutes', 'getSeconds', 'getMilliseconds'];

//#endregion Constants

export const isNullOrUndefined = <T>(value: T): boolean => value === null || value === undefined;

export const isEmptyOrWhiteSpace = <T>(value: T): boolean => {
  if(isNullOrUndefined(value) || value === '') {
    return true;
  } else if(typeof value === 'object') {
    if (value instanceof Date) {
      return false;
    } else if(Array.isArray(value) && !value.length) {
      return true;
    } else if(!Object.keys(value ?? {}).length) {
      return true;
    }
  }

  
  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNumber = (value: any) => typeof value === 'number';

export const isJSONString = (value: string): boolean => {
  try {
    JSON.parse(value);
  } catch {
    return false;
  }
  return true;
}


export const containsText = (fullText: string, searchText: string): boolean => fullText.toLowerCase().includes(searchText.toLowerCase());

export const objectsAreEqual = (objA: object, objB: object): boolean => {
  if(isNullOrUndefined(objA) || isNullOrUndefined(objB)) {
    return false;
  }

  const keysA: string[] = Object.keys(objA),
        keysB: string[] = Object.keys(objB);

  return keysA.length === keysB.length && keysA.every((key: string) => JSON.stringify(objA[key]) === JSON.stringify(objB[key]));
}


export const isMobile = () => {
  const {type} = new UAParser(navigator.userAgent).getDevice()
  return !!type; // When type is undefined, then it is desktop
}

export const timeIsTruncated = (date: Date) => DATE_TIME_METHODS.every((validator: keyof Date) => (date[validator] as () => number)() === 0)
