import React, { ReactNode } from 'react'

import styles from './Pill.module.scss'


type PillProps = {
  variant?: 'primary'
          | 'primaryWhite'
          | 'secondary'
          | 'secondaryWhite'
          | 'neutral'
          | 'neutralWhite'
          | 'success'
          | 'successWhite'
          | 'danger'
          | 'dangerWhite'
          | 'alert'
          | 'alertWhite'
          | 'warning'
          | 'warningWhite'
  title: string | ReactNode
  titleSize?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'
  icon?: string
  fullHeight?: boolean
  noBorderRadiusLeft?: boolean
  noBorderRadiusRight?: boolean
  noBorderLeft?: boolean
  noBorderRight?: boolean
}


export const Pill = ({
  variant = 'primary',
  title,
  titleSize,
  icon,
  fullHeight,
  noBorderRadiusLeft,
  noBorderRadiusRight,
  noBorderLeft,
  noBorderRight
}: PillProps) => {
  const pillStyles: { [key: string]: string } = {
    primary: styles.primary,
    primaryWhite: styles.primaryWhite,
    secondary: styles.secondary,
    secondaryWhite: styles.secondaryWhite,
    neutral: styles. neutral,
    neutralWhite: styles.neutralWhite,
    success: styles.success,
    successWhite: styles.successWhite,
    danger: styles.danger,
    dangerWhite: styles.dangerWhite,
    warning: styles.warning,
    warningWhite: styles.warningWhite,
  }

  const titleStyles: { [key: string]: string } = {
    h1: styles.h1,
    h2: styles.h2,
    h3: styles.h3,
    h4: styles.h4,
    h5: styles.h5,
    h6: styles.h6,
  }


  return (
    <div
      className={`
        ${styles.pill}
        ${pillStyles[variant]}
        ${fullHeight && styles.fullHeight}
        ${noBorderRadiusLeft && styles.noBorderRadiusLeft}
        ${noBorderRadiusRight && styles.noBorderRadiusRight}
        ${noBorderLeft && styles.noBorderLeft}
        ${noBorderRight && styles.noBorderRight}
      `}
    >
      {icon && (
        <img
          className={styles.icon}
          src={icon}
          alt='pillIcon'
        />
      )}
      {typeof title === 'string' ? (
        <p className={`${styles.title} ${titleSize && titleStyles[titleSize]}`}>
          {title}
        </p>
      ) : (
        title
      )}
    </div>
  )
}
