import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import PageNotFoundIcon from '../../assets/icons/pageNotFound.svg'
import { Button } from '../../elements/Button'

import styles from './RouteNotFound.module.scss'
 

type RouteNotFoundProps = {
  goToText: string
  route: string
}


export const RouteNotFound = ({
  goToText,
  route
}: RouteNotFoundProps) => {
  const i18n = useTranslation()
  const navigate = useNavigate()

  return (
    <div className={styles.routeNotFound}>
      <div className={styles.top}>
        <img
          src={PageNotFoundIcon}
          alt='PageNotFoundIcon'
          draggable={false}
        />
        <h2>
          {i18n.t('Error:PageNotFound')}
        </h2>
      </div>
      <Button
        title={`${i18n.t('GoTo:GoTo')} ${goToText}`}
        handler={() => navigate(route)}
        fullWidth
      />
    </div>
  )
}
