import { Box, Grid } from "@mui/material"

import { DashboardChartType } from "../../../components/DashboardChart/DashboardChart"
import { DashboardFiltersType, getFilterTags } from "../../../components/DashboardFilters/DashboardFilters"
import { KpiCardType } from "../../../components/KpiCard/KpiCard"
import DadosAnoModel from "../../../models/DadosAnoModel"
import { DashboardWidgetType, getWidgetsRender, WidgetsStructure } from "../../../utils/dashboard"
import { WrapperHeight, WrapperWidth } from "../../../utils/wrapper"
//#region Enums

enum TableTypes {
  BASE_YEAR = 'BaseYear'
}

//#endregion Enums

//#region Constants

const FILTERS: string[] = getFilterTags([DashboardFiltersType.SELLERS, DashboardFiltersType.STORE, DashboardFiltersType.SUB_FAMILY, DashboardFiltersType.PRODUCT, DashboardFiltersType.DATES]),
  KPIS: WidgetsStructure = {
    [KpiCardType.DAILY_AVERAGE_SELLOUT]: {
      filters: FILTERS,
      height: WrapperHeight.SMALL,
      width: WrapperWidth.FULL
    },
    [KpiCardType.TOTAL_SELLOUT]: {
      filters: FILTERS,
      height: WrapperHeight.SMALL,
      width: WrapperWidth.FULL
    },
    [KpiCardType.TOTAL_STOCK]: {
      filters: FILTERS,
      height: WrapperHeight.MEDIUM,
      width: WrapperWidth.FULL
    },
    [KpiCardType.TOTAL_SUPPLY]: {
      filters: FILTERS,
      height: WrapperHeight.MEDIUM,
      width: WrapperWidth.FULL
    },
    [KpiCardType.TOTAL_OUTAGES]: {
      filters: FILTERS,
      height: WrapperHeight.SMALL,
      width: WrapperWidth.FULL
    },
    [KpiCardType.PERCENTAGE_OUTAGE]: {
      filters: FILTERS,
      height: WrapperHeight.SMALL,
      width: WrapperWidth.FULL
    },
  },
  CHARTS: WidgetsStructure = {
    [DashboardChartType.SELLOUT_YEAR]: {
      filters: getFilterTags([DashboardFiltersType.DATES]),
      height: WrapperHeight.MEDIUM,
      width: WrapperWidth.FULL
    },
    [DashboardChartType.STOCK_YEAR]: {
      filters: FILTERS,
      height: WrapperHeight.MEDIUM,
      width: WrapperWidth.FULL
    },
    [DashboardChartType.SUPPLY_YEAR]: {
      filters: FILTERS,
      height: WrapperHeight.MEDIUM,
      width: WrapperWidth.FULL
    },
    [DashboardChartType.OUTAGES_YEAR]: {
      filters: FILTERS,
      height: WrapperHeight.MEDIUM,
      width: WrapperWidth.FULL
    }
  },
  TABLES: WidgetsStructure = {
    [TableTypes.BASE_YEAR]: {
      model: DadosAnoModel,
      filters: FILTERS,
      width: WrapperWidth.FULL
    }
  }

//#endregion Constants

export const YearAnalysis = () => {
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item md={12} lg={10}>
              <Grid container spacing={1}>
                { getWidgetsRender(DashboardWidgetType.CHARTS, CHARTS) }
              </Grid>
            </Grid>
            <Grid item md={12} lg={2}>
              <Grid container spacing={1}>
                { getWidgetsRender(DashboardWidgetType.KPIS, KPIS) }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        { getWidgetsRender(DashboardWidgetType.TABLES, TABLES) }
      </Grid>
    </Box>
  )
}
