import { useLayoutEffect, useMemo } from "react";
import { Location, useLocation } from "react-router-dom"

//#region Interfaces

interface LocationTransitionConfig {
  prop?: keyof Location;
  onEnter?: (location: Location) => void;
  onExit?: (location: Location) => void;
}

//#endregion Interfaces

export const useLocationTransition = ({prop, onEnter, onExit}: LocationTransitionConfig) => {
  const location = useLocation(),
        listenProp = useMemo(() => !prop ? location : location[prop],[location, prop]);

  useLayoutEffect(() => {
    onEnter?.(listenProp);
    return () => onExit?.(listenProp);
    // Disable eslint for onEnter and onExit
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listenProp]);
}
