import { line, scaleLinear, bin, Bin, scaleBand, curveCatmullRom } from "d3";
import { prepareBoxplotData } from "echarts/extension/dataTool";
import { regression } from "echarts-stat";
import { CSSProperties } from "react";

import { ServerResponseType } from "../../api";
import i18n from "../../localization";
import { addDateTime, dateDifference, DateUnitType, isDate } from "../../utils/dates";
import { formatString, formatTitle, formatValueByType, roundToDecimalPlaces, ValueType } from "../../utils/formatter";
import { stddev } from "../../utils/math";
import { isNullOrUndefined, isNumber } from "../../utils/validator";
import { WrapperConfig } from "../../utils/wrapper";

import styles from "./Chart.module.scss";

//#region Enums

export enum ChartType {
  LINE = 'line',
  BAR = 'bar',
  PIE = 'pie',
  SCATTER = 'scatter',
  GAUGE = 'gauge',
  BOXPLOT = 'boxplot',
  VIOLIN = 'violin'
}

export enum ChartAxisType {
  VALUE = 'value',
  CATEGORY = 'category',
  TIME = 'time',
  LOG = 'log'
}

export enum OrientType {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical'
}

export enum ChartStackBarsType {
  STANDARD = 'standard',
  FILLED = 'filled'
}

export enum ChartToolboxActionType {
  EXPORT = 'export',
  SCATTER_SHIFT = 'scatterShift',
  LINE_SHIFT = 'lineShift',
  BAR_SHIFT = 'barShift',
  ZOOM = 'zoom',
  RESET_ZOOM = 'resetZoom'
}

export enum DefaultChartActionType {
  DATA_ZOOM = 'dataZoom',
  LEGEND_SELECT = 'legendSelect',
  TAKE_GLOBAL_CURSOR = 'takeGlobalCursor'
}

export enum DefaultChartTakeGlobalCursorActionKeyType {
  DATA_ZOOM_SELECT = 'dataZoomSelect'
}

export enum DefaultChartEventType {
  DATA_ZOOM = 'datazoom',
  CLICK = 'click',
  LEGEND_SELECT_CHANGED = 'legendselectchanged'
}

enum ChartTooltipAxisPointerType {
  LINE = 'line',
  SHADOW = 'shadow',
  CROSS = 'cross',
  NONE = 'none'
}

enum ChartDataZoomType {
  INSIDE = 'inside',
  SLIDER = 'slider'
}

enum ChartDataZoomKeyBindType {
  SHIFT = 'shift',
  CTRL = 'ctrl',
  ALT = 'alt'
}

enum ChartTooltipTriggerType {
  ITEM = 'item',
  AXIS = 'axis',
  NONE = 'none'
}

enum ChartAnimationType {
  LINEAR = 'linear',
  QUADRATIC_IN = 'quadraticIn',
  QUADRATIC_OUT = 'quadraticOut',
  QUADRATIC_IN_OUT = 'quadraticInOut',
  CUBIC_IN = 'cubicIn',
  CUBIC_OUT = 'cubicOut',
  CUBIC_IN_OUT = 'cubicInOut',
  QUARTIC_IN = 'quarticIn',
  QUARTIC_OUT = 'quarticOut',
  QUARTIC_IN_OUT = 'quarticInOut',
  QUINTIC_IN = 'quinticIn',
  QUINTIC_OUT = 'quinticOut',
  QUINTIC_IN_OUT = 'quinticInOut',
  SINUSOIDAL_IN = 'sinusoidalIn',
  SINUSOIDAL_OUT = 'sinusoidalOut',
  SINUSOIDAL_IN_OUT = 'sinusoidalInOut',
  EXPONENTIAL_IN = 'exponentialIn',
  EXPONENTIAL_OUT = 'exponentialOut',
  EXPONENTIAL_IN_OUT = 'exponentialInOut',
  CIRCULAR_IN = 'circularIn',
  CIRCULAR_OUT = 'circularOut',
  CIRCULAR_IN_OUT = 'circularInOut',
  ELASTIC_IN = 'elasticIn',
  ELASTIC_OUT = 'elasticOut',
  ELASTIC_IN_OUT = 'elasticInOut',
  BACK_IN = 'backIn',
  BACK_OUT = 'backOut',
  BACK_IN_OUT = 'backInOut',
  BOUNCE_IN = 'bounceIn',
  BOUNCE_OUT = 'bounceOut',
  BOUNCE_IN_OUT = 'bounceInOut'
}

enum ChartIconType {
  CIRCLE = 'circle',
  RECT = 'rect',
  ROUND_RECT = 'roundRect',
  TRIANGLE = 'triangle',
  DIAMOND = 'diamond',
  PIN = 'pin',
  ARROW = 'arrow',
  NONE = 'none'
}

enum ChartLegendType {
  PLAIN = 'plain',
  SCROLL = 'scroll'
}

enum ChartSeriesRenderItemType {
  GROUP = 'group',
  PATH = 'path',
  IMAGE = 'image',
  TEXT = 'text',
  RECT = 'rect',
  CIRCLE = 'circle',
  RING = 'ring',
  SECTOR = 'sector',
  ARC = 'arc',
  POLYGON = 'polygon',
  POLYLINE = 'polyline',
  LINE = 'line',
  BEZIER_CURVE = 'bezierCurve'
}

enum VerticalPositionType {
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom'
}

enum HorizontalPositionType {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right'
}

enum LinePositionType {
  START = 'start',
  MIDDLE = 'middle',
  CENTER = 'center',
  END = 'end'
}

enum FontWeightType {
  NORMAL = 'normal',
  BOLD = 'bold',
  _300 = '300',
  _700 = '700'
}

enum ObjectPositionType {
  INSIDE = 'inside',
  OUTSIDE = 'outside',
  INNER = 'inner',
  CENTER = 'center'
}

enum ObjectFocusType {
  NONE = 'none',
  SELF = 'self',
  SERIES = 'series'
}

enum TableRowType {
  HEADER = 'header',
  DATA = 'data'
}

enum ChartAxisLabelRotateType {
  RADIAL = 'radial',
  TANGENTIAL = 'tangential'
}

enum ChartRegressionMethodType {
  LINEAR = 'linear',
  EXPONENTIAL =  'exponential',
  LOGARITHMIC = 'logarithmic',
  POLYNOMIAL = 'polynomial'
}

enum LineStyleType {
  SOLID = 'solid',
  DASHED = 'dashed',
  DOTTED = 'dotted'
}

enum ChartSymbolType {
  CIRCLE = 'circle',
  EMPTY_CIRCLE = 'emptyCircle',
  RECT = 'rect',
  ROUND_RECT = 'roundRect',
  TRIANGLE = 'triangle',
  DIAMOND = 'diamond',
  PIN = 'pin',
  ARROW = 'arrow'
}

enum ChartToolboxFeatureType {
  DATA_ZOOM = 'dataZoom',
  RESTORE = 'restore'
}

//#endregion Enums

//#region Type

type ChartIntervalType = number | 'auto';

type ChartXMinMax = { min: number, max: number};

type ChartXYValue = string | number | Date | undefined | null;

type ChartDataZoomInsideType = boolean | ChartDataZoomKeyBindType;

type ChartGridTopType = number | VerticalPositionType;

type ChartGridLeftType = number | HorizontalPositionType;

type ChartAxisLabelType = string | { year?: string, month?: string, day?: string} | ((value: number, index: number) => string);

type BinsIndexes = { start?: number, end?: number};

type ChartShowOption = { show?: boolean };

type AllTags = 'All';

export type ChartToolboxTagValue = ChartToolboxActionType[] | AllTags | false;

export type ChartZoomAction = BaseAction & BaseZoomActionEvent;

export type ChartZoomEvent = BaseEvent & BaseZoomActionEvent & { batch: BaseZoomActionEvent[] };

export type ChartAction = ChartZoomAction;

export type GenericChartAction = ChartAction & { triggerId?: string };

//#endregion Type

//#region Interface

export interface ChartRawData {
  value?: number | null;
  label: string;
  time?: Date | null;
  groupLabel?: string | null;
  extraProperties?: { [key: string]: string | number } | null;
}

export interface ChartGaugeInterval {
  interval: number[];
  color?: string;
  label: string;
}

export interface ChartConfig {
  type: ChartType;
  wrapper?: WrapperConfig;
  data?: InnerChartRawData[];
  fetchMethod?: (filters?: object) => Promise<ServerResponseType<ChartRawData[]>>;
  bindGenericFilters?: boolean | string[];
  grids?: number;
  dataLabel?: string;
  stackBarsType?: ChartStackBarsType;
  axisType?: ChartAxisType;
  orientation?: OrientType;
  seriesMap?: ChartSeriesMap[];
  xAxisValueType?: ValueType;
  yAxisValueType?: ValueType;
  title?: string;
  subTitle?: string;
  toolbox?: ChartToolboxTagValue;
  gaugeIntervals?: ChartGaugeInterval[];
  tooltipFormatter?: (params: ChartTooltipParams|Array<ChartTooltipParams>) => string | HTMLElement | HTMLElement[];
  showSeriesLabel?: boolean;
  showLinearRegression?: boolean;
  areaFill?: boolean;
  translateSeries?: boolean | string;
  multipleYAxis?: string[];
  globalActionBind?: boolean;
}

interface ChartBaseParams {
  seriesType: string;
  seriesIndex: number;
  seriesName: string;
  name: string;
  dataIndex: number;
  data: number | Array<ChartXYValue> | object;
  value: number | Array<ChartXYValue> | object;
  color: string;
}

export interface ChartEventParams {
  name: string
}

export interface ChartTooltipParams extends ChartBaseParams {
  componentType: 'series';
  componentSubType: string;
  componentIndex: 0;
  marker: string;
  axisValue: number;
  axisValueLabel: string;
}

export interface ChartLoadingConfig {
  text?: string;
  color?: string;
  showSpinner: boolean;
  fontWeight?: string,
  fontFamily?: string;
  fontSize?: number;
}

interface BaseAction {
  type: DefaultChartActionType;
}

interface BaseEvent {
  type: DefaultChartEventType;
}

interface ChartSeriesMap {
  valueField: keyof ChartRawData;
  categoryField?: keyof ChartRawData;
  timeField?: keyof ChartRawData;
  name: string;
  color: string;
}

interface ChartSeriesData {
  value: number | ChartXYValue[];
  itemStyle?: CSSProperties | { borderRadius?: number | [number, number, number, number] };
  name?: string;
}

interface ChartSeriesLabel {
  show: boolean;
  color?: string;
  position?: ObjectPositionType;
  formatter?: (params: ChartBaseParams) => string;
  rich?: {[name: string]: CSSProperties};
}

interface ChartSeriesLabelLayout {
  hideOverlap?: boolean;
}

interface ChartSeriesEmphasis {
  scale?: boolean;
  focus?: ObjectFocusType;
  label?: ChartSeriesLabel;
  itemStyle?: CSSProperties & { shadowBlur?: number };
}

interface ChartSeriesRenderItemParams {
  context: string;
  seriesId: string;
  seriesName: string;
  seriesIndex: number;
  dataIndex: number;
  dataIndexInside: number;
  dataInsideLength: number;
}

interface ChartSeriesRenderItemApi {
  value: (index: number, dataIndexIndex?: number) => number;
  coord: (data: number[]) => number[];
  size: (dataSize: number[], dataItem?: number[]) => number[];
  style: (extra: CSSProperties & {lineWidth: number}, dataIndexInside?: number) => CSSProperties;
  styleEmphasis: (extra: CSSProperties, dataIndexInside?: number) => CSSProperties;
  visual: (visualType: string, dataIndexInside?: number) => string | number;
  currentSeriesIndices: () => number;
  getWidth: () => number;
  getHeight: () => number;
  getDevicePixelRation: () => number;
}

interface ChartSeriesRenderItemReturn {
  z2?: number;
  type: ChartSeriesRenderItemType;
  x?: number;
  y?: number;
  shape?: { pathData: string };
  style?: CSSProperties;
  focus?: ObjectFocusType;
  emphasis?: { style: CSSProperties; };
}

interface ChartSeriesEncode {
  x: string | number | (string|number)[];
  y: string | number | (string|number)[];
}

interface TextStyle {
  align?: string;
  verticalAlign?: string;
  fontWeight?: FontWeightType;
  fontFamily?: string;
  fontSize?: number | string;
  color?: string;
  rich?: {[name: string]: CSSProperties | { padding: string | number | (string | number)[]}};
}

interface AxisLabel extends TextStyle {
  width?: string | number;
  formatter?: ChartAxisLabelType;
  hideOverlap: boolean;
  interval?: ChartIntervalType;
  rotate?: number | ChartAxisLabelRotateType;
  distance?: number;
  showMinLabel?: boolean;
  showMaxLabel?: boolean;
}

interface InnerChartRawData extends ChartRawData {
  percentage?: number;
}

interface ChartDataset {
  id?: string;
  source?: number[] | number[][];
  transform?: { type: ChartType };
}

interface ChartLegendData {
  name: string;
  icon?: ChartIconType | string;
  itemStyle?: CSSProperties;
}

interface ChartSeriesPointer {
  icon: ChartIconType;
  length?: number | string;
  width?: number | string;
  offsetCenter?: (number | string)[];
  itemStyle?: CSSProperties;
}

interface ChartSeriesDetail {
  fontSize?: number;
  offsetCenter?: (number | string)[];
}

interface ChartAxisLine {
  lineStyle: ChartAxisLineStyle;
}

interface ChartAxisLineStyle {
  width: number | string;
  color: (number | string)[]
}

interface ChartGaugeIntervalExtract {
  min: number;
  max: number;
  lineStyleColors: (number | string)[];
  axisLabels: { [key: number]: number | string };
}

interface MultipleYAxisConfig {
  name: string;
  position: HorizontalPositionType;
}

interface ChartAxisSplitLine {
  lineStyle: CSSProperties | { type: LineStyleType };
}

interface BaseZoomActionEvent {
  start: number;
  end: number;
  startValue: number;
  endValue: number;
}

//#endregion Interface

//#region Functions

const formatTooltipRow = (type: TableRowType, ...cells: string[]) => {
  const colspan: number = type === TableRowType.DATA ? 1 : 100,
        columns: string = cells.map((cell:string) => formatString(TOOLTIP_TABLE_CELL_TEMPLATE, cell, colspan)).join('');
  return `${formatString(TOOLTIP_TABLE_ROW_TEMPLATE, columns)}`;
}

const simpleTooltipFormat = (params: Array<ChartTooltipParams>, {xAxisValueType, yAxisValueType}: ChartConfig): string => {
  return params.reduce((acc: string[], {axisValue, value, marker, seriesName}: ChartTooltipParams, index: number) => {
    const xValue: ChartXYValue = (value as Array<ChartXYValue>)[0],
          yValue: ChartXYValue = Array.isArray(value) ? value[1] : value,
          yValueFormatted: string = isNumber(yValue) ? formatValueByType(yValue, yAxisValueType) : yValue as string;

    let xValueFormatted: string = isNumber(xValue) || isDate(xValue as string) ? formatValueByType(xValue, xAxisValueType) : xValue as string;

    switch(xAxisValueType) {
      case ValueType.WEEK_INTERVAL:
        xValueFormatted = `${xValueFormatted} - ${formatValueByType(addDateTime(new Date(xValue as string), 6, DateUnitType.DAY), ValueType.DATE)}`;
        break;
    }
    
    return [...acc, `${index === 0 ? xValueFormatted ?? axisValue : ''}${LINE_BREAK_TAG}${marker} ${seriesName} ${yValueFormatted}`];
  }, []).join('');
}

const percentageTooltipFormat = (params: Array<ChartTooltipParams>, {data, seriesMap}: ChartConfig): string => {
  const { seriesName, name }: ChartTooltipParams = params[0],
        singleSerie: boolean = seriesMap?.length === 1,
        {total, table}: {total: number, table: string[]} = (data ?? []).reduce((obj: {total: number, table:string[]}, item: InnerChartRawData, index: number) => {
          const map = seriesMap?.find(({name}: ChartSeriesMap) => name === item.groupLabel),
                color = COLOR_PALLETE[index % COLOR_PALLETE.length],
                label = item[map?.categoryField ?? DEFAULT_SERIES_MAP.categoryField],
                value = item[map?.valueField ?? DEFAULT_SERIES_MAP.valueField];
          
          if(label === name || singleSerie) {
            const content = formatTooltipRow(TableRowType.DATA, `${formatString(MARKER_TEMPLATE, color)} ${item.groupLabel ?? label}`, `${value}`, `${formatValueByType(item.percentage, ValueType.PERCENTAGE)}`);
            if((singleSerie && label === name) || seriesName === item.groupLabel) {
              obj.table.splice(0,0,content);
            } else {
              obj.table.push(content);
            }
            obj.total += value as number;
          }
          return obj;
        }, {total:0, table:[]}),
        title: string = formatTooltipRow(TableRowType.HEADER, singleSerie ? seriesName : name),
        bottom: string = formatTooltipRow(TableRowType.DATA, TOTAL_TOOLTIP, `${total}`, `100%`);
  return formatString(TOOLTIP_TABLE_TEMPLATE, `${styles.tooltip} ${styles.emphasis}`, [title, ...table, bottom].join(''));
}

const defaultTooltipFormatter = (params: ChartTooltipParams|Array<ChartTooltipParams>, {type, yAxisValueType, xAxisValueType, data, seriesMap}: ChartConfig): string | HTMLElement | HTMLElement[] => {
  params = Array.isArray(params) ? params : [params];
  switch(type) {
    case ChartType.PIE:
      return percentageTooltipFormat(params, {data, seriesMap} as ChartConfig);
    case ChartType.VIOLIN: {
      const {value}: ChartTooltipParams = params[0],
            [xValue, ...values] = value as (number|string)[],
            { boxData } = prepareBoxplotData([values as number[]]),
            reversedBoxData = boxData[0].reverse(),
            title: string = formatTooltipRow(TableRowType.HEADER, xValue as string),
            table = VIOLIN_TOOLTIP_TABLE.map((row: string, index: number) => formatString(row, formatValueByType(reversedBoxData[index])));

        return formatString(TOOLTIP_TABLE_TEMPLATE, styles.tooltip, [title, ...table].join(''));
    }
    case ChartType.BAR:
      return (seriesMap?.length ?? 1) > 1
                ? percentageTooltipFormat(params, {data, seriesMap} as ChartConfig)
                : simpleTooltipFormat(params, {xAxisValueType, yAxisValueType} as ChartConfig);
    default:
      return simpleTooltipFormat(params, {xAxisValueType, yAxisValueType} as ChartConfig);
  }
};

const gapAxisTime = ({min, max}: ChartXMinMax, increment?:boolean) => {
  if(isNaN(min) || isNaN(max)) {
    return new Date();
  }

  const minDate = new Date(min),
        maxDate = new Date(max),
        date = increment ? new Date(max) : new Date(min),
        incrementValue = increment ? 1 : -1;

  if(dateDifference(minDate, maxDate, DateUnitType.MONTH) > 2) {
    date.setMonth(date.getMonth() + incrementValue);
  } else if (dateDifference(minDate, maxDate, DateUnitType.DAY) > 2){
    date.setDate(date.getDate() + incrementValue);
  } else {
    date.setHours(date.getHours() + incrementValue);
  }

  return date;
}

//#endregion Functions

//#region Classes

class ChartXAxis {
  type: ChartAxisType = ChartAxisType.CATEGORY;
  min?: number | ((value: ChartXMinMax) => ChartXYValue);
  max?: number | ((value: ChartXMinMax) => ChartXYValue);
  boundaryGap?: boolean | number;
  axisLabel: AxisLabel = { fontFamily: FONT_FAMILY, color: DEFAULT_TEXT_COLOR, formatter: STANDARD_FORMAT, hideOverlap: true, interval: AUTO };
  minInterval?: number;
  nameTextStyle: TextStyle = { verticalAlign: VerticalPositionType.MIDDLE, fontWeight: FontWeightType._700 };

  constructor({axisType, type}: ChartConfig) {
    this.type = axisType ?? this.type;

    switch(type) {
      case ChartType.VIOLIN:
        this.boundaryGap = true;
        break;
      case ChartType.BAR:
        this.boundaryGap = 0;
        break;
    }
    
    switch(this.type) {
      case ChartAxisType.TIME:
        // eslint-disable-next-line i18next/no-literal-string
        this.axisLabel.formatter = { year: '{yearStyle|{yyyy}}\n{monthStyle|{MMM}}', month: '{monthStyle|{MMM}}', day: '{dd}/{MM}/{yyyy}'};
        this.minInterval = DAY_IN_MILLISECONDS;
        this.min = (data: ChartXMinMax) => gapAxisTime(data);
        this.max = (data: ChartXMinMax) => gapAxisTime(data, true);
        this.axisLabel = {
          ...this.axisLabel,
          showMinLabel: false,
          showMaxLabel: false
        };

        break;
    }
  }
}

class ChartYAxis implements Partial<MultipleYAxisConfig>{
  name?: string;
  position?: HorizontalPositionType;
  alignTicks?: boolean;
  type: ChartAxisType = ChartAxisType.VALUE;
  min?: number;
  max?: number;
  nameGap?: number;
  nameLocation: LinePositionType = LinePositionType.MIDDLE;
  nameTextStyle: TextStyle = { verticalAlign: VerticalPositionType.MIDDLE, fontWeight: FontWeightType._700 };
  axisLabel: AxisLabel = { fontFamily: FONT_FAMILY, color: DEFAULT_TEXT_COLOR, width: 50, formatter: STANDARD_FORMAT, hideOverlap: true};
  splitLine?: ChartAxisSplitLine = { lineStyle: { color: LINE_STYLE_COLOR, type: LineStyleType.DASHED} };

  constructor({type, data, yAxisValueType, stackBarsType, seriesMap}: ChartConfig, multiYAxisConfig?: MultipleYAxisConfig) {
    if(multiYAxisConfig) {
      this.alignTicks = true;
      this.nameGap = 45;
      Object.entries(multiYAxisConfig).forEach(([key, value]: [string, unknown]) => {
        this[key] = value;
      });
    }

    switch(stackBarsType) {
      case ChartStackBarsType.FILLED:
        this.min = 0;
        this.max = 100.1;
        break;
      default:
        this.min = data?.length ? this.min : 0;
        this.max = data?.length ? this.max : 1;
        break;
    }

    switch(type) {
      case ChartType.VIOLIN:
        const values = (data ?? []).map((item: ChartRawData): number => {
                const map = seriesMap?.find(({name}: ChartSeriesMap) => name === item.groupLabel);
                return item[map?.valueField ?? DEFAULT_SERIES_MAP.valueField] as number;
              }),
              percentageStddev = stddev(values) * STDDEV_PERCENTAGE;

        this.min = Math.floor(Math.min(...values) - percentageStddev);
        this.max = Math.ceil(Math.max(...values) + percentageStddev);
        break;
    }
    
    this.axisLabel.formatter = yAxisValueType
                                ? (value: number) => formatValueByType(value, stackBarsType === ChartStackBarsType.FILLED ? ValueType.PERCENTAGE : yAxisValueType)
                                : this.axisLabel.formatter;
  }
}

class ChartLegend {
  show = true;
  type: ChartLegendType = ChartLegendType.PLAIN;
  bottom?: number;
  top?: ChartGridTopType;
  left?: ChartGridLeftType;
  orient?: OrientType;
  icon?: ChartIconType = ChartIconType.CIRCLE;
  tooltip?: ChartTooltip;
  textStyle: TextStyle = { fontFamily: FONT_FAMILY, fontWeight: FontWeightType.NORMAL, fontSize: LEGEND_FONT_SIZE, color: DEFAULT_TEXT_COLOR };
  data?: ChartLegendData[];
  formatter?: (name: string) => string;

  constructor(chartConfig: ChartConfig) {
    const {type, seriesMap, data, dataLabel} = chartConfig;

    this.tooltip = new ChartTooltip({...chartConfig, tooltipFormatter: (params: ChartTooltipParams|Array<ChartTooltipParams>) => {
      params = Array.isArray(params) ? params : [params];
      return params.reduce((acc: string[], {name}: ChartTooltipParams) => {
        switch (type) {
          case ChartType.PIE:
            const {total, table}: {total: number, table: string[]} = (data ?? []).reduce((obj: {total: number, table: string[]}, item: InnerChartRawData, index: number) => {
                    const map = seriesMap?.find(({name}: ChartSeriesMap) => name === item.groupLabel),
                          label = item[map?.categoryField ?? DEFAULT_SERIES_MAP.categoryField] as string,
                          value = item[map?.valueField ?? DEFAULT_SERIES_MAP.valueField] as number,
                          color = COLOR_PALLETE[index % COLOR_PALLETE.length],
                          content = formatTooltipRow(TableRowType.DATA, `${formatString(MARKER_TEMPLATE, color)} ${label}`, `${value}`, `${formatValueByType(item.percentage, ValueType.PERCENTAGE)}`);
                    if(item[map?.categoryField ?? DEFAULT_SERIES_MAP.categoryField] === name) {
                      obj.table.splice(0,0,content);
                    } else {
                      obj.table.push(content);
                    }
                    obj.total += item.value as number;
                    return obj;
                  }, {total:0, table:[]}),
                  title: string = formatTooltipRow(TableRowType.HEADER, dataLabel ?? ''),
                  bottom: string = formatTooltipRow(TableRowType.DATA, TOTAL_TOOLTIP, `${total}`, `100%`);
            acc.push(formatString(TOOLTIP_TABLE_TEMPLATE, `${styles.tooltip} ${styles.emphasis}`, [title, ...table, bottom].join('')))
            break;
          default:
            acc.push(name);
            break;
        }
        return acc;
      }, []).join('');
    }});

    switch(type) {
      case ChartType.PIE:
        this.left = HorizontalPositionType.LEFT;
        this.top = VerticalPositionType.MIDDLE;
        this.orient = OrientType.VERTICAL;
        this.textStyle.rich = PIE_LEGEND_STYLE_CONFIG
        this.formatter = (name: string) => {
          const map = seriesMap?.[0],
                item = (data ?? []).find((item: InnerChartRawData) => item[map?.categoryField ?? DEFAULT_SERIES_MAP.categoryField] === name);
          
          // eslint-disable-next-line i18next/no-literal-string
          return `{label|${name}} {percentage|${formatValueByType(item?.percentage, ValueType.PERCENTAGE)}}`;
        };
        break;
      case ChartType.BAR:
        this.orient = OrientType.HORIZONTAL;
        this.top = TITLE_FONT_SIZE + DEFAULT_GAP_SIZE;
        this.left = HorizontalPositionType.LEFT;
        this.type = ChartLegendType.SCROLL;
        this.show = (seriesMap?.length ?? 0) > 1;
        break;
      case ChartType.VIOLIN:
        this.bottom = 12;
        this.left = HorizontalPositionType.CENTER;
        this.orient = OrientType.HORIZONTAL;
        this.type = ChartLegendType.SCROLL;
        this.show = true;
        this.data = [{
          name: formatTitle(ChartType.VIOLIN),
          icon: ChartIconType.DIAMOND,
          itemStyle: {
            color: VIOLIN_BOXPLOT_FILL_COLOR,
            borderColor: VIOLIN_BOXPLOT_STROKE_COLOR,
            borderWidth: 1
          }
        }, {
          name: formatTitle(ChartType.BOXPLOT),
          icon: ChartIconType.RECT,
          itemStyle: {
            color: VIOLIN_BOXPLOT_FILL_COLOR,
            borderColor: VIOLIN_BOXPLOT_STROKE_COLOR,
            borderWidth: 1
          }
        }];
        break;
      default:
        this.bottom = 0;
        this.show = (seriesMap?.length ?? 0) > 1;
        this.orient = OrientType.HORIZONTAL;
        this.type = ChartLegendType.SCROLL;
        break;
    }
  }
}

class ChartGrid {
  top: ChartGridTopType = TITLE_FONT_SIZE + (2 * DEFAULT_GAP_SIZE);
  bottom = 8;
  left: ChartGridLeftType = 0;
  right = 18;
  containLabel = true;

  constructor({seriesMap, type, multipleYAxis}: ChartConfig) {
    if(multipleYAxis) {
      this.left = this.right = 25;
    }

    switch(type) {
      case ChartType.BAR:
        this.top = (seriesMap?.length ?? 1) > 1 ? this.top as number + LEGEND_FONT_SIZE + DEFAULT_GAP_SIZE : this.top;
        break;
      default:
        this.bottom = (seriesMap?.length ?? 1) > 1 ? 48 : this.bottom;
        break;
    }
  }
}

class ChartTitle {
  text?: string;
  subtext?: string;
  textStyle: TextStyle = { fontFamily: FONT_FAMILY, fontWeight: FontWeightType._700, fontSize: TITLE_FONT_SIZE, color: DEFAULT_TEXT_COLOR };
  top: ChartGridTopType = VerticalPositionType.TOP;
  left: ChartGridLeftType = HorizontalPositionType.LEFT;

  constructor({title, subTitle}: ChartConfig) {
      this.text = title;
      this.subtext = subTitle;
  }
}

class ChartToolbox {
  itemSize= 0;
  itemGap= 0;
  width= 0;
  height= 0;
  showTitle = false;
  top= -999;
  feature : { [key in ChartToolboxFeatureType ]: object } = {
    dataZoom: { yAxisIndex: false},
    restore: {}
  }
}

class ChartDataZoom {
  type: ChartDataZoomType = ChartDataZoomType.INSIDE;
  minValueSpan: number | string = 2;
  zoomOnMouseWheel: ChartDataZoomInsideType = false;
  moveOnMouseMove: ChartDataZoomInsideType = false;
  moveOnMouseWheel: ChartDataZoomInsideType = false;
  preventDefaultMouseMove = false;
  zoomLock = true;

  constructor({axisType}: ChartConfig) {
    this.minValueSpan = axisType === ChartAxisType.TIME ? DAY_IN_MILLISECONDS * 2 : this.minValueSpan;
  }
}

class ChartTooltip {
  show = true;
  appendToBody = true;
  trigger: ChartTooltipTriggerType = ChartTooltipTriggerType.ITEM;
  axisPointer: { type: ChartTooltipAxisPointerType } = { type: ChartTooltipAxisPointerType.NONE };
  formatter?: ((params: ChartTooltipParams|Array<ChartTooltipParams>) => string | HTMLElement | HTMLElement[]) | undefined;
  backgroundColor = TOOLTIP_BACKGROUND_COLOR;
  textStyle: TextStyle = { fontFamily: FONT_FAMILY, color: TOOLTIP_TEXT_COLOR };

  constructor(chartConfig: ChartConfig) {
    switch(chartConfig.type) {
      case ChartType.GAUGE:
        this.show = false;
        break;
    }
    const {tooltipFormatter} = chartConfig;
    this.formatter = tooltipFormatter ?? ((params: ChartTooltipParams|Array<ChartTooltipParams>) => defaultTooltipFormatter(params, chartConfig));
  }
}

class ChartSeries {
  data?: (ChartSeriesData | (string | number)[]) [];
  id: string;
  name: string;
  smooth = false;
  silent = false;
  showSymbol = true;
  symbol?: ChartSymbolType;
  symbolSize?: number = 6;
  stack?: string;
  type?: ChartType | 'custom';
  color?: string | string[];
  animation = true;
  animationThreshold = 1000;
  animationDelay = 500;
  animationEasingUpdate: ChartAnimationType = ChartAnimationType.CUBIC_IN;
  radius?: [number|string, number|string] | number | string;
  center?: [number|string, number|string];
  width?: string | number = AUTO; 
  label?: ChartSeriesLabel;
  labelLayout?: ChartSeriesLabelLayout;
  emphasis?: ChartSeriesEmphasis;
  itemStyle?: CSSProperties;
  lineStyle?: CSSProperties | { type: LineStyleType };
  areaStyle?: CSSProperties;
  z = 0;
  min?: number;
  max?: number;
  splitNumber?: number;
  axisLine?: ChartAxisLine;
  axisLabel?: AxisLabel;
  pointer?: ChartSeriesPointer;
  axisTick?: ChartShowOption;
  splitLine?: ChartShowOption;
  title?: ChartShowOption;
  detail?: ChartSeriesDetail;
  renderItem?: (params: ChartSeriesRenderItemParams, api: ChartSeriesRenderItemApi) => ChartSeriesRenderItemReturn;
  encode?: ChartSeriesEncode;
  yAxisIndex?: number;

  constructor({type, stackBarsType, data, axisType, seriesMap, orientation, gaugeIntervals, yAxisValueType, showSeriesLabel, areaFill, multipleYAxis}: ChartConfig, {name, timeField, categoryField, valueField, color}: ChartSeriesMap, index: number) {
    this.name = name ?? DEFAULT_SERIE_NAME;
    this.id = this.name.replaceAll(' ', '-').toLowerCase();
    this.type = type;
    this.color = type !== ChartType.PIE ? color : undefined;
    this.stack = type === ChartType.BAR && stackBarsType ? DEFAULT_STACK_VALUE : undefined;

    if(showSeriesLabel) {
      this.label = {
        show: true,
        position: ObjectPositionType.OUTSIDE,
        formatter: ({value}: ChartBaseParams) => {
          const yValue: ChartXYValue = Array.isArray(value) ? value[1] : value;
          return formatValueByType(yValue, yAxisValueType);
        }
      };

      this.labelLayout = {
        hideOverlap: true
      }
    }

    if(multipleYAxis) {
      this.yAxisIndex = index;
    }

    switch(type) {
      case ChartType.GAUGE:

        const orderedGaugeIntervals = (gaugeIntervals ?? []).sort((a, b) => b.interval[1] - a.interval[1]),
              { min, max, lineStyleColors, axisLabels} = orderedGaugeIntervals.reduce(({min, max, lineStyleColors, axisLabels}: ChartGaugeIntervalExtract, {interval, label, color}: ChartGaugeInterval, index: number) => {
                const [currentMin, currentMax] = interval,
                      newMin = isNullOrUndefined(min) || currentMin < min ? currentMin : min,
                      newMax = isNullOrUndefined(max) || currentMax > max ? currentMax : max,
                      newLineStyle = [(currentMax)/newMax, color ?? COLOR_PALLETE[index]];
                
                return {
                  axisLabels: {
                    ...axisLabels ?? { },
                    // eslint-disable-next-line i18next/no-literal-string
                    [currentMin]: `{spacer| }\n{tick| }\n{number|${currentMin}}`,
                    // eslint-disable-next-line i18next/no-literal-string
                    [currentMax]: `{spacer| }\n{tick| }\n{number|${currentMax}}`,
                    [roundToDecimalPlaces(currentMin + (currentMax-currentMin)/2)]: label
                  },
                  lineStyleColors: [newLineStyle, ...lineStyleColors ?? []],
                  min: newMin,
                  max: newMax
                } as ChartGaugeIntervalExtract;
              }, {} as ChartGaugeIntervalExtract);
        
        this.min = min;
        this.max = max;
        this.splitNumber = max * 100;
        this.center = ['50%', '60%'];
        this.radius = '50%';
        this.axisTick = this.splitLine = this.title = {show: false};
        this.axisLabel = {
          ...BASE_GAUGE_AXIS_LABEL_CONFIG,
          formatter: (value: number) => axisLabels[value] as string ?? ''
        };
        this.axisLine = {
          lineStyle: {
            width: -70,
            color: lineStyleColors
          }
        };
        this.detail = {
          fontSize: DETAIL_FONT_SIZE,
          offsetCenter: [0, 0]
        };
        this.pointer = {
          icon: ChartIconType.TRIANGLE,
          length: 35,
          width: 35,
          offsetCenter: [0, '-100%'],
          itemStyle: {
            color: POINTER_COLOR,
            borderColor: POINTER_BORDER_COLOR,
            borderWidth: 4
          }
        }
        break;
      case ChartType.PIE:
        this.radius = ['50%', '75%'];
        this.center = ['90%', '50%'];
        this.width = '75%';
        this.emphasis = {
          scale: false,
          focus: ObjectFocusType.SELF,
          label: {
            ...BASE_PIE_LABEL_CONFIG,
            // eslint-disable-next-line i18next/no-literal-string
            formatter: ({value, name}: ChartBaseParams) => `{value|${value}}\n{label|${name}}`
          }
        };
        this.label = {
          ...BASE_PIE_LABEL_CONFIG,
          position: ObjectPositionType.CENTER,
          // eslint-disable-next-line i18next/no-literal-string
          formatter: () => `{value|${data?.reduce((acc: number, item: InnerChartRawData) => acc + (item[valueField] as number), 0)}}\n{label|${TOTAL_LABEL}}`
        };
        break;
      case ChartType.VIOLIN:
        this.type = CUSTOM_CHART_TYPE;
        this.renderItem = (params: ChartSeriesRenderItemParams, api: ChartSeriesRenderItemApi): ChartSeriesRenderItemReturn => {
          const categoryIndex: number = api.value(0),
                values: number[] = [];

          for(let index = 1; true; ++index) {
            const value: number = api.value(index);
            if(isNaN(value)) {
              break;
            }
            values.push(value);
          }
          
          const percentageStddev = stddev(values) * STDDEV_PERCENTAGE,
                min = Math.floor(Math.min(...values) - percentageStddev),
                max = Math.ceil(Math.max(...values) + percentageStddev),
                labels: string[] = [...new Set((data ?? [])?.map((item: ChartRawData) => item[categoryField ?? DEFAULT_SERIES_MAP.categoryField] as string))],
                y = scaleLinear()
                          .domain([min , max])
                          .range([api.getHeight(), 0]),
                x = scaleBand()
                          .range([0, api.getWidth()])
                          .domain(labels),
                histogram = bin()
                              .domain(y.domain() as [number, number])
                              .thresholds(y.ticks(20))
                              .value(d => d),
                bins = histogram(values),
                {start, end} = bins.reduce((indexes: BinsIndexes, bin: Bin<number,number>, index: number) => {
                  if(indexes.start === undefined && bin.length) {
                    indexes.start = index - 1 > 0 ? index - 1 : 0;
                  } else if(indexes.start !== undefined && indexes.end === undefined && !bin.length) {
                    indexes.end = index + 1;
                  } else if(indexes.end !== undefined && bin.length) {
                    indexes.end = undefined;
                  }
                  return indexes;
                }, {} as BinsIndexes),
                filteredBins = bins.slice(start, end), // Remove the line without meaning data
                maxBin = Math.max(...filteredBins.map((bin: Bin<number,number>) => bin.length)),
                xNum = scaleLinear()
                        .range([0, x.bandwidth()]),
                linearGenerator = line().curve(curveCatmullRom),
                points = filteredBins.map((v: Bin<number,number>) => {
                  const point = api.coord([categoryIndex, v.x0 as number]);
                  point[0] += (((api.size([0, 0])[0] / 3) * xNum(v.length)) / xNum(maxBin));
                  return point;
                }),
                points2 = filteredBins.map((v: Bin<number,number>) => {
                  const point = api.coord([categoryIndex, v.x0 as number]);
                  point[0] += (((api.size([0, 0])[0] / 3) * xNum(-v.length)) / xNum(maxBin));
                  return point;
                }),
                style = {
                  fill: VIOLIN_BOXPLOT_FILL_COLOR,
                  stroke: VIOLIN_BOXPLOT_STROKE_COLOR,
                  lineWidth: 1
                };
          
          return {
            z2: 2,
            type: ChartSeriesRenderItemType.PATH,
            shape: {
              pathData: `${linearGenerator(points as [number, number][])}${linearGenerator(points2 as [number, number][])}`
            },
            style: style,
            focus: ObjectFocusType.SELF,
            emphasis: {
              style: style
            }
          };
        };

        this.data = data?.reduce((acc: (string|number)[][], item: InnerChartRawData) => {
          const label: string = item[categoryField ?? DEFAULT_SERIES_MAP.categoryField] as string,
                value: number = item[valueField ?? DEFAULT_SERIES_MAP.valueField] as number,
                array: (string|number)[] | undefined = acc.find((stream: (string|number)[]) => stream[0] === label);

          if(!array) {
            acc.push([label, value]);
          } else {
            array.push(value);
          }
          return acc;
        }, []).sort((a: (string | number)[], b: (string | number)[]) => {
          const aLabelNumber = parseInt(a[0] as string),
                bLabelNumber = parseInt(b[0] as string);
          if(isNaN(aLabelNumber) || isNaN(bLabelNumber)) {
            return 0;
          }
          return aLabelNumber - bLabelNumber;
        });

        this.encode = {
          x: 0,
          y: ((this.data ?? []) as (string|number)[][]).reduce((biggest: (string|number)[], item: (string|number)[]) => {
            return item.length > biggest.length ? item : biggest;
          }, [] as (string|number)[]).map((v: string|number, i: number) => i + 1)
        };

        return;
      case ChartType.BOXPLOT:
        this.itemStyle = {
          color: VIOLIN_BOXPLOT_FILL_COLOR,
          borderColor: VIOLIN_BOXPLOT_STROKE_COLOR
        };
        this.emphasis = {
          focus: ObjectFocusType.SELF,
          scale: false,
          itemStyle: {
            color: VIOLIN_BOXPLOT_FILL_COLOR,
            borderColor: VIOLIN_BOXPLOT_STROKE_COLOR,
            borderWidth: 1,
            shadowBlur: 0
          }
        }
        return;
      case ChartType.BAR:
      case ChartType.LINE:
      case ChartType.SCATTER:
        this.symbol = ChartSymbolType.EMPTY_CIRCLE;

        this.lineStyle = {
          color: COLOR_PALLETE[index % COLOR_PALLETE.length]
        }

        this.emphasis = {
          scale: false,
          focus: ObjectFocusType.SELF,
          itemStyle: {
            color: INHERIT
          }
        };

        if(type === ChartType.LINE && areaFill) {
          this.areaStyle = {
            opacity: 0.3
          };
        }
        break;
    }
    
    this.data = data?.map((item: InnerChartRawData, itemIndex: number): ChartSeriesData | undefined => {
      if(item.groupLabel && item.groupLabel !== name) {
        return undefined;
      }
      switch(type) {
        case ChartType.PIE:
        case ChartType.GAUGE:
          return {
            value: item[valueField] as number,
            name: item[categoryField ?? DEFAULT_SERIES_MAP.categoryField] as string,
            itemStyle: {
              color: COLOR_PALLETE[itemIndex % COLOR_PALLETE.length]
            }
          };
        default:
          const value: number = stackBarsType === ChartStackBarsType.FILLED ? item.percentage as number : item[valueField] as number,
                valueAxis: ChartXYValue[] = [axisType === ChartAxisType.TIME ? item[timeField ?? DEFAULT_SERIES_MAP.timeField] as Date : item[categoryField ?? DEFAULT_SERIES_MAP.categoryField] as string, value];
          return {
            value: orientation === OrientType.HORIZONTAL ? valueAxis.reverse() : valueAxis,
            itemStyle: {
              color: COLOR_PALLETE[index % COLOR_PALLETE.length],
              borderRadius: type === ChartType.BAR && index === (seriesMap?.length ?? 1) - 1 && orientation === OrientType.VERTICAL
                          ? [4, 4, 0, 0]
                          : undefined,
            }
          }
      }
    }).filter((item: ChartSeriesData | undefined) => item) as ChartSeriesData[];
  }
}

export class ChartOptions {
  xAxis?: ChartXAxis | ChartXAxis[];
  yAxis?: ChartYAxis | ChartYAxis[];
  legend?: ChartLegend | ChartLegend[];
  grid?: ChartGrid | ChartGrid[];
  title?: ChartTitle | ChartTitle[];
  toolbox: ChartToolbox = new ChartToolbox();
  dataZoom?: ChartDataZoom | ChartDataZoom[];
  chartConfig: ChartConfigStruct;
  tooltip: ChartTooltip;
  dataset?: ChartDataset[];
  series: ChartSeries[];

  constructor(chartConfig: ChartConfig) {
    this.chartConfig = new ChartConfigStruct(chartConfig);

    const { type, seriesMap, orientation, showLinearRegression } = this.chartConfig;

    this.initArrayProperties();
    if(orientation === OrientType.HORIZONTAL) {
      const xAxis = {...this.xAxis},
            yAxis = {...this.yAxis};

      this.xAxis = yAxis as ChartXAxis;
      this.yAxis = xAxis as ChartYAxis;
    }
    this.tooltip = new ChartTooltip(this.chartConfig);
    this.series = seriesMap?.map((serieMap: ChartSeriesMap, index: number) => new ChartSeries(this.chartConfig, serieMap, index)) ?? [];

    switch(type) {
      // Add BoxPlot on a Violin Plot
      case ChartType.VIOLIN:
        const [violinSeries]= this.series,
              serieMap = {...seriesMap?.[0] ?? DEFAULT_SERIES_MAP, name: formatTitle(ChartType.BOXPLOT)},
              boxplotSeries = new ChartSeries({...this.chartConfig, type: ChartType.BOXPLOT, data: undefined}, serieMap, 0);
        
        if(!violinSeries) {
          return;
        }

        this.dataset = [{
            source: (violinSeries.data as (string|number)[][] ?? []).map(([label, ...values]: (string|number)[]) => {
              const { boxData } = prepareBoxplotData([values as number[]]);
              return [label, ...boxData[0]];
            })
          }];

        this.series.push(boxplotSeries);
        break;
      case ChartType.SCATTER:
      case ChartType.LINE:
      case ChartType.BAR:
        // Add linear regression series on a Scatter or Line plot, if the flag is active
        if(showLinearRegression) {
          const [singleSeries] = this.series,
                rawData = (singleSeries?.data as ChartSeriesData[])?.map(({value}: ChartSeriesData, index: number) => [index, value[1]]) ?? [],
                serieMap = {...seriesMap?.[0] ?? DEFAULT_SERIES_MAP, name: LINEAR_REGRESSION_TITLE},
                {points} = regression(ChartRegressionMethodType.LINEAR, rawData, 2),
                regressionSeries = new ChartSeries({...this.chartConfig, type: ChartType.LINE}, serieMap, 1);

          regressionSeries.data = (regressionSeries.data as ChartSeriesData[])?.map((item: ChartSeriesData, index: number) => {
            const [x] = item.value as ChartXYValue[],
                  [,y] = points[index];
            item.value = [x, y];

            return item;
          });

          this.series.splice(0, 0, {
            ...regressionSeries,
            silent: true,
            showSymbol: false,
            lineStyle: {
              ...regressionSeries.lineStyle,
              type: LineStyleType.DASHED
            }
          });
        }
        break;
    }
  }

  //#region Private Methods

  private initArrayProperties() {
    const { type } = this.chartConfig,
          constructors = (() => {
            switch(type) {
              case ChartType.GAUGE:
                return SIMPLE_CONSTRUCTORS;
              case ChartType.PIE:
                return NON_AXIS_CONSTRUCTORS;
              default:
                return STANDARD_CONSTRUCTORS;
            }
          })();

    Object.keys(constructors).forEach((prop: string) => {
      this.initProperty(constructors, prop);
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private initProperty(constructors: { [name: string]: any }, prop: string) {
    const propClass = constructors[prop];
    //eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this as any)[prop] = (() => {
      if(this.chartConfig.grids) {
        return [...new Array(this.chartConfig.grids)].map(() => new propClass(this.chartConfig));  
      } else {
        if(prop === Y_AXIS_PROP && this.chartConfig.multipleYAxis) {
          return this.chartConfig.multipleYAxis.map((axisName: string, index: number) => {
            return new propClass(this.chartConfig, {
              name: axisName,
              position: index > 0 ? HorizontalPositionType.RIGHT : HorizontalPositionType.LEFT
            });
          });
        } else {
          return new propClass(this.chartConfig);
        }
      }
    })()
  }

  //#endregion Private Methods
}

class ChartConfigStruct implements ChartConfig {
  type: ChartType = ChartType.LINE;
  dataLabel?: string;
  seriesMap?: ChartSeriesMap[];
  axisType?: ChartAxisType;
  xAxisValueType?: ValueType;
  yAxisValueType?: ValueType;
  grids?: number;
  orientation?: OrientType;
  data?: InnerChartRawData[];
  stackBarsType?: ChartStackBarsType;
  showLinearRegression?: boolean;
  multipleYAxis?: string[];

  constructor(chartConfig: ChartConfig) {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    Object.entries(chartConfig).forEach(([key, value]: [string, any]) => {
      (this as any)[key] = value;
    });
    /* eslint-disable @typescript-eslint/no-explicit-any */

    const { type } = chartConfig,
          groupNames: string[] = [...new Set(chartConfig.data?.map(({groupLabel}: any) => type === ChartType.VIOLIN ? formatTitle(ChartType.VIOLIN) : groupLabel ?? this.dataLabel ?? DEFAULT_SET_NAME))];

    this.seriesMap = this.seriesMap ?? groupNames.map((groupName: string, index: number) => ({...DEFAULT_SERIES_MAP, name: groupName, color: COLOR_PALLETE[index % COLOR_PALLETE.length]} as ChartSeriesMap));
    this.type = this.type ?? ChartType.LINE;
    this.stackBarsType = this.type !== ChartType.BAR ? undefined : this.stackBarsType;
    this.xAxisValueType = this.xAxisValueType ?? (this.axisType === ChartAxisType.TIME ? ValueType.DATE : undefined);
    this.yAxisValueType = this.type !== ChartType.PIE ? this.yAxisValueType ?? ValueType.NUMBER : undefined;
    this.orientation = this.type === ChartType.BAR ? (this.orientation ?? OrientType.VERTICAL) : undefined;

    const totals: { [key: string]: number } = this.data?.reduce((acc: { [key: string]: number }, item: ChartRawData) => {
      const map = this.seriesMap?.find(({name}: ChartSeriesMap) => name === item.groupLabel),
            property = groupNames.length > 1 ? item[map?.categoryField ?? DEFAULT_SERIES_MAP.categoryField] as string : DEFAULT_SET_NAME;
      acc[property] = (acc[property] ?? 0) + (item[map?.valueField ?? DEFAULT_SERIES_MAP.valueField] as number);
      return acc;
    }, {}) ?? {};

    this.data = this.data?.map((item: ChartRawData) => {
      const map = this.seriesMap?.find(({name}: ChartSeriesMap) => name === item.groupLabel),
            property = groupNames.length > 1 ? item[map?.categoryField ?? DEFAULT_SERIES_MAP.categoryField] as string : DEFAULT_SET_NAME,
            total: number = totals[property];
      return {
        ...item,
        percentage: (item[map?.valueField ?? DEFAULT_SERIES_MAP.valueField] as number * 100) / total
      };
    });
  }
}

//#endregion Classes

//#region Constants

const DAY_IN_MILLISECONDS: number = 24 * 60 * 60 * 1000,
      STDDEV_PERCENTAGE = .65,
      DEFAULT_TEXT_COLOR = '#333',
      LINE_STYLE_COLOR = '#DDD',
      LINEAR_REGRESSION_TITLE = i18n.t('Common:LinearRegression'),
      CUSTOM_CHART_TYPE = 'custom',
      AUTO = 'auto',
      INHERIT = 'inherit',
      TITLE_FONT_SIZE = 20,
      LEGEND_FONT_SIZE = 12,
      DEFAULT_GAP_SIZE = 16,
      DETAIL_FONT_SIZE = 70,
      DEFAULT_SET_NAME = 'Set',
      DEFAULT_SERIE_NAME = DEFAULT_SET_NAME,
      TOTAL_LABEL = 'Total',
      TOTAL_TOOLTIP = `= ${TOTAL_LABEL}`,
      MARKER_TEMPLATE = `<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:{0};"></span>`,
      TOOLTIP_TABLE_TEMPLATE = `<table class="{0}">{1}</table>`,
      TOOLTIP_TABLE_ROW_TEMPLATE = '<tr>{0}</tr>',
      TOOLTIP_TABLE_CELL_TEMPLATE = '<td colspan="{1}">{0}</td>',
      LINE_BREAK_TAG = '<br/>',
      FONT_FAMILY = '\'Space Grotesk\', sans-serif',
      POINTER_COLOR = '#333',
      POINTER_BORDER_COLOR = '#FFF',
      TOOLTIP_BACKGROUND_COLOR = '#2E353B',
      TOOLTIP_TEXT_COLOR = '#FFF',
      DEFAULT_STACK_VALUE = 'stack',
      DEFAULT_LOADING_NAME = 'default',
      DEFAULT_LOADING_CONFIG: ChartLoadingConfig = {
        text: 'Loading',
        showSpinner: true,
        fontWeight: '500',
        fontFamily: FONT_FAMILY,
        fontSize: 24
      },
      BASE_GAUGE_AXIS_LABEL_CONFIG: AxisLabel = {
        color: '#333',
        fontSize: 16,
        fontWeight: FontWeightType.BOLD,
        hideOverlap: true,
        distance: -110,
        rotate: ChartAxisLabelRotateType.TANGENTIAL,
        rich: {
          number: {
            fontSize: 16,
            fontFamily: FONT_FAMILY,
            fontWeight: FontWeightType._300,
            color: '#333',
            padding: [8,0,0,0]
          },
          tick: {
            backgroundColor: 'black',
            width: 1,
            height: 70
          },
          spacer: {
            padding: [120,0,0,0]
          }
        }
      },
      BASE_PIE_LABEL_CONFIG: ChartSeriesLabel = {
        show: true,
        color: '#333',
        rich: {
          value: {
            fontSize: 24,
            fontFamily: FONT_FAMILY,
            fontWeight: FontWeightType._700
          },
          label: {
            color: '#999',
            fontSize: 20,
            fontFamily: FONT_FAMILY,
            fontWeight: FontWeightType._700
          }
        }
      },
      PIE_LEGEND_STYLE_CONFIG: {[name: string]: CSSProperties} = {
        percentage: {
          color: '#999',
          fontFamily: FONT_FAMILY,
          fontWeight: FontWeightType.NORMAL,
          padding: 8
        },
        label: {
          color: '#333',
          fontFamily: FONT_FAMILY,
          fontWeight: FontWeightType.NORMAL
        }
      },
      STANDARD_FORMAT = '{value}',
      DEFAULT_SERIES_MAP: Required<ChartSeriesMap> = {
        valueField: 'value',
        categoryField: 'label',
        timeField: 'time',
        name: '',
        color: ''
      },
      Y_AXIS_PROP = 'yAxis',
      SIMPLE_CONSTRUCTORS: { [name: string]: any } = {
        'title': ChartTitle
      },
      NON_AXIS_CONSTRUCTORS: { [name: string]: any } = {
        'legend': ChartLegend,
        ...SIMPLE_CONSTRUCTORS
      },
      STANDARD_CONSTRUCTORS: { [name: string]: any } = {
        'xAxis': ChartXAxis,
        'yAxis': ChartYAxis,
        'grid': ChartGrid,
        'dataZoom': ChartDataZoom,
        ...NON_AXIS_CONSTRUCTORS
      },
      COLOR_PALLETE: string[] = [
        '#C2DFFF',
        '#6CB2FF',
        '#1579E7'
      ],
      VIOLIN_TOOLTIP_TABLE = [
        formatTooltipRow(TableRowType.DATA, `High:`, `{0}`),
        formatTooltipRow(TableRowType.DATA, `Q3:`, `{0}`),
        formatTooltipRow(TableRowType.DATA, `Median:`, `{0}`),
        formatTooltipRow(TableRowType.DATA, `Q1:`, `{0}`),
        formatTooltipRow(TableRowType.DATA, `Low:`, `{0}`)
      ],
      VIOLIN_BOXPLOT_FILL_COLOR = COLOR_PALLETE[0],
      VIOLIN_BOXPLOT_STROKE_COLOR = COLOR_PALLETE[2],
      RESET_ZOOM_ACTION = {
        type: DefaultChartActionType.DATA_ZOOM,
        start: 0,
        end: 100
      } as ChartZoomAction;

//#endregion Constants

export {DEFAULT_LOADING_NAME , DEFAULT_LOADING_CONFIG, RESET_ZOOM_ACTION};
